import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/PHPdevelopment/Hero";
import DevelopServices from "../components/PHPdevelopment/DevelopServices";
import Technology from "../components/PHPdevelopment/Technology";
import Process from "../components/PHPdevelopment/Process";
import IndustryBussinessjs from "../components/PHPdevelopment/IndustryBussinessjs";
import Commitment from "../components/PHPdevelopment/Commitment";
import Customers from "../components/PHPdevelopment/Customers";
import Success from "../components/PHPdevelopment/Success";
import PageTitle from "../components/PHPdevelopment/PageTitle";

import '../assets/Webdevelopment.css';



const PHPdevelopment = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  </div>
	)
}

export default PHPdevelopment;
