import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp text-uppercase">At DigiPanda, we develop softwares that adds value to your <span>business</span> and the life of your customers.</h2>
						<p className="wow fadeInUp">Our team focuses aggressively on the user experience that users will get through the softwares, which makes us different from others.</p>
						<p className="wow fadeInUp">Our team is filled with experts providing services under custom software development, dealing with the complexity of softwares and developing products based on innovation, client ideas, and more. </p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">SOFTWARE DEVELOPMENT</h3>
									<p className="wow fadeInUp">Get high-performance and efficient custom software development services to fulfill your business requirements. </p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ENTERPRISE APPLICATIONS</h3>
									<p className="wow fadeInUp">Build a scalable business by using our enterprise application development services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">SOFTWARE MIGRATION</h3>
									<p className="wow fadeInUp">Migrate to the latest technologies for a better approach with the help of software migration services.</p>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
