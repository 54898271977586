import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/JavaDevelopment/Hero";
import DevelopServices from "../components/JavaDevelopment/DevelopServices";
import Technology from "../components/JavaDevelopment/Technology";
import Process from "../components/JavaDevelopment/Process";
import IndustryBussinessjs from "../components/JavaDevelopment/IndustryBussinessjs";
import Commitment from "../components/JavaDevelopment/Commitment";
import Customers from "../components/JavaDevelopment/Customers";
import Success from "../components/JavaDevelopment/Success";
import PageTitle from "../components/JavaDevelopment/PageTitle";

import '../assets/Webdevelopment.css';



const JavaDevelopment = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  </div>
	)
}

export default JavaDevelopment;
