import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

import {Link} from "react-router-dom";



const IndustriesContent = () => {
	return(
		<div className="industries-content">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="inner-box">
							<Row>
								<Col lg="8" md="12" className="left-sec">
									<h3 className="mt-0 wow fadeInUp">digiPanda HAS DEMONSTRATED ITS ABILITY TO DELIVER STATE-OF-THE-ART SOFTWARE SOLUTIONS WITH SPEED-TO-MARKET ADVANTAGE TO DISCERNING AUTOMOTIVE ELECTRONICS CUSTOMERS USING ITS READY-TO-DEPLOY TECHNOLOGY BUILDING BLOCKS AND MATURE DESIGN METHODOLOGIES.</h3>
									<p className="wow fadeInUp">The automotive industry is driven by high levels of innovation and flexibility. As per a survey conducted in 2010, approximately 4% of the United States' GDP was accounted by the automotive sales. Since 2011, the sales have increased by 11% and are expected to grow at a steady rate. Technological advancements have empowered the industry to reduce operational costs, enhance quality and productivity, streamline compliance and develop end-to-end automotive solutions. Utilizing customized software product, organizations can improvise supply chain collaboration, accelerate innovation and diversify product offerings to increase the ROI for their business.</p>
									<h3 className="wow fadeInUp"> Challenges and Opportunities </h3>
									<p className="wow fadeInUp"><span>Dependency on Traditional Tools:</span></p>
									<p className="wow fadeInUp">Automotive processes are driven by hardware. Traditional integrations and tools hinder the collaboration process, resulting in surging costs and increased time to market the product. Organizations in the automotive industry are conducting 90% of the product changes utilizing targeted software. This amplifies the velocity and volume of the engineering change almost by 10 to 100 times in comparison with hardware. </p>
									<p className="wow fadeInUp"><span>Regulation Compliance:</span></p>
									<p className="wow fadeInUp">Safety and environmental regulations such as ISO 26262 and Automotive SPICE are imposed by government agencies to enhance the process and product quality. For businesses, ineffective management of resources can result in soaring expenses and extended time to market the deliverables.</p>
									<p className="wow fadeInUp"><span>Comprehending Isolated Groups:</span></p>
									<p className="wow fadeInUp">Isolated systems and groups in the global automotive ecosystem can be tedious to comprehend. Consequently, decision making based on incomplete and out dated data can make the entire process error prone and time consuming. The adversely impacts the quality and can drop the level of productivity.</p>
									<p className="wow fadeInUp"><span>Competition:</span></p>
									<p className="wow fadeInUp">The automotive industry is expanding its reach across boundaries and this has resulted in cut-throat competition in the marketplace. Furthermore, regulatory and consumer pressure to render sophisticated and high quality products lead to soaring expenses and unsustainable time-to-market.</p>
									<h3 className="wow fadeInUp">HOW digiPanda DELIVERS BUSINESS VALUE</h3>
									<p className="wow fadeInUp">For over a decade, digiPanda has partnered with some of the established names in the automotive industry. Software solutions delivered by our automotive consultants put your automotive company ahead of the curve. At digiPanda  we understand that automotive interfaces are not just about appealing aesthetics- it's about a scalable design and rich functionalities that make consumer's web experience an inviting one. Whether you are seeking solutions for inventory management, product development, API bridging application, customer service or social media integration, digiPanda has diversified customization services for you.</p>
									<p className="wow fadeInUp"><span>Simplifying Collaboration:</span></p>
									<p className="wow fadeInUp">Solutions rendered by digiPanda simplifies the collaboration process amongst designers and engineers and suppliers and OEMs across engineering disciplines, removing the barriers and propagating innovation across the development ecosystem.</p>
									<p className="wow fadeInUp"><span>Real-Time Results:</span></p>
									<p className="wow fadeInUp">Software solutions enables the management to make right decisions at right instances by providing real-time visibility into release readiness, ensuring high quality of deliverables.</p>
									<p className="wow fadeInUp"><span>Process-Controlled Development:</span></p>
									<p className="wow fadeInUp">Promotes full traceability and process-controlled development, thus simplifying compliance and improvising the quality of product and process. It acts as a single source of truth across engineering disciplines and process domains.</p>
									<h3 className="wow fadeInUp">digiPanda' HEALTHCARE SERVICE OFFERINGS</h3>
									<ul className="listings wow fadeInUp">
										<li>Business Intelligence and Data Warehousing Solutions</li>
										<li>Dealer Management Systems (DMS)</li>
										<li>E-Commerce, CRM and ERP Solutions</li>
										<li>Enterprise Mobility and Transformation</li>
										<li>Product Life Cycle Management (PLCM)</li>
										<li>Rapid Prototyping and Product Testing Support</li>
										<li>Supply Chain Management (SCM)</li>
										<li>Web Applications / Portals</li>
										<li>Mobile Applications / Mobility Solutions</li>
									</ul>
								</Col>
								<Col lg="4" md="12" className="right-sec">
									<div className="blue-box wow fadeInUp">
										<h4 className="title-wht"> Request Information </h4>
										<p className="text-white">To start a dialogue with our consultant, please complete our contact form and we shall get in touch with you shortly. </p>
										<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
									</div>
									<h3 className="btm-title wow fadeInUp"> HEALTHCARE SOLUTIONS </h3>
									<ul className="listings wow fadeInUp">
										<li>Dealer Management Systems</li>
										<li>Business Intelligence and Data Warehousing Solutions</li>
										<li>Legacy Migration Solutions</li>
										<li>CRM and ERP Solutions</li>
										<li>Supply Chain Management</li>
										<li>Business Intelligence</li>
										<li>Data Warehousing Solutions</li>
										<li>Product Life Cycle Management (PLCM)</li>
										<li>Product Testing Support</li>
										<li>Data Warehousing Solutions</li>
										<li>Web Applications / Portals</li>
										<li>Enterprise Mobility Solutions</li>
									</ul>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
	    	</Container>
	  	</div>
	)
}

export default IndustriesContent;

