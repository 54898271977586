import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Industries/Hero";
import OtServices from "../components/Industries/OtServices";
import Customers from "../components/Industries/Customers";
import PageTitle from "../components/Industries/PageTitle";



import '../assets/Webdevelopment.css';



const Industries = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<OtServices />
	    	<Customers/>
	  </div>
	)
}

export default Industries;
