import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import q1 from '../../images/q1.png';
import q2 from '../../images/q2.png';
import q3 from '../../images/q3.png';
import q4 from '../../images/q4.png';
import q5 from '../../images/q5.png';
import q6 from '../../images/q6.png';


const AutomationTesting = () => {

	return (
	    <div className="consul_hire">
			<Container fluid>
				<Row>
					<Col lg="4" sm="6" className="consul_col automation-col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={q1} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">AUTOMATION TESTING</h3>
							<p className="exp wow fadeInUp">We help you perform software testing activities with no human interactions with the help of our automation testing services</p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col automation-col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={q2} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">SECURITY TESTING</h3>
							<p className="exp wow fadeInUp">To check if your products and secure completely, we carry forward aggressive security testing. </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col automation-col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={q3} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">USABILITY TESTING</h3>
							<p className="exp wow fadeInUp">User experience matters nowadays, and that's why our team makes sure to go through usability testing for the best possible results. </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col automation-col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={q4} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">CORE TESTING</h3>
							<p className="exp wow fadeInUp">To figure out if all the structures or frameworks of the software/product are working, we carry forward this core testing service.  </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col automation-col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={q5} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">MOBILE TESTING</h3>
							<p className="exp wow fadeInUp"> Mobile usability matters a lot, as most of the users use our products from mobiles only.</p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col automation-col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={q6} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">PERFORMANCE TESTING</h3>
							<p className="exp wow fadeInUp">We never compromise with the performance of a product with the help of our performance testing facility. </p>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default AutomationTesting;
