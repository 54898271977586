import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">HARNESS <span> BIG DATA</span> TO DRIVE BETTER BUSINESS DECISIONS AND TO CONVERT YOUR DATA INTO VALUABLE INSIGHTS.</h2>
						<p className="wow fadeInUp">More and more organizations are facing challenges with increasing volume of data and the ability to leverage it effectively in a short time with higher certainty and accuracy. Big data analytics helps organizations harness their data and use it to identify new opportunities which in turn, leads to smarter business moves, more efficient operations, higher profits and happier customers. That means Big data opens doors and opportunities when turned into insights.</p>
						<p className="wow fadeInUp">digiPanda's big data & analytics services provide advance solutions to extract insight from your data which in turn helps you in refining the products and services. We make data accessible and secure. Our big data analytics solutions help organizations to build competitive strategies based on the four-fold path of capture, store, process and analyze. digiPanda is a top rated big data & analytics company and our strong analytics expertise, in-depth domain knowledge, data management skills and machine learning excellence, have helped us in creating a successful track-record in partnering with Big data and Analytics initiatives of leading organizations in all sectors and domains including Financial, Healthcare, Life Sciences, Entertainment, Education and Telecom etc.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">DATA WAREHOUSING</h3>
									<p className="wow fadeInUp">digiPanda is a preferred Android app development company that builds highly robust and scalable Android apps with easy user interface and better user experience (UI/UX) meeting your requirements and budget.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">DATA LAKE</h3>
									<p className="wow fadeInUp">We build elegant and engaging iOS apps that makes your business successful. We have professional iOS app developers who always design and develop easy to use, user friendly and cost-effective iOS applications.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ETL FOR ANALYTICS</h3>
									<p className="wow fadeInUp">digiPanda builds cross platform apps with Xamarin, PhoneGap, React Native and other frameworks. Cross platform app development makes it possible to use reusable codes which saves lots of development time and costs.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">DATA PROCESSING</h3>
									<p className="wow fadeInUp">digiPanda offers expert development of Augmented Reality apps. Right from retail to construction to daily enterprise activities, you can use the potential benefits of AR to make your key operations highly efficient, informed and cost effective for better ROI.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">DATA INTEGRATION</h3>
									<p className="wow fadeInUp">Create, schedule and manage your data integration at scale with digiPanda's hybrid data integration service. Work with data wherever it lives, in the cloud or on-premises and with enterprise grade security.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">BIG DATA STRATEGY CONSULTING</h3>
									<p className="wow fadeInUp">digiPanda's Big Data Strategy consulting services provide strategy, engineering and analytics to help you maximize your data insights. Our end to end services, matched with industry specific skills and processes, help you make your data simpler to access and understand.</p>
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
