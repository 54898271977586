import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import qualitytesting from '../../images/qualitytesting.png';
import testing from '../../images/testing.png';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner Webdevelopment QualityTesting">
			    <div className="Banner_Content">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="6" className="banner-left-img cms-img">
								<div className="left-img">
									<img src={qualitytesting} className="wow fadeInUp" alt="Developemnt" />
								</div>
							</Col>
							<Col lg="6" className="banner-right-content">
								<div className="heart-img">
									<img src={testing} className="wow zoomIn" alt="Developemnt" />
								</div>
								<h2 className="wow fadeInUp">QUALITY <br /> ASSURANCE & <br /> <span>TESTING</span></h2>
								<p>Delivering quality work within given timelines with the help of our quality assurance and testing team, who make sure that every aspect of your products and error-free and ready to use. </p>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
