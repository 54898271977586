import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Services/Hero";
import DevelopServices from "../components/Services/DevelopServices";
import Process from "../components/Services/Process";
import Customers from "../components/Services/Customers";
import Success from "../components/Services/Success";
import PageTitle from "../components/Services/PageTitle";
import Businees from "../components/Services/Businees";


import '../assets/Webdevelopment.css';



const Services = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Businees />
	    	<Process />
	    	<Customers/>
	    	<Success />
	  </div>
	)
}

export default Services;
