import React from "react";
import { Row, Col, Container } from 'react-bootstrap';





const IndustriesContent = () => {
	return(
		<div className="industries-content">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="inner-box">
							<Row>
								<Col lg="12" md="12" className="left-sec border-right-0">
									<h3 className="border-bottom pb-4 mb-4 mt-0 wow fadeInUp">TERMS & CONDITIONS THAT GOVERN OUR WEBSITE</h3>
									<p className="wow fadeInUp">The automotive industry is driven by high levels of innovation and flexibility. As per a survey conducted in 2010, approximately 4% of the United States' GDP was accounted by the automotive sales. Since 2011, the sales have increased by 11% and are expected to grow at a steady rate. Technological advancements have empowered the industry to reduce operational costs, enhance quality and productivity, streamline compliance and develop end-to-end automotive solutions. Utilizing customized software product, organizations can improvise supply chain collaboration, accelerate innovation and diversify product offerings to increase the ROI for their business.</p>
									<h3 className="wow fadeInUp"> 1. TERMS </h3>
									<p className="wow fadeInUp"><span>Dependency on Traditional Tools:</span></p>
									<p className="wow fadeInUp">Automotive processes are driven by hardware. Traditional integrations and tools hinder the collaboration process, resulting in surging costs and increased time to market the product. Organizations in the automotive industry are conducting 90% of the product changes utilizing targeted software. This amplifies the velocity and volume of the engineering change almost by 10 to 100 times in comparison with hardware. </p>
									<p className="wow fadeInUp"><span>Regulation Compliance:</span></p>
									<p className="wow fadeInUp">Safety and environmental regulations such as ISO 26262 and Automotive SPICE are imposed by government agencies to enhance the process and product quality. For businesses, ineffective management of resources can result in soaring expenses and extended time to market the deliverables.</p>
									<p className="wow fadeInUp"><span>Comprehending Isolated Groups:</span></p>
									<p className="wow fadeInUp">Isolated systems and groups in the global automotive ecosystem can be tedious to comprehend. Consequently, decision making based on incomplete and out dated data can make the entire process error prone and time consuming. The adversely impacts the quality and can drop the level of productivity.</p>
									<p className="wow fadeInUp"><span>Competition:</span></p>
									<p className="wow fadeInUp">The automotive industry is expanding its reach across boundaries and this has resulted in cut-throat competition in the marketplace. Furthermore, regulatory and consumer pressure to render sophisticated and high quality products lead to soaring expenses and unsustainable time-to-market.</p>
									<h3 className="wow fadeInUp">2. USE LICENSE</h3>
									<p className="wow fadeInUp">For over a decade, digiPanda has partnered with some of the established names in the automotive industry. Software solutions delivered by our automotive consultants put your automotive company ahead of the curve. At digiPanda, we understand that automotive interfaces are not just about appealing aesthetics- it's about a scalable design and rich functionalities that make consumer's web experience an inviting one. Whether you are seeking solutions for inventory management, product development, API bridging application, customer service or social media integration, digiPanda has diversified customization services for you.</p>
									<p className="wow fadeInUp"><span>Simplifying Collaboration:</span></p>
									<p className="wow fadeInUp">Solutions rendered by digiPanda simplifies the collaboration process amongst designers and engineers and suppliers and OEMs across engineering disciplines, removing the barriers and propagating innovation across the development ecosystem.</p>
									<p className="wow fadeInUp"><span>Real-Time Results:</span></p>
									<p className="wow fadeInUp">Software solutions enables the management to make right decisions at right instances by providing real-time visibility into release readiness, ensuring high quality of deliverables.</p>
									<p className="wow fadeInUp"><span>Process-Controlled Development:</span></p>
									<p className="wow fadeInUp">Promotes full traceability and process-controlled development, thus simplifying compliance and improvising the quality of product and process. It acts as a single source of truth across engineering disciplines and process domains.</p>
									<h3 className="wow fadeInUp">3. DISCLAIMER</h3>
									<ul className="listings wow fadeInUp">
										<li>Business Intelligence and Data Warehousing Solutions</li>
										<li>Dealer Management Systems (DMS)</li>
										<li>E-Commerce, CRM and ERP Solutions</li>
										<li>Enterprise Mobility and Transformation</li>
										<li>Product Life Cycle Management (PLCM)</li>
										<li>Rapid Prototyping and Product Testing Support</li>
										<li>Supply Chain Management (SCM)</li>
										<li>Web Applications / Portals</li>
										<li>Mobile Applications / Mobility Solutions</li>
									</ul>
									<h3 className="wow fadeInUp">4. LIMITATIONS</h3>
									<p className="wow fadeInUp">In no event shall digiPanda Technologies Ltd. or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on digiPanda Technologies Ltd.'s website, even if digiPanda Technologies Ltd. or a digiPanda Technologies Ltd. authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
									<h3 className="wow fadeInUp">5. ACCURACY OF MATERIALS</h3>
									<p className="wow fadeInUp">The materials appearing on digiPanda Technologies Ltd. website could include technical, typographical, or photographic errors. digiPanda Technologies Ltd. does not warrant that any of the materials on its website are accurate, complete or current. digiPanda Technologies Ltd. may make changes to the materials contained on its website at any time without notice. However digiPanda Technologies Ltd. does not make any commitment to update the materials.</p>
									<h3 className="wow fadeInUp">6. LINKS</h3>
									<p className="wow fadeInUp">digiPanda Technologies Ltd. has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by digiPanda Technologies Ltd. of the site. Use of any such linked website is at the user's own risk.</p>
									<h3 className="wow fadeInUp">8. GOVERNING LAW</h3>
									<p className="wow fadeInUp">These terms and conditions are governed by and construed in accordance with the laws of New Delhi, India and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
	    	</Container>
	  	</div>
	)
}

export default IndustriesContent;

