import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import image1 from '../../images/image1.jpg';
import image2 from '../../images/image2.jpg';
import image4 from '../../images/image4.jpg';

import {Link} from "react-router-dom";

const Benifits = () => {

	return (
	    <div className="HomeServices padding pb-50 border-bottom">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">EXPLORE THE <span>BENEFITS</span> OF WORKING AT XICOM</h2>
						<div className="column-box wow fadeIn">
							<Row>
								<Col md="4" className="sm-cols">
									<p> Flexible engagement models </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> 24x7 Support across all timezones </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Competitive pricing & ontime delivery </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> State of the art it infrastructure </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Strong technology competency</p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Seamless communication </p>
								</Col>
							</Row>
						</div>
						<Link to="#" className="same-btn wow fadeInUp"> Explore Your Next </Link>
					</Col>
					<Col lg="7">
						<div className="benifits-col">
							<OwlCarousel 
							className="owl-theme testimonial-slider"
							items="1" 
							loop 
							dots
							autoplay
							>
							    <div className='item'>
							    	<img src={image1} className="" alt="Benifits" />
							    </div>
							    <div className='item'>
							    	<img src={image2} className="" alt="Benifits" />
							    </div>
							    <div className='item'>
							    	<img src={image4} className="" alt="Benifits" />
							    </div>
							</OwlCarousel>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Benifits;
