import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import transparency from '../../images/transparency.png';
import ontimedelivery from '../../images/ontimedelivery.png';
import freesupport from '../../images/freesupport.png';
import flexible from '../../images/flexible.png';
import hours from '../../images/hours.png';



const Commitment = () => {

	return (
	    <div className="Commitment_area clearfix padding">
			<Container fluid>
				<Row>
					<Col md="12" className="text-center">
						<h2 className="wow fadeInUp">OUR COMMITMENT & <span>GUARANTEE</span></h2>
						<p className="wow fadeInUp">digiPanda delivers robust, scalable and high performance software, web and mobile app development services to help you harness the power of technology, consulting and maximize your online business investment.</p>
					</Col>
				</Row>
				<Row className="guarantee-row">
					<Col className="grnt-cols">
						<div className="icon">
							<img src={transparency}  alt="icon" />
						</div>
						<h5>100% TRANSPARENCY</h5>
						<p>At digiPanda, we believe in complete transparency with our clients and we keep them fully updated during the entire development production cycle.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={ontimedelivery}  alt="icon" />
						</div>
						<h5>95% ONTIME DELIVERY</h5>
						<p>Our pursuit of agile development methodology have resulted in an enviable 95% on-time delivery track record for the past 15+ years of our operations.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={freesupport}  alt="icon" />
						</div>
						<h5>FREE 30 DAYS SUPPORT</h5>
						<p>We take complete responsibility of our work and provide free 30 days support to all our customer even after the software / mobile app has been launched.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={flexible}  alt="icon" />
						</div>
						<h5>FLEXIBLE ENGAGEMENTS</h5>
						<p>digiPanda offers tailor-made engagement models, hand crafted to meet the exact requirements of clients with diverse business needs.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={hours}  alt="icon" />
						</div>
						<h5>24X7 SUPPORT</h5>
						<p>Our communication channels are open 24/7 that connects you with the appropriate team of experts to ensure smooth execution of your project.</p>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Commitment;
