import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "../components/Mirrorwalla/Hero";
import IndustriesContent from "../components/Mirrorwalla/IndustriesContent";
import PageTitle from "../components/Mirrorwalla/PageTitle";

import "../assets/Webdevelopment.css";

const Mirrorwalla = () => {
  return (
    <div className="App">
      <PageTitle />
      <Hero />
      <IndustriesContent />
    </div>
  );
};

export default Mirrorwalla;
