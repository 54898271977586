import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';
import q5 from '../../images/q5.png';
import q3 from '../../images/q3.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda IS A TOP ANDROID APP DEVELOPMENT COMPANY WITH OVER <span>50,000</span> MAN YEARS OF EXPERIENCE.</h2>
						<p className="wow fadeInUp">digiPanda is a ISO 9001 certified android app development company, backed by a strong workforce of 300+ experts offering custom Android app development services to help you surge ahead of the competition. digiPanda a leading android development company offers a plethora of highly innovative and impressive mobile applications based on Android platform that helps businesses enhance their marketing strategy and collaboration. Constant research and in-depth understanding of various Android platforms allows digiPanda to offer cutting edge android solutions for various industry verticals. The android development services offered by digiPanda are exclusively designed to furnish solutions that are built around the specific business requirements of the client across diverse market verticals. Hire Android app developers from digiPanda to develop performance-oriented and business-centric mobile apps for the latest Android platform.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ANDROID APP STRATEGY</h3>
									<p className="wow fadeInUp">Our Android strategy consultants helps in forming a successful strategy for the growth of your online mobile business.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ANDROID APP DESIGN</h3>
									<p className="wow fadeInUp">Design and user experience can make or break your Android app. Our Android app developers ensure excellent designs and UI experience.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ANDROID APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">We have 100+ expert Android app developers working on Android Studio with an average industry experience of 5+ years.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ANDROID APP TESTING</h3>
									<p className="wow fadeInUp">We have 25+ android app testers to provide you high performance and 100% reliable Android app testing services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q5} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ENTERPRISE ANDROID APPS</h3>
									<p className="wow fadeInUp">We have a strong team of Android app developers working on enterprise android apps for the fortune 1000 clients.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">GAME DEVELOPMENT</h3>
									<p className="wow fadeInUp">We have 50+ android game developers to provide you high performance and 100% reliable android gaming services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
