import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/SOHO/Hero";
import IndustriesContent from "../components/SOHO/IndustriesContent";
import PageTitle from "../components/SOHO/PageTitle";

import '../assets/Webdevelopment.css';



const SOHO = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<IndustriesContent />
	  </div>
	)
}

export default SOHO;
