import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const Whatsnew = () => {

	return (
	    <div className="WhatsNew">
			<Container fluid>
				<Row>
					<Col lg="3" md="6" className="whatsNew-col">
						<h3 className="wow fadeInUp">BI AND DATA VISUALIZATION</h3>
						<div className="border-line"></div>
						<p className="wow fadeInUp">To improve agility and efficiency of your organization, we help you modernize your business intellegence (BI) infrastructures and practices, be it information management, data quality or data integration.</p>
					</Col>
					<Col lg="3" md="6" className="whatsNew-col">
						<h3 className="wow fadeInUp">ENTERPRISE DATA MANAGEMENT</h3>
						<div className="border-line"></div>
						<p className="wow fadeInUp">EDM is the ability of an organization to precisely define, integrate and retrieve data for both internal and external applications. We provide EDM services focused on creation of accurate, consistent and transparent content.</p>
					</Col>
					<Col lg="3" md="6" className="whatsNew-col">
						<h3 className="wow fadeInUp">PREDICTIVE DATA MODELING</h3>
						<div className="border-line"></div>
						<p className="wow fadeInUp">We provide predictive analytics using many techniques like data mining, statistics, modeling, machine learning and artificial intelligence to analyze current data and make accurate predictions about the future.</p>
					</Col>
					<Col lg="3" md="6" className="whatsNew-col">
						<h3 className="wow fadeInUp">DATA SCIENCE SERVICES</h3>
						<div className="border-line"></div>
						<p className="wow fadeInUp">We offer data science consulting that help organizations take effective data driven business decisions through data ingestion, statistical modelling, algorithm development, insight generation and deployment.</p>
					</Col>
				</Row>
			</Container>
			
	    </div>
	);
};

export default Whatsnew;
