import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';
import q5 from '../../images/q5.png';
import q3 from '../../images/q3.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda IS ONE OF THE TOP CROSS PLATFORM APP DEVELOPMENT COMPANY WITH OVER <span>50,000</span> MAN YEARS OF EXPERIENCE.</h2>
						<p className="wow fadeInUp">digiPanda is an ISO 9001 certified, cross platform mobile app development company having a team of 300+ experts, providing custom cross platform app development services hybrid mobile app development services of any complexity with incredible competence. digiPanda's cross platform mobile app development services can definitely give your business a competitive advantage by delivering multi-performance cross platform mobile applications for various other platforms including iOS, Android and Windows. With several years of expertise, our cross platform app development team has proved its excellence by delivering robust and scalable cross platform mobile app development services for various frameworks including React Native, Xamarin, PhoneGap / Cordova, Titanium Appcelerator, Ionic and Sencha. We strive to build and implement feature-rich cross platform mobile apps that take your business ahead of your competition. Hire cross platform mobile app developers from digiPanda to build high-performance cross platform mobile applications.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">REACT NATIVE</h3>
									<p className="wow fadeInUp">React Native is a JavaScript framework and digiPanda is a leading React Native development company that builds close to native mobile apps using JavaScript and React.JS</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">FLUTTER</h3>
									<p className="wow fadeInUp">Flutter is a cross platform mobile app development SDK designed to build mobile apps for Android, iOS, and Google Fuchsia, a new platform being developed by Google.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">XAMARIN</h3>
									<p className="wow fadeInUp">Xamarin is a Microsoft supported framework for cross platform mobile app development. It uses C# and .NET native libraries wrapped in the ASP.NET architecture layer.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">TITANIUM APPCELERATOR</h3>
									<p className="wow fadeInUp">Titanium is an open, extensible mobile app development environment for creating native apps across different mobile devices including iOS, Android and HTML5.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q5} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IONIC FRAMEWORK</h3>
									<p className="wow fadeInUp">digiPanda specializes in Ionic app development services which is an open source framework used to build highly interactive, robust and scalable cross platform mobile apps using HTML5, CSS and Angular JS.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CORDOVA PHONEGAP</h3>
									<p className="wow fadeInUp">digiPanda can help you to leverage the most popular cross platform, open source mobile app development tool - PhoneGap using HTML, CSS, JavaScript, Web APIs alongwith jQuery Mobile, Backbone.js etc.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
