import React from "react";
import { Container } from "react-bootstrap";

import WOW from "wowjs";

class Hero extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <div className="HereoBanner eCommerceBg">
        <div className="Banner_Content">
          <Container fluid>
            <h2 className="pagehead-title wow fadeInUp">
              Introducing Smart eCommerce sites
            </h2>
            <div className="red-border"> </div>
            <p> The future of selling online…</p>
            <a
              href="https://calendly.com/digipanda-in/30min"
              target="_blank"
              className="bookAdemo"
            >
              {" "}
              Book a demo{" "}
            </a>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero;
