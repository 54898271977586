import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/ArtificialIntelligence/Hero";
import DevelopServices from "../components/ArtificialIntelligence/DevelopServices";
import Technology from "../components/ArtificialIntelligence/Technology";
import Process from "../components/ArtificialIntelligence/Process";
import IndustryBussinessjs from "../components/ArtificialIntelligence/IndustryBussinessjs";
import Commitment from "../components/ArtificialIntelligence/Commitment";
import Customers from "../components/ArtificialIntelligence/Customers";
import Success from "../components/ArtificialIntelligence/Success";
import PageTitle from "../components/ArtificialIntelligence/PageTitle";


import '../assets/Webdevelopment.css';



const ArtificialIntelligence = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  	</div>
	)
}

export default ArtificialIntelligence;
