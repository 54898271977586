import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

import {Link} from "react-router-dom";



const IndustriesContent = () => {
	return(
		<div className="industries-content">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="inner-box">
							<Row>
								<Col lg="8" md="12" className="left-sec">
									<h3 className="mt-0 wow fadeInUp">ROLE/POSITIONING</h3>
									<p className="wow fadeInUp">An IT services industry that helps your agency with all its IT needs. No matter what background you belong to like entertainment, legal or any others we got you covered. Soho understands that IT is the inevitable part of any digitally spread business hence it aims to take responsibility for all your IT needs. It’s versatile and relevant as it ensures that your IT needs are fulfilled to keep you ahead of all. Soho offers the necessary solutions you might need and you can trust.</p>
									<h3 className="wow fadeInUp">Target Market</h3>
									<p className="wow fadeInUp">Soho is for every niche that needs IT solutions and services. They deal with onsite IT services, CDO Consulting, the Legal industry, entertainment industry and other IT projects. They are full fledge IT, solution providers.</p>
									<h3 className="wow fadeInUp">BRAND & BRIEF</h3>
									<p className="wow fadeInUp">Soho was established in 2002 to provide information technology services to Southern California and New York businesses. They have a wide range of clients with expertise in the entertainment and legal industries. Soho in a few years developed an Onsite IT service on demand. They have an experienced team of technicians who excel at clients' IT infrastructure and business objectives. The Soho team has a strong commitment to trust, reliability and customisation with countless clients.</p>
									<h3 className="wow fadeInUp">THE CHALLENGES</h3>
									<p className="wow fadeInUp">With the entire world turning towards the digital platform, it is nearly impossible that when people signup for your services or product they won’t look for you over the internet. Hence, the website of your business is the foundation that decides the future of your business. The quality of your digital personality is the first impression people get and that ends up being the deciding factor too if a visitor will turn into your paying customer or not.Hence, monitoring and maintaining your website performance can’t be neglected before better</p>
									<p className="wow fadeInUp">When Soho joined hands with digiPanda, our first step was to stop the quality leakage.</p>
									<h3 className="wow fadeInUp">Team Analysis</h3>
									<p className="wow fadeInUp">Our expert team analysed that the existing website was made with a messy code. It was unorganized and arise a variety of issues. It directly affected the ability of search engines to index the site’s content thereby damaging the search ranking. Other problems that we spotted were:</p>
									<ul className="listings wow fadeInUp">
										<li>Broken links</li>
										<li>Outdated website design</li>
										<li>Improper use of subdomain and subfolders</li>
										<li>Poor quality of graphics</li>
										<li>Slow loading time</li>
										<li>Mobile incompatibility</li>
										<li>Lack of SEO optimisation</li>
									</ul>
									<h3 className="wow fadeInUp">digiPanda Strategy</h3>
									<p className="wow fadeInUp">As Soho in itself is an IT service provider it goes mandatory to have an amazing website for the potential audience to believe in it. When we aim to deliver a responsive website, it means to deliver an interactive and simple viewing website experience that people can understand easily. We worked on building a seamless user experience. This is a must-have facility as a non-responsive website will only give your user a poor experience and it might get tough to convince the visitor to sign up for any services. When we speak of a good website, they are easy to adapt on different smart devices smoothly. With a responsive website, you are already ahead of your competition. This also improves your SEO efforts when it’s about search ranking. The old Soho website that our team worked on was not optimised for the mobile view which directly hampered the ranking. We made the website optimised and fixed the navigation menu too. It made the website accessible on mobiles derived meaningful results and improved accessibility on the site.</p>
									<ul className="listings wow fadeInUp">
										<li>Broken links</li>
										<li>There were other measures that our team took like choosing a framework. The first step was to choose a suitable framework. We made a list of elements that were responsible for a poor website.</li>
										<li>Then we converted the codes and repeated the process for all necessary pages.</li>
										<li>Intensive responsive tests were taken online to test the working of the same. The more test we took the better the user experience got.</li>
										<li>In the end, we reached a point where the website was ready to roll. </li>
									</ul>
									<h3 className="wow fadeInUp">OUR WORK PROCESS</h3>
									<p className="wow fadeInUp">ThedigiPanda team speaks more of solutions and less of problems. Our objective is to provide a complete solution with the best possible approach and project analysis.</p>
									<ul className="listings wow fadeInUp">
										<li>We start with a tailor-made strategy as no two websites are the same.</li>
										<li>Then we understand the loopholes and fix them. Our strategies are built with a thorough interaction and feedback mechanism. Intensive testing is mandatory and makes a huge difference in the end.</li>
									</ul>
									<h3 className="wow fadeInUp">Strategy To Success</h3>
									<p className="wow fadeInUp">By the time you read this case study, more than 10-15 people must have visited and left your website. Whether a visitor will stay on a website or not take only a few seconds for him or her to decide. Hence the secret to success is definitely a good online identity. We help youfocus on the website interface and easy usability. We used features like:</p>
									<ul className="listings wow fadeInUp">
										<li>Custom Branding</li>
										<li>Ai Scanner, Add anything and everything</li>
										<li>Automated updating, No limit on information,</li>
										<li>Share with anyone and anywhere</li>
										<li>Contactless sharing</li>
										<li>Click to navigate</li>
										<li>Automated updating</li>
										<li>Build traffic</li>
										<li>90-sec brand video upload</li>
									</ul>
									<p className="wow fadeInUp">We also ensured that the data privacy function was secured and protected.Our main objective was to develop an error-free application with smooth navigation to help increase the traffic surge and usage rate.</p>
								</Col>
								<Col lg="4" md="12" className="right-sec">
									<div className="blue-box wow fadeInUp">
										<h4 className="title-wht"> Request Information </h4>
										<p className="text-white">To start a dialogue with our consultant, please complete our contact form and we shall get in touch with you shortly. </p>
										<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
									</div>
									<h3 className="btm-title wow fadeInUp"> TECHNOLOGIES </h3>
									<p>WordPress CMS, JQuery, Contact Form7, Yoast plugin for SEO, Apache Server</p>
									<h3 className="btm-title wow fadeInUp"> CLIENT REVIEWS </h3>
									<p>During our interaction with the digiPanda team we found that it is an extremely professional company with an expert team of designers and developers. It has demonstrated a high degree of flexibility and scalability to enable us meet the strategic goals. The team showed high level of commitment and completed the project in a very smooth manner meeting all the requirements and in the timeframe provided. We will definitely contact them for our other projects in future.</p>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
	    	</Container>
	  	</div>
	)
}

export default IndustriesContent;

