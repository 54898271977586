import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import careers from '../../images/careers.jpg';

import {Link} from "react-router-dom";


const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="8">
						<h2 className="wow fadeInUp">READY FOR A CHALLENGING AND <span>ENJOYABLE</span> WORK CULTURE?</h2>
						<p className="wow fadeInUp">At DigiPanda, we make sure that we can offer amazing services to our clients, give them a happy face and also be able to keep ourselves happy. The overall environment at our place is extremely motivating and friendly that helps us a lot. </p>
					</Col>
					<Col lg="4">
						<Link to="#">
							<img src={careers} className="img-responsve" alt="testimonials" />
						</Link>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
