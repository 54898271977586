import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp text-uppercase">At DigiPanda, we develop mobile-based products that add value to your <span>business</span> and the life of your customers. </h2>
						<p className="wow fadeInUp">Our team focuses aggressively on the user experience that users will get through the product, which makes us different from others. </p>
						<p className="wow fadeInUp">Our team is filled with experts providing services under custom mobile app development, dealing with the complexity of mobile applications, developing mobile-based products based on innovation and client ideas, and more. </p>
						<p className="wow fadeInUp">We are a result-oriented mobile app development company providing services to every work sector, including healthcare, management, financial, technical, development-based, and others. </p>
						<p className="wow fadeInUp">Mobile products developed by our team will have the main motive of fulfilling your requirements and targets while keeping in mind the user experience offered to the users. So hire mobile app developers from DigiPanda to get professional mobile development solutions that fulfill your targets. </p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ANDROID APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Our team helps you develop easy-to-use and scalable android applications that meet your requirements within your budget. </p>
									<Link to="/android-app-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IOS APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Want to get iOS apps that enhance your business? If yes, then at DigiPanda, we always develop professional products with targeted requirements. </p>
									<Link to="/ios-app-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CROSS PLATFORM APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Get high-quality cross platform applications with our expert team handling Xamarin, PhoneGap, React Native and other frameworks.</p>
									<Link to="/cross-platform-app-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">REACT NATIVE APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Build an exceptional and superior performing react native application using our react native development services. </p>
									<Link to="/react-native-app-development" className="href_link"></Link>
								</div>
							</Col>
						
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
