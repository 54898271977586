import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const Customers = () => {

	return (
	    <div className="consul_hire benifits border-0 bg-white">
			<Container fluid>
				<Row>
					<Col md="12 text-center">
						<h2 className="wow fadeInUp">Accessible websites <span>guarantee:</span></h2>
					</Col>
				</Row>
				<Row className="mt-5">
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Respect for the Law</h3>
							<p className="exp wow fadeInUp">The law forbids discrimination against people with disabilities in all settings, including online platforms. 7,633 ADA website compliance complaints were filed in 2017 alone, and this figure increased sharply by almost 200 percent in 2018.</p>
							<p className="exp wow fadeInUp">Using ADA website complying services to your advantage shields your business from frequent web accessibility lawsuits. You can avoid spending many thousand dollars on a defense attorney and court proceedings if your case is ADA web compliant.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Everyone's Ability to Access</h3>
							<p className="exp wow fadeInUp">Millions of disabled individuals globally have technology demands that need to be better understood and given more attention, including the need for material from internet sources. Your website's visitors will have equal access to your digital material and the ability to make educated decisions thanks to an ADA-compliant website.</p>
							<p className="exp wow fadeInUp">The web experience for your clients is improved and memorable brand interactions are facilitated by strict compliance with web content accessibility requirements. Furthermore, ADA-compliant websites give internet consumers a sense of respect and importance.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Eligibility for ADA Tax Credits</h3>
							<p className="exp wow fadeInUp">Small companies who prioritize web accessibility and satisfy the ADA tax incentive 2021 requirements may be eligible for an IRS ADA tax credit of up to $5,000 or an ADA tax rebate of up to $5,000. Industry experts and marketers who wish to increase the accessibility of their websites and their online presence but are having financial difficulties might greatly benefit from these ADA tax benefits.</p>
							<p className="exp wow fadeInUp">A website's total expenses for ADA compliance during a tax year are covered by a tax credit for ADA compliance services.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Greater Potential for Conversion</h3>
							<p className="exp wow fadeInUp">Different disabilities take different shapes. Businesses that tailor their website to serve this demographic will significantly increase the number of users who can get the content they need. A website that complies with ADA regulations enables you to engage various custom audiences. Your prospects of increasing your return rate are higher hence more people who can view your website.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Increasingly Profitable</h3>
							<p className="exp wow fadeInUp">Websites that are ADA compliant boost sales and encourage patron loyalty. Disabled people have enormous purchasing power, even when their overall income is reduced.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Competitive Benefit</h3>
							<p className="exp wow fadeInUp">Many companies make the error of ignoring people with impairments in the workforce. But most businesses are unaware that the disabled population is a sizable economic bloc, accounting for around 10% of all internet purchases.</p>
							<p className="exp wow fadeInUp">Your ability to maintain a competitive advantage depends on your website's ADA compliance. By being able to create a website ADA compliant, you may increase accessibility to your goods and services and defend your brand from litigation about ADA website compliance.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Recommendations for Optimization of Search Engines (SEO)</h3>
							<p className="exp wow fadeInUp">Numerous SEO components, including text size, color contrast, sitemaps, descriptions, video transcription, and picture alt tags, intersect with ADA guidelines. By adhering to web standards, you enhance your Search rankings and give both website visitors and search engines a favorable view of your business.</p>
							<p className="exp wow fadeInUp">Screen readers can browse websites that adhere to WCAG standards. An assistive technology called a screen reader searches online sites and produces voice from text and visual content. This makes it possible for users with visual impairments, learning difficulties, or blindness to consume the content on your website. Search engine crawlers and screen readers can easily crawl and present information to persons with impairments thanks to ADA web compliance.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">An impressive Website Reputation</h3>
							<p className="exp wow fadeInUp">Only 11% of disabled individuals would contact friends or relatives for assistance when they meet an accessibility barrier, according to a "Click Away Pound" survey. This signifies that the majority of online shoppers and browsers who are impaired will abandon your website and go for one that complies with the ADA.</p>
							<p className="exp wow fadeInUp">Because word-of-mouth promotion spreads quickly, it only takes one online comment or referral to change how other people regard your company. It works well to broaden your internet audience and draw more visitors to your website.</p>
							<p className="exp wow fadeInUp">The upkeep of an ADA-compliant website also safeguards your online standing, allowing you the go-to company for your target market and their links.</p>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Customers;
