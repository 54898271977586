import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import adaComplianceImg from '../../images/adaComplianceImg.jpg';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding mb-5">
			<Container fluid>
				<Row>
					<Col lg="6">
						<p className="wow fadeInUp">A manual audit is another way to determine whether your website complies with the ADA. A manual audit entails applying the <b>Web Content Accessibility Guidelines(WCAG)</b> to assess the accessibility of each page on your website. Although WCAG is a sizable criterion, it serves as the foundation for ADA compliance.</p>
						<p className="wow fadeInUp">The absence of supportive technologies and communicative information methods that are not adapted has an impact on the one billion individuals with disabilities who live around the globe. With an ADA-compliant website, you can ensure that all visitors to the site have equal access to your web-based information. </p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="6">
						<img src={adaComplianceImg} className="" alt="ADA Compliance" />
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
