import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import c1 from '../../images/c1.png';
import c2 from '../../images/c2.png';
import c3 from '../../images/c3.png';
import c4 from '../../images/c4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">At DigiPanda, we offer <span>IT Consulting</span> services that help you develop </h2>
						<p className="wow fadeInUp">innovative products for your customers, figure out IT solutions and strategies. In short, you would be able to make your business IT-compatible. </p>
						<p className="wow fadeInUp">Whether you want to reduce the operation cost of your business, make changes in your IT strategies, or anything linked with IT solutions, DigiPanda is your go-to service provider. We offer high-quality solutions for all your IT requirements, and everything works in a systematic way that makes it easier for you to understand the whole process. </p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">PRODUCT ENGINEERING SOLUTIONS</h3>
									<p className="wow fadeInUp">Build a product that stands out from the competition and helps your business achieve higher sales, brand awareness, and more. </p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MANAGED IT SOLUTIONS</h3>
									<p className="wow fadeInUp">We help you manage your IT solutions to let you focus completely on growing your business. </p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CLOUD SOLUTIONS</h3>
									<p className="wow fadeInUp">Integrate your softwares with cloud support that helps in enhancing the business reach and support. </p>
									<Link to="/cloud-computing" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ENGAGEMENT MODELS</h3>
									<p className="wow fadeInUp">Develop an exceptional framework between clients and the outsourcing vendors with the help of our engagement models development services. </p>
								</div>
							</Col>
						
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
