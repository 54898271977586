import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/CloudComputing/Hero";
import DevelopServices from "../components/CloudComputing/DevelopServices";
import Technology from "../components/CloudComputing/Technology";
import Process from "../components/CloudComputing/Process";
import IndustryBussinessjs from "../components/CloudComputing/IndustryBussinessjs";
import Commitment from "../components/CloudComputing/Commitment";
import Customers from "../components/CloudComputing/Customers";
import Success from "../components/CloudComputing/Success";
import PageTitle from "../components/CloudComputing/PageTitle";


import '../assets/Webdevelopment.css';



const CloudComputing = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  	</div>
	)
}

export default CloudComputing;
