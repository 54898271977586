import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Contact/Hero";
import Getintouch from "../components/Contact/Getintouch";
import PageTitle from "../components/Contact/PageTitle";

import '../assets/contact.css';

const Contact = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<Getintouch />
	  </div>
	)
}

export default Contact;
