import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Ecommercesolution/Hero";
import DevelopServices from "../components/Ecommercesolution/DevelopServices";
import TransformSection from "../components/Ecommercesolution/TransformSection";
import Process from "../components/Ecommercesolution/Process";
import Success from "../components/Ecommercesolution/Success";
import PageTitle from "../components/Ecommercesolution/PageTitle";

import '../assets/Webdevelopment.css';



const Ecommercesolution = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<TransformSection />
	    	<Process />
	    	<Success />
	  </div>
	)
}

export default Ecommercesolution;
