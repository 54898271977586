import React from "react";
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import keras from '../../images/keras.png';
import apachespark from '../../images/apachespark.png';
import pytorch from '../../images/pytorch.png';
import microsoftazure from '../../images/microsoftazure.png';
import dl4j from '../../images/dl4j.png';

const Technology = () => {

	return (
	    <div className="transform-row">
	    	<div className="transform-sec">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">WE USE SMART <span>TECHNOLOGIES</span> TO BUILD SMARTER APPLICATIONS</h2>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec">
				<Container fluid>
					<Row>
						<Col md="12" className="">
							<div className="brands technology_brands wow fadeInUp">
								<ListGroup>
								  <ListGroup.Item><img src={keras} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={apachespark} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={pytorch} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={microsoftazure} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={dl4j} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								</ListGroup>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default Technology;
