import React from "react";
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import microsoftazure from '../../images/microsoftazure.png';
import amazonec2 from '../../images/amazonec2.png';
import heroku from '../../images/heroku.png';
import ibmblue from '../../images/ibmblue.png';
import rackspace from '../../images/rackspace.png';

const Technology = () => {

	return (
	    <div className="transform-row">
	    	<div className="transform-sec">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">WE USE SMART <span>TECHNOLOGIES</span> TO BUILD SMARTER APPLICATIONS</h2>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec">
				<Container fluid>
					<Row>
						<Col md="12" className="">
							<div className="brands technology_brands wow fadeInUp">
								<ListGroup>
								  <ListGroup.Item><img src={microsoftazure} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={amazonec2} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={heroku} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={ibmblue} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={rackspace} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								</ListGroup>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default Technology;
