import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import softwaredevelopment from '../../images/softwaredevelopment.png';
import software from '../../images/software.png';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner Webdevelopment softdev">
			    <div className="Banner_Content">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="7" className="banner-left-img cms-img">
								<div className="left-img">
									<img src={softwaredevelopment} className="wow fadeInUp" width="100%" alt="Developemnt" />
								</div>
							</Col>
							<Col lg="5" className="banner-right-content">
								<div className="heart-img">
									<img src={software} className="wow zoomIn" alt="Developemnt" />
								</div>
								<h2 className="wow fadeInUp"><span>SOFT</span>WARE <br /> DEVELOPMENT</h2>
								<p>Our software development services comprise engineering, designing, deploying, supporting, and evolving various processes that help us develop the best possible product for the clients. </p>
								<p>We serve all types of enterprises across multiple industries, including retail, fashion, marketing, financial, manufacturing, healthcare, and more. </p>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
