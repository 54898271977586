import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';


import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding mb-5">
			<Container fluid>
				<Row>
					<Col lg="6">
						<h2 className="wow fadeInUp">Cost-effective solution for every type of <span>development</span> and IT solution. </h2>
						<p className="wow fadeInUp">Our team will be in direct contact with the clients with the help of which they can get better transparency. With the integration of a dedicated teams model, you can have complete control over all the resources linked with your business processes.</p>
						<Link to="/web-development" className="same-btn wow fadeInUp">Explore Services</Link>
					</Col>
					<Col lg="6">
						<Row className="dedicated-teams">
							<Col md="12">
								<div className="column-box">
									<Row>
										<Col lg="12" className="sm-cols">
											<h4><span>BENEFITS</span> OF OUR DEDICATED TEAMS MODEL</h4>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p> Get in touch with technology and IT solution experts with immense experience.  </p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p>Build IT infrastructures customized for your business. </p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p>Single point of contact for better communication management.</p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p>Complete transparency linked with project flow and other details.</p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p>Use of a comprehensive reporting system.</p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p>Custom engagement models.</p>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
