import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import appdevelopment from '../../images/appdevelopment.png';
import app from '../../images/app.png';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner Webdevelopment">
			    <div className="Banner_Content">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="7" className="banner-left-img cms-img">
								<div className="left-img app-dev">
									<img src={appdevelopment} className="wow fadeInUp" width="100%" alt="Developemnt" />
								</div>
							</Col>
							<Col lg="5" className="banner-right-content">
								<div className="heart-img">
									<img src={app} className="wow zoomIn" alt="Developemnt" />
								</div>
								<h2 className="wow fadeInUp"><span>MOBILE</span> APP <br /> DEVELOPMENT</h2>
								<p>We provide secure and optimal-performing mobile applications; Our team has developed applications for startups, small businesses, large enterprises, and many more. </p>
								<p>We have a huge checklist for iPhone app development, android app development, iPad app development, and tablet app development. </p>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
