import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import seo from '../../images/seo.jpg';

import {Link} from "react-router-dom";


const ConsultingServices = () => {

	return (
	    <div className="consul_hire benifits bg-white ps-0 pe-0 pb-0">
			<Container fluid>
				<Row>
					<Col lg="12" className="text-center">
						<h2 className="wow fadeInUp">WE TAKE CARE OF YOUR <span>DIGITAL</span> PRESENCE</h2>
						<p className="small-para wow fadeInUp mb-4 pt-4">digiPanda is a Professional Digital Marketing company that provides various types of high-quality services. We provide services like Search Engine Optimization, Web Development.</p>
						<p className="small-para wow fadeInUp"> With our expert team, your business would be able to get targeted results leading to higher conversions and growth. Along with that, you can let our team know about your goals and based on which they will design a particular digital marketing strategy for your business.</p>
					</Col>
				</Row>
			</Container>
			<div className="consul_hire benifits ps-0 pe-0 mt-4">
				<Container fluid>
					<Row>
						<Col lg="6">
							<div className="digital-content">
								<h3 className="wow fadeInUp"> Search Engine Optimization <span>(SEO) </span></h3>
								<h4 className="wow fadeInUp">OUR BUSINESS HELP YOU STRENGTHEN YOUR BRAND’S ONLINE PRESENCE ORGANICALLY </h4>
								<p className="exp wow fadeInUp"> At digiPanda, we are a team of professional SEO service providers who can help your business in figuring out the right keywords, creating top-notch content, building your website's backlinks, curating content, checking out the templates, and many more.</p>
								<p className="exp wow fadeInUp">Our team will listen to your thoughts, and based on your requirement, a personalized SEO plan would be created that will lead your business to reach more people and generate high-quality leads organically.</p>
								<Link to="/search-engine-optimization" className="same-btn wow fadeInUp"> Explore our SEO services </Link>
							</div>
						</Col>
						<Col lg="6">
							<div className="digitial-marketing-img">
								<img src={seo} alt="logo" />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default ConsultingServices;
