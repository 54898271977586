import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/TechnicalLead/Hero";
import Getintouch from "../components/TechnicalLead/Getintouch";
import PageTitle from "../components/TechnicalLead/PageTitle";

import '../assets/contact.css';

const TechnicalLead = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<Getintouch />
	  </div>
	)
}

export default TechnicalLead;
