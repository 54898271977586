import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import feature from '../../images/feature.png';
import feature2 from '../../images/feature2.jpg';
import feature3 from '../../images/feature3.png';
import {Link} from "react-router-dom";



const IndustriesContent = () => {
	return(
		<div className="industries-content blog_content">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="inner-box">
							<Row>
								<Col lg="8" md="12" className="left-sec">
									<div className="blog-post">
										<h5><span>JUN 16, 2021</span> / MOBILE APP DEVELOPMENT</h5>
										<h3 className="mt-0 wow fadeInUp"><Link to="/build-a-food-delivery-app-like-ubereats">How to build a food delivery app like Ubereats and Deliveroo</Link></h3>
										<img src={feature} className="blog mb-4" alt="blog" />
										<p className="wow fadeInUp">AlgoTrader is basically a first fully-integrated algorithmic trading software solution for quantitative hedge funds. It allows automation of complex, quantitative trading strategies in Equity, Forex and Derivative markets. AlgoTrader provides everything a typical quantitative hedge fund needs on an everyday basis to run his operation and it is also the very first and only algorithmic trading software product to allow automated trading of Bitcoin and other Cryptocurrencies. Any hedge fund, brokerage firm or individual can get and use AlgoTraderâ€™s inbuilt algorithms, past data and key indicators to create innovative and successful trading strategies and take their trading decisions based on that with high level of probability of success. AlgoTrader currently supports over 100 trading strategies and already has more than 80 clients and 400+ supported brokers and exchanges.</p>
									</div>
									<div className="blog-post">
										<h5><span>JUN 14, 2021</span> / HIRE MOBILE APP DEVELOPER</h5>
										<h3 className="mt-0 wow fadeInUp"><Link to="/build-a-food-delivery-app-like-ubereats">HOW TO MAKE A SUCCESSFUL MESSAGING APP IN 2021?</Link></h3>
										<img src={feature2} className="blog mb-4" alt="blog" />
										<p className="wow fadeInUp">AlgoTrader is basically a first fully-integrated algorithmic trading software solution for quantitative hedge funds. It allows automation of complex, quantitative trading strategies in Equity, Forex and Derivative markets. AlgoTrader provides everything a typical quantitative hedge fund needs on an everyday basis to run his operation and it is also the very first and only algorithmic trading software product to allow automated trading of Bitcoin and other Cryptocurrencies. Any hedge fund, brokerage firm or individual can get and use AlgoTraderâ€™s inbuilt algorithms, past data and key indicators to create innovative and successful trading strategies and take their trading decisions based on that with high level of probability of success. AlgoTrader currently supports over 100 trading strategies and already has more than 80 clients and 400+ supported brokers and exchanges.</p>
									</div>
									<div className="blog-post">
										<h5><span>JUN 13, 2021</span> / MOBILE APP DEVELOPMENT</h5>
										<h3 className="mt-0 wow fadeInUp"><Link to="/build-a-food-delivery-app-like-ubereats">HOW MUCH DOES IT COST TO MAKE A FITNESS APP</Link></h3>
										<img src={feature3} className="blog mb-4" alt="blog" />
										<p className="wow fadeInUp">AlgoTrader is basically a first fully-integrated algorithmic trading software solution for quantitative hedge funds. It allows automation of complex, quantitative trading strategies in Equity, Forex and Derivative markets. AlgoTrader provides everything a typical quantitative hedge fund needs on an everyday basis to run his operation and it is also the very first and only algorithmic trading software product to allow automated trading of Bitcoin and other Cryptocurrencies. Any hedge fund, brokerage firm or individual can get and use AlgoTraderâ€™s inbuilt algorithms, past data and key indicators to create innovative and successful trading strategies and take their trading decisions based on that with high level of probability of success. AlgoTrader currently supports over 100 trading strategies and already has more than 80 clients and 400+ supported brokers and exchanges.</p>
									</div>
								</Col>
								<Col lg="4" md="12" className="right-sec">
									<div className="blue-box wow fadeInUp">
										<h4 className="title-wht">LOOKING FOR CERTIFIED & EXPERIENCED DEVELOPERS </h4>
										<p className="text-white">To start a dialogue with our consultant, please complete our contact form and we shall get in touch with you shortly. </p>
										<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
									</div>
									<h3 className="btm-title wow fadeInUp"> Digipanda Tech LTD. </h3>
									<p>Founded in the year 2002, digiPanda is a ISO 9001 certified software development company with a strong team of 300+ highly skilled IT experts, catering result-oriented and cost-competitive solutions to SME's across the world.</p>
									<h3 className="mb-4 btm-title wow fadeInUp"> RECENT POSTS </h3>
									<p><Link to="/build-a-food-delivery-app-like-ubereats">How to build a food delivery app like Ubereats and Deliveroo</Link></p>
									<p><Link to="/build-a-food-delivery-app-like-ubereats">How To Make A Successful Messaging App In 2021?</Link></p>
									<p><Link to="/build-a-food-delivery-app-like-ubereats">How Much Does It Cost to Make a Fitness App</Link></p>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
	    	</Container>
	  	</div>
	)
}

export default IndustriesContent;

