import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	     <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">At digiPanda we create a website that enhances your product and services. It’s the foundation of your <span>business.</span> Your website is your digital personality hence, it matters.</h2>
						<p className="wow fadeInUp">Someone said the first impression is the last impression and nothing is as true as it is. Your website is the way you will communicate to your potential consumer. Hence, it’s immediately essential if you want to succeed. At digiPanda you will come across the experts who can offer you your dream website. Express your brand your way, you are just one good website away from success. </p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">WEB DESIGN & DEVELOPMENT</h3>
									<p className="wow fadeInUp">Offer your visitors an experience they can’t forget. Website is a virtual journey into your brand. We create, personalise, design and make it more compelling.</p>
									<Link to="/web-design-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ECOMMERCE SOLUTIONS</h3>
									<p className="wow fadeInUp">We create e-commerce stores on a different platform to make your brand omnipresent. Our experts analyse your approach and offer solutions that work.</p>
									<Link to="/ecommerce-solution" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">PHP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Our team have enrolled the best PHP developers.  It’s essential to connect you’re your application to other web services to keep your brand relevant in changing times.</p>
									<Link to="/php-development" className="href_link"></Link>
								</div>
							</Col>
							
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">OPEN SOURCE SOLUTIONS</h3>
									<p className="wow fadeInUp">digiPanda team uses tons of open-source products like technologies, sources, frameworks and more to create the best work for the clients. </p>
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
