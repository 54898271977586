import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';


import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding mb-5">
			<Container fluid>
				<Row>
					<Col lg="6">
						<h2 className="wow fadeInUp text-uppercase">Solve the increasing demands of your clients by using modern <span>technologies</span> and integrations. </h2>
						<p className="wow fadeInUp">We at DigiPanda offer various technical solutions from growing, facing market challenges, scalability issues, and more. In addition, our team can offer application development, software development, building customer relations, and others. </p>
						<Link to="/web-development" className="same-btn wow fadeInUp">Explore Services</Link>
					</Col>
					<Col lg="6">
						<Row className="dedicated-teams">
							<Col md="12">
								<div className="column-box">
									<Row>
										<Col lg="12" className="sm-cols">
											<h4 className="text-uppercase"><span>BENEFITS</span> Benefits of our technical solutions & strategies: </h4>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p> Get in touch with technology and IT solution experts with immense experience.  </p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p> Build IT infrastructures customized for your business.  </p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p> Single point of contact for better communication management. </p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p> Complete transparency linked with project flow and other details. </p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p> Use of a comprehensive reporting system.</p>
										</Col>
										<Col lg="4" sm="6" className="sm-cols">
											<p> Custom engagement models.  </p>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
