import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {Link} from "react-router-dom";



const Customers = () => {

	return (
	    <div className="HomeServices padding pb-50">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">WHY CUSTOMERS IN OVER <span>10+</span> COUNTRIES CHOOSE DIGIPANDA?</h2>
						<div className="column-box wow fadeIn">
							<Row>
								<Col md="4" className="sm-cols">
									<p> Flexible engagement models </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> 24x7 Support across all timezones </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Competitive pricing & ontime delivery </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> State of the art it infrastructure </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Strong technology competency</p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Seamless communication </p>
								</Col>
							</Row>
						</div>
						<Link to="#" className="same-btn wow fadeInUp"> Explore Your Next </Link>
					</Col>
					<Col lg="7">
						<div className="testimonial-col">
							<OwlCarousel 
							className="owl-theme testimonial-slider"
							items="1" 
							loop 
							dots
							autoplay
							>
							    <div className='item'>
							        <h4> <span>JAMES HOPKIN </span> . WYNN TRADING </h4>
							        <p> "Excellence is earned and trust is built over time. Over 2 year period, we collaborated with Digipanda and we were able to save over 55% in our service related costs, cutting our expenses by upto five million dollars a year."</p>
							    </div>
							    <div className='item'>
							        <h4><span> KAREN COFFIELD</span> . AVIA DENTAL</h4>
							        <p> "We have always enjoyed a high level of professionalism, continuity, stability and a customer focused approach working with Digipanda. They provide excellent technical skills and project management capabilities."</p>
							    </div>
							</OwlCarousel>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Customers;
