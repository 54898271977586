import React from "react";
import { Helmet } from "react-helmet";

class PageTitle extends React.Component {
  render() {
    return (
      <div className="Page_Title">
        <Helmet>
          <title>
            MirrorWalla Case Studies, Technology, IT Industries | Digipanda
          </title>
          <meta
            name="description"
            content="MirrorWalla Case Studies, Technology, IT Industries | Digipanda"
          />
        </Helmet>
      </div>
    );
  }
}

export default PageTitle;
