import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/CaseStudies/Hero";
import Cstudys from "../components/CaseStudies/Cstudys";
import CustomerSection from "../components/CaseStudies/CustomerSection";
import PageTitle from "../components/CaseStudies/PageTitle";

import '../assets/Webdevelopment.css';



const CaseStudies = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<Cstudys />
	    	<CustomerSection />
	  	</div>
	)
}

export default CaseStudies;







