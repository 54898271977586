import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import c1 from '../../images/c1.png';
import c2 from '../../images/c2.png';
import c4 from '../../images/c4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">WHEN YOU OUTSOURCE TO INDIA, YOU GET ACCESS TO THE LARGEST POOL OF <span>DEVELOPERS</span> AND EXPERTS ACROSS THE WORLD.</h2>
						<p className="wow fadeInUp">Why outsource to DigiPanda? We have a team of experts with immense experience in various working sectors and with their knowledge they can help you & fulfill your requirements effortlessly. The most significant advantage is you don't need to work on any single process linked to the work. </p>
						<p className="wow fadeInUp">Our IT outsourcing services are one of the most effective methods with the help of which you can reduce the operational costs of your company and also provide high-quality IT solutions. Nowadays, most companies invest in IT outsourcing companies from India to outsource their work, which offers them quality work within a given time without actually handling a team from their end. </p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ACCESS TO SKILLED EXPERTISE</h3>
									<p className="wow fadeInUp">With our IT outsourcing services, you can directly access our skilled experts who are ready to offer you IT solutions. Moreover, this reduces the employment levels and the linked costs with it from your end. </p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">FOCUS ON CORE ACTIVITIES</h3>
									<p className="wow fadeInUp">We have professionals who can handle almost every core activity linked with your business, such as software development, mobile app development for android and iOS, and more. </p>
									<Link to="/software-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">COMPETITIVE ADVANTAGE</h3>
									<p className="wow fadeInUp">It helps you get competitive advantages as you save a lot of your money by not setting up a team and getting quality work done from the outsourcing work company. </p>

								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
