import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import { Link } from "react-router-dom";


const Profiles = () => {
	return (
	    <div className="padding">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="profiles_tab">
							<Accordion>
								<AccordionItem>
					                <AccordionItemHeading>
					                    <AccordionItemButton>
					                    	<Link to="#">
					                        	<h3>Android Developer</h3>
					                        	<div className="exp"> <span> Experience: </span> 2-4 Years </div>  
					                    		<div className="location"> <span> Location: </span> Delhi, &nbsp; Experience: B.Tech/MCA </div>
					                    	</Link>
					                    	<Link to="/technical-lead" className="same-btn wow fadeInUp">Apply Now!</Link>
					                    </AccordionItemButton>
					                </AccordionItemHeading>
					                <AccordionItemPanel>
					                	<h3 className="mt-4">RESPONSIBILITIES</h3>
					                    <ul>
					                    	<li>Design and build advanced applications for the Android platform.</li>
					                    	<li>Write scalable and maintainable code solutions for complex engineering challenges.</li>
					                    	<li>Unit-test code for robustness, including test cases, usability, and general reliability.</li>
					                    	<li>Work with cross-functional team including designers, information architects, backend developers to create optimum integration solutions.</li>
					                    	<li>Understand and follow standards, guidelines, and best practices adopted in the project.</li>
					                    	<li>Develop high quality Front End/UI patterns for mobile devices.</li>
					                    	<li>Publish apps on App Store.</li>
					                    	<li>Keep abreast of technology trends and advances in web / mobile technologies.</li>
					                    </ul>
					                    <h3 className="mt-4">MUST HAVE</h3>
					                    <ul>
					                    	<li>2- 4 yrs of experience with Strong OO design and programming skills in Java (J2EE/ J2ME).</li>
					                    	<li>Solid understanding of the android app development life cycle.</li>
					                    	<li>Excellent knowledge of Android SDK.</li>
					                    	<li>Knowledge of SQLite, MySQL or similar database management system.</li>
					                    	<li>Excellent working knowledge of Eclipse.</li>
					                    	<li>Strong understanding of mobile UI guidelines and best practices.</li>
					                    	<li>Experience developing and distributing applications via enterprise channels or public app stores.</li>
					                    	<li>Excellent knowledge of working with dynamic data (e.g., JSON, XML, AMF) through various interface types (e.g., REST, SOAP).</li>
					                    	<li>Must be able to handle multiple projects and deadlines.</li>
					                    </ul>
					                    <Link to="/technical-lead" className="same-btn wow fadeInUp mb-4">Apply Now!</Link>
					                </AccordionItemPanel>
					            </AccordionItem>
					            <AccordionItem>
					                <AccordionItemHeading>
					                    <AccordionItemButton>
					                    	<Link to="#">
					                        	<h3>Senior Php Developer</h3>
					                    		<div className="exp"> <span> Experience: </span> 2-4 Years </div>  
					                    		<div className="location"> <span> Location: </span> Delhi, &nbsp; Experience: B.Tech/MCA </div> 
					                    	</Link>
					                    	<Link to="/technical-lead" className="same-btn wow fadeInUp">Apply Now!</Link>
					                    </AccordionItemButton>
					                </AccordionItemHeading>
					                <AccordionItemPanel>
					                    <h3 className="mt-4">RESPONSIBILITIES </h3>
					                    <ul>
					                    	<li>Writing clean, fast PHP to a high standard, in a timely and scalable manner.</li>
					                    	<li>Act as the lead developer on large and more complex projects.</li>
					                    	<li>Work closely with the Project Management and Business Analyst groups to refine and understand business requirements to ensure the development is on target.</li>
					                    	<li>Develop and deploy new features to facilitate related procedures and tools if necessary.</li>
					                    	<li>Follow best industry practices and coding protocols.</li>
					                    	<li>Contribute in all phases of the development lifecycle.</li>
					                    	<li>Producing detailed project specifications.</li>
					                    	<li>Troubleshooting, testing and maintaining the core product software and databases.</li>
					                    </ul>
					                    <h3 className="mt-4"> MUST HAVE </h3>
					                    <ul>
					                    	<li>Proven software development experience in PHP.</li>
					                    	<li>Demonstrable knowledge of web technologies including HTML, CSS, Javascript, AJAX etc.</li>
					                    	<li>Having expertise in popular PHP frameworks like CakePHP / Codeigniter / Laravel etc.</li>
					                    	<li>Having experience integrating payment Gateways like Paypal, Authorize.net, Stripe etc.</li>
					                    	<li>Experience in Postgres, MySQL (exposure to NoSQL database related technologies like Mongo DB, Redis, Elastic search, Cassandra etc. is a plus).</li>
					                    	<li>Experience developing RESTful services and loosely coupled systems.</li>
					                    	<li>Well-versed with reporting and documentation skills.</li>
					                    	<li>Experience in common third-party APIs (Google, Facebook, Twitter etc.).</li>
					                    	<li>Ability to work with GitHub / Bitbucket version controlling system.</li>
					                    </ul>
					                    <Link to="/technical-lead" className="same-btn wow fadeInUp mb-4">Apply Now!</Link>
					                </AccordionItemPanel>
					            </AccordionItem>
					            <AccordionItem>
					                <AccordionItemHeading>
					                    <AccordionItemButton>
					                    	<Link to="#">
					                        	<h3>Business Development Manager </h3>
					                        	<div className="exp"> <span> Experience: </span> 2-4 Years </div>  
					                    		<div className="location"> <span> Location: </span> Delhi, &nbsp; Experience: B.Tech/MCA </div>
					                    	</Link>
					                    	<Link to="/technical-lead" className="same-btn wow fadeInUp">Apply Now!</Link>
					                    </AccordionItemButton>
					                </AccordionItemHeading>
					                <AccordionItemPanel>
					                    <h3 className="mt-4"> RESPONSIBILITIES </h3>
					                    <ul>
					                    	<li>Develop new accounts for offshore/onsite projects.</li>
					                    	<li>Manage the end-to-end Business Development and Sales process from creating a database,Lead generation, account penetration, pursuit strategy, and deal closure..</li>
					                    	<li>Sell IT services in International Market; market research; data mining etc.</li>
					                    	<li>Identifying potential business partners, researching, exploring and discovering opportunities for partnership.</li>
					                    	<li>Ensure conversion of qualified leads into Business.</li>
					                    	<li>Meeting, and exceeding where possible, quarterly and annual sales targets.</li>
					                    </ul>
					                    <h3 className="mt-4"> MUST HAVE </h3>
					                    <ul>
					                    	<li>3+ years of experience in business development in International Market..</li>
					                    	<li>Strong account mapping and head hunting skills with a proven track record of success in selling IT services/technology outsourcing services for International market.</li>
					                    	<li>Excellent communication, presentation and negotiation skills essential..</li>
					                    	<li>Self-starter who can effectively work within a strong team culture.</li>
					                    	<li>Candidate should have managed large conglomerates and global customers and must have engaged CXO /CIO's..</li>
					                    	<li>Ability to build strong rapport, establish trust and credibility through a consultative approach, and balance assertive sales follow-up without being overly aggressive.</li>
					                    </ul>
					                    <Link to="/technical-lead" className="same-btn wow fadeInUp mb-4">Apply Now!</Link>
					                </AccordionItemPanel>
					            </AccordionItem>
					        </Accordion>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Profiles;
