import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">WE HELP COMPANIES TO TAKE ADVANTAGE BY USING OUR ADVANCED <span>CLOUD</span> COMPUTING CAPABILITIES.</h2>
						<p className="wow fadeInUp">Cloud computing has eliminated business risk and helps in releasing new application by taking away traditional server costs that were incurred in the past. With a range of services provided by cloud such as computational facilities on-demand, storage services, software and video applications, enterprises of all scales are compelling to use them which provides them with more scalability, agility, data storage, on-demand access from any device, and furthermore, reduces the cost tremendously.We endeavor to provide cutting-edge cloud computing services to help clients in addressing their challenges in a customized manner. We also ensure simplified deployment and automated cloud management to establish an operational framework.</p>
						<p className="wow fadeInUp">Using digiPanda's cloud development services you can accelerate your application performance, grow revenue and conduct business globally. Our cloud solutions will help you engage customers through fast and personalized online experiences, gain IT agility and scale globally. Your connected audience expect instant, reliable and secure access to websites , applications and digiPanda can enable you to deliver that.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CLOUD STRATEGY & CONSULTING</h3>
									<p className="wow fadeInUp">digiPanda builds applications of AI which empower businesses to get ultimate benefits of machine learning which is a technology that allows machines to use data for self-learning. Machine Learning can be used to interpret complex data, trends, patterns to learn human behavior.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CLOUD APPLICATION DEVELOPMENT</h3>
									<p className="wow fadeInUp">We build elegant and engaging iOS apps that makes your business successful. We have professional iOS app developers who always design and develop easy to use, user friendly and cost-effective iOS applications.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CLOUD MIGRATION</h3>
									<p className="wow fadeInUp">digiPanda builds cross platform apps with Xamarin, PhoneGap, React Native and other frameworks. Cross platform app development makes it possible to use reusable codes which saves lots of development time and costs.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CLOUD SECURITY AND GOVERNANCE</h3>
									<p className="wow fadeInUp">digiPanda offers expert development of Augmented Reality apps. Right from retail to construction to daily enterprise activities, you can use the potential benefits of AR to make your key operations highly efficient, informed and cost effective for better ROI.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CLOUD MANAGEMENT SERVICES</h3>
									<p className="wow fadeInUp">Create, schedule and manage your data integration at scale with digiPanda's hybrid data integration service. Work with data wherever it lives, in the cloud or on-premises and with enterprise grade security.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CLOUD AI</h3>
									<p className="wow fadeInUp">digiPanda's Big Data Strategy consulting services provide strategy, engineering and analytics to help you maximize your data insights. Our end to end services, matched with industry specific skills and processes, help you make your data simpler to access and understand.</p>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
