import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Mirrorwalla from "../../images/Mirrorwalla.png";

import { Link } from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="HereoBanner Webdevelopment digipanda-portfolio">
        <div className="Banner_Content">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg="6" className="banner-left-img cms-img">
                <div className="left-img">
                  <img src={Mirrorwalla} alt="Developemnt" />
                </div>
              </Col>
              <Col lg="6" className="banner-right-content">
                <h2 className="wow fadeInUp text-white">Mirrorwalla</h2>
                <p className="text-white">
                  Mirrorwalla, a leading Indian brand, crafts exquisite mirrors
                  that transcend mere functionality. Their collections go beyond
                  reflecting your image but also reflect your style. From
                  statement pieces that make a bold design statement to elegant
                  accents that enhance any space, Mirrorwalla offers a treasure
                  trove of options.
                </p>
                <p className="text-white">
                  {" "}
                  Discover high-quality mirrors meticulously crafted to add
                  light, depth, and a touch of timeless beauty to your home.
                  Browse their diverse collection, whether you seek a touch of
                  modern minimalism or a hint of vintage charm, there's a
                  Mirrorwalla mirror waiting to be the perfect centrepiece for
                  your space.
                </p>
                <Link
                  target="_blank"
                  to={{ pathname: "https://mirrorwalla.com/" }}
                  className="red-btn"
                >
                  {" "}
                  Launch Live Website &nbsp; <FaTelegramPlane />{" "}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero;
