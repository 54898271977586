import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const ConsultingServices = () => {

	return (
	    <div className="consul_hire benifits">
			<Container fluid>
				<Row>
					<Col lg="10 offset-1" className="text-center">
						<h2 className="wow fadeInUp">OUR OUTSOURCING <span>BENEFITS</span></h2>
						<p className="small-para wow fadeInUp">Our team not only gets your work done on time and ensures quality assurance tests. But along with that, there are several benefits linked with our outsourcing services, which are as follows: </p>
					</Col>
				</Row>
				<Row>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> Key to Expertise </h3>
							<p className="exp wow fadeInUp"> DigiPanda offers tailored IT solution services to all the major web and software development companies, which helped them grow and build their expertise in the market. We have a robust team of Java, PHP, ASP.NET, MVC, Ruby on Rails, iPhone, Android, and more. </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> Top Rated Team </h3>
							<p className="exp wow fadeInUp"> We have a team of experts who handle all the aspects of IT services, and they are responsible for growing our clients' businesses till now. </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> Adaptability & Scalability </h3>
							<p className="exp wow fadeInUp"> Our team makes sure to know and adapt to your business equations, one we are aware of everything, then we start our ideations and help you scale your business. </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> Intellectual Property Protection </h3>
							<p className="exp wow fadeInUp"> You can get a competitive end over other businesses in the market with the help of intellectual property protection services.  </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> Cost Benefits </h3>
							<p className="exp wow fadeInUp"> With the help of our IT services and strategies, your business can get excellent cost benefits.  </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> Enhanced Communication </h3>
							<p className="exp wow fadeInUp"> Develop better communication channels with the help of professional IT services from DigiPanda.  </p>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default ConsultingServices;
