import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Logistics/Hero";
import IndustriesContent from "../components/Logistics/IndustriesContent";
import PageTitle from "../components/Logistics/PageTitle";

import '../assets/Webdevelopment.css';



const Logistics = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<IndustriesContent />
	  	</div>
	)
}

export default Logistics;
