import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

import {Link} from "react-router-dom";



const Success = () => {

	return (
	    <div className="SuccessArea">
			<Container fluid>
				<Row>
					<Col lg="12">
						<h2 className="wow fadeInUp">WHEN <span>SUCCESS</span> MATTERS</h2>
						<p className="wow fadeInUp">WE ARE COMMITTED TO BUILDING ROBUST AND SCALABLE APPLICATIONS THAT CREATES EFFICIENT BUSINESS PROCESSES AND ADDS VALUE TO OUR CUSTOMERS' BUSINESSES.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp mt-5">Get Free Estimation</Link>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Success;
