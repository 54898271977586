import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">WE TURN THE <span>INSIGHTS</span> FROM CONNECTED DEVICES INTO ACTION WITH ADVANCED IOT DEVELOPMENT.</h2>
						<p className="wow fadeInUp">Internet of Things or IoT is an ecosystem of connected physical objects and devices that are accessible through the internet. The â€˜thingâ€™ in IoT could be a person or an automobile with attached or inbuilt sensors i.e. objects that have been assigned an IP address and have the ability to collect and transfer data over a network without manual assistance. digiPanda's internet of things services enables organizations to transform business needs into competitive advantages by delivering innovative IoT powered solutions. We provide comprehensive IoT development services to our clients like integrating the right sensors, deriving insights and choosing the best development platform. digiPandaâ€™s IoT solutions allow businesses to focus on outcomes while we facilitate their digital transformation journey. As a leading IoT development company, our end-to-end IoT solutions portfolio with coverage over sensors, gateways, connectivity, cloud, user experience and analytics provide immense benefits to organizations. We help you improve business value, enhance efficiency and reduce operations and maintenance cost. We have partnered with leading innovators like Microsoft Azure, AWS IoT, MongoDB, WindRiver, Intel and various other device and sensor providers and deliver best-of-class solutions for all of your Internet of Things (IoT) requirements.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IOT CONSULTING</h3>
									<p className="wow fadeInUp">digiPanda is a preferred Android app development company that builds highly robust and scalable Android apps with easy user interface and better user experience (UI/UX) meeting your requirements and budget.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IOT APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">We build elegant and engaging iOS apps that makes your business successful. We have professional iOS app developers who always design and develop easy to use, user friendly and cost-effective iOS applications.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IOT TESTING</h3>
									<p className="wow fadeInUp">digiPanda builds cross platform apps with Xamarin, PhoneGap, React Native and other frameworks. Cross platform app development makes it possible to use reusable codes which saves lots of development time and costs.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">DATA ANALYTICS</h3>
									<p className="wow fadeInUp">digiPanda offers expert development of Augmented Reality apps. Right from retail to construction to daily enterprise activities, you can use the potential benefits of AR to make your key operations highly efficient, informed and cost effective for better ROI.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IOT INTEGRATIONS</h3>
									<p className="wow fadeInUp">Create, schedule and manage your data integration at scale with digiPanda's hybrid data integration service. Work with data wherever it lives, in the cloud or on-premises and with enterprise grade security.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IOT EXTENSION</h3>
									<p className="wow fadeInUp">digiPanda's Big Data Strategy consulting services provide strategy, engineering and analytics to help you maximize your data insights. Our end to end services, matched with industry specific skills and processes, help you make your data simpler to access and understand.</p>
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
