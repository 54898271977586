import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import transparency from '../../images/transparency.png';
import ontimedelivery from '../../images/ontimedelivery.png';
import freesupport from '../../images/freesupport.png';
import flexible from '../../images/flexible.png';
import hours from '../../images/hours.png';

const Commitment = () => {

	return (
	    <div className="Commitment_area clearfix padding">
			<Container fluid>
				<Row>
					<Col md="12" className="text-center">
						<h2 className="wow fadeInUp">Our Promise & <span>GUARANTEE</span></h2>
						<p className="wow fadeInUp">We offer only the best of our services. Our team is top-notch, expert and efficient in developing as desired.</p>
					</Col>
				</Row>
				<Row className="guarantee-row">
					<Col className="grnt-cols">
						<div className="icon">
							<img src={transparency}  alt="icon" />
						</div>
						<h5>Transparent Process</h5>
						<p>Our strategy is 100% transparent and open to discussion with our clients. </p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={ontimedelivery}  alt="icon" />
						</div>
						<h5>On-Time Delivery</h5>
						<p>We are a team of our words. Our deadlines are serious and we stick to them.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={freesupport}  alt="icon" />
						</div>
						<h5>Customer Support</h5>
						<p>We offer customer support till 30 days after the product is delivered.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={flexible}  alt="icon" />
						</div>
						<h5>Flexible Engagements</h5>
						<p>Just let us know if you need any tailor-made models or designs. We do it all. </p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={hours}  alt="icon" />
						</div>
						<h5>24/7 Support</h5>
						<p>We have a 24/7 available customer support team globally. We are easy to reach out to. </p>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Commitment;
