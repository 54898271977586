import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Iothings/Hero";
import DevelopServices from "../components/Iothings/DevelopServices";
import Technology from "../components/Iothings/Technology";
import Process from "../components/Iothings/Process";
import IndustryBussinessjs from "../components/Iothings/IndustryBussinessjs";
import Commitment from "../components/Iothings/Commitment";
import Customers from "../components/Iothings/Customers";
import Success from "../components/Iothings/Success";
import Whatsnew from "../components/Iothings/Whatsnew";
import PageTitle from "../components/Iothings/PageTitle";


import '../assets/Webdevelopment.css';



const Iothings = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Whatsnew />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  	</div>
	)
}

export default Iothings;
