import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const Whatsnew = () => {

	return (
	    <div className="WhatsNew">
			<Container fluid>
				<Row>
					<Col lg="4" md="6" className="whatsNew-col blockchainCol">
						<h3 className="wow fadeInUp">ACCELERATE <br/>REALIZATION</h3>
						<div className="border-line"></div>
						<p className="wow fadeInUp">digiPanda provides excellent support for each step of blockchain adoption and development i.e. from innovative design and assessment which evaluates blockchain solutions from various angles, rapid prototyping, creation of proof-of-concepts and development process to fully implementation of highly reliable blockchain solutions. Our experience in building blockchain platform and solutions helps you to accelerate the realization and implementation of your blockchain network.</p>
					</Col>
					<Col lg="4" md="6" className="whatsNew-col blockchainCol">
						<h3 className="wow fadeInUp">ACHIEVE <br/>INTEROPERABILITY</h3>
						<div className="border-line"></div>
						<p className="wow fadeInUp">digiPanda has extensive experience in developing blockchain solutions for various industries and domains having strong integration abilities and can help you to face the challenges of process and technology interoperability on latest blockchain networks and also takes care of the important things like effective security and privacy of shared data, consensus management, standardization of data transfers and re-alignment of various business processes across network partners.</p>
					</Col>
					<Col lg="4" md="6" className="whatsNew-col blockchainCol">
						<h3 className="wow fadeInUp">ENABLE TRUSTED <br/>ECOSYSTEMS</h3>
						<div className="border-line"></div>
						<p className="wow fadeInUp">digiPanda empowers you to harness full potential of blockchain technology in developing a network where data is highly accessible and reliable, and transactions among partners on that network are fully traceable and verifiable thus enabling a highly trusted ecosystem for your customers, suppliers and partners. digiPandaâ€™s extensive experience promotes ecosystem connections leading to the expansion of the strength of your network.</p>
					</Col>
				</Row>
			</Container>
			
	    </div>
	);
};

export default Whatsnew;
