import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import appdevelopment from '../../images/appdevelopment.png';
import app from '../../images/app.png';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner Webdevelopment">
			    <div className="Banner_Content">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="7" className="banner-left-img cms-img">
								<div className="left-img app-dev">
									<img src={appdevelopment} className="wow fadeInUp" width="100%" alt="Developemnt" />
								</div>
							</Col>
							<Col lg="5" className="banner-right-content">
								<div className="heart-img">
									<img src={app} className="wow zoomIn" alt="Developemnt" />
								</div>
								<h2 className="wow fadeInUp">REACT <span>NATIVE</span> <br /> DEVELOPMENT</h2>
								<p>digiPanda offers robust and scalable React Native application development services across various platforms and industry verticals. We provide complete end-to-end React Native app development services for mission-critical React Native applications demanding superior performance.</p>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
