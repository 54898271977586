import React from "react";
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import ethereum from '../../images/ethereum.png';
import hashgraph from '../../images/hashgraph.png';
import ripple from '../../images/ripple.png';
import hyperledger from '../../images/hyperledger.png';
import crda from '../../images/crda.png';

const Technology = () => {

	return (
	    <div className="transform-row">
	    	<div className="transform-sec">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">WE USE SMART <span>TECHNOLOGIES</span> TO BUILD SMARTER APPLICATIONS</h2>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec">
				<Container fluid>
					<Row>
						<Col md="12" className="">
							<div className="brands technology_brands wow fadeInUp">
								<ListGroup>
								  <ListGroup.Item><img src={ethereum} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={hashgraph} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={ripple} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={hyperledger} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={crda} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								</ListGroup>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default Technology;
