import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';
import q5 from '../../images/q5.png';
import q3 from '../../images/q3.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda IS A TOP REACT NATIVE APP DEVELOPMENT COMPANY WITH OVER <span>50,000</span> MAN YEARS OF EXPERIENCE.</h2>
						<p className="wow fadeInUp">Being rooted in mobile app development services for 15+ years and offering world-class mobile apps, digiPanda Technologies is globally recognized as a top React Native app development company in India, by leading brands and innovative startups. Since React Native is the perfect approach for building intuitive and engaging applications for both android and iOS platforms and maximizes the experience of users, therefore, our dedicated and highly experienced React Native app development team strives to create business-empowered cross-platform applications with delightful UX and high performance value. When you outsource custom React Native application development services to digiPanda Technologies, you are one step closer to making a successful and impactful robust, scalable and flexible product for your business. As one of the top React Native app development companies, we integrate extremely refined approaches and industry proven methodologies that empower us to launch highly innovative solutions globally.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">FULL CYCLE APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Our Android strategy consultants helps in forming a successful strategy for the growth of your online mobile business.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CUSTOM DEVELOPMENT SOLUTIONS</h3>
									<p className="wow fadeInUp">Design and user experience can make or break your Android app. Our Android app developers ensure excellent designs and UI experience.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">REACT NATIVE MVP DEVELOPMENT</h3>
									<p className="wow fadeInUp">We have 100+ expert Android app developers working on Android Studio with an average industry experience of 5+ years.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">APP MAINTENANCE AND SUPPORT</h3>
									<p className="wow fadeInUp">We have 25+ android app testers to provide you high performance and 100% reliable Android app testing services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q5} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MIGRATION & UPGRADATION</h3>
									<p className="wow fadeInUp">We have a strong team of Android app developers working on enterprise android apps for the fortune 1000 clients.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">DEDICATED DEVELOPERS</h3>
									<p className="wow fadeInUp">We have 50+ android game developers to provide you high performance and 100% reliable android gaming services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
