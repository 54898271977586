import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Privacy/Hero";
import TermContent from "../components/Privacy/TermContent";
import PageTitle from "../components/Privacy/PageTitle";


import '../assets/Webdevelopment.css';



const Privacy = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<TermContent />
	  </div>
	)
}

export default Privacy;
