import React from "react";
import { Row, Col, Container } from 'react-bootstrap';




const IndustriesContent = () => {
	return(
		<div className="industries-content">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="inner-box">
							<Row>
								<Col lg="12" md="12" className="left-sec border-right-0">
									<h3 className="border-bottom pb-4 mb-4 mt-0 wow fadeInUp">PRIVACY POLICY</h3>
									<p className="wow fadeInUp">This privacy policy outlines what data digiPanda Technologies Ltd. (herein referred to as "digiPanda", "digiPanda Technologies", "We", "Our", or "Us") collects, stores, and process about you. It also explains your rights to your data and how you can contact us if you have any questions. We reserve the right to alter this privacy statement at our discretion and encourage you to check this section from time to time to be aware of any changes.</p>
									<h3 className="wow fadeInUp"> TO WHOM THIS POLICY APPLIES </h3>
									<p className="wow fadeInUp">This privacy statement applies to individuals ("users" or "you") who interact with our website, subscribe to any of our products, request information, or use the chat support (herein collectively referred to as "services").</p>
									<h3 className="wow fadeInUp"> HOW WE COLLECT DATA </h3>
									<p className="wow fadeInUp">Depending on services you use, we collect data from one or more of these three sources:</p>
									<h3 className="wow fadeInUp"> 1. DATA WE REQUEST: </h3>
									<p className="wow fadeInUp">When you request any of our services, we may ask, store, and process any/all of these data categories:</p>
									<ul className="listings wow fadeInUp">
										<li>Business Intelligence and Data Warehousing Solutions</li>
										<li>Dealer Management Systems (DMS)</li>
										<li>E-Commerce, CRM and ERP Solutions</li>
										<li>Enterprise Mobility and Transformation</li>
										<li>Product Life Cycle Management (PLCM)</li>
										<li>Rapid Prototyping and Product Testing Support</li>
										<li>Supply Chain Management (SCM)</li>
										<li>Web Applications / Portals</li>
										<li>Mobile Applications / Mobility Solutions</li>
									</ul>
									<h3 className="wow fadeInUp">2. COOKIES:</h3>
									<p className="wow fadeInUp">Cookies are small files placed on your computer when you first visit our website that helps analyze web traffic and tracks you across the web. The collection of this data is based on â€œimplied consentâ€, i.e we assume you agree to our terms of usage unless you explicitly opt out. You can opt out by changing your browser settings to not accept cookies, browsing in anonymous mode, or by not using our website. We also have on our website links to many third-party services and use third-party tools, the privacy policy of which may be different from us and we assume no responsibility for their compliance. The third-party tools we use cookies to collect data are:</p>
									<p className="wow fadeInUp"><span>Google Analytics</span> - It collects data related to the device/browser, IP address and on-site activities to measure and report statistics about your interactions on the web. It is used to enhance user experience and we do not access your personally identifiable information. To understand how Google Analytics handles your data, read their privacy policy.</p>
									<p className="wow fadeInUp"><span>Intercom</span> - We use Intercom to provide customer support on our website. While they donâ€™t have access to private data you share with our representatives, they may collect additional data that is enforced by their privacy policy.</p>
									<p className="wow fadeInUp">Lucky Orange - We use Lucky Orange to anonymously track usage of our website including your clicks, cursor hoovers, scroll depth, and more. We may also record some sessions to anonymously to track your behavior across our platform to help improve the user experience. Read Lucky Orange's privacy policy.</p>
									<h3 className="wow fadeInUp">3. EXTERNAL TOOLS:</h3>
									<p className="wow fadeInUp">We also use some tools to collect data about your usage and behavior beyond our website that include:</p>
									<p className="wow fadeInUp"><span>MailChimp</span> - Promotional emails we send are tracked to know much traffic they bring on our website. We may also track if an individual email is opened by any recipient to calculate click rates and improve engagement. We use MailChimp as our email marketing tool and they are governed by their own privacy policy.</p>
									<h3 className="wow fadeInUp">WHY WE COLLECT THESE DATA</h3>
									<p className="wow fadeInUp">The data we collect from these cookies can be personally identifiable or anonymous. We collect this data to:</p>
									<ul className="listings wow fadeInUp">
										<li>Business Intelligence and Data Warehousing Solutions</li>
										<li>Dealer Management Systems (DMS)</li>
										<li>E-Commerce, CRM and ERP Solutions</li>
										<li>Enterprise Mobility and Transformation</li>
										<li>Product Life Cycle Management (PLCM)</li>
										<li>Rapid Prototyping and Product Testing Support</li>
										<li>Supply Chain Management (SCM)</li>
										<li>Web Applications / Portals</li>
										<li>Mobile Applications / Mobility Solutions</li>
									</ul>
									<h3 className="wow fadeInUp"> HOW WE USE YOUR PERSONAL DATA </h3>
									<ul className="listings wow fadeInUp">
										<li>Business Intelligence and Data Warehousing Solutions</li>
										<li>Dealer Management Systems (DMS)</li>
										<li>E-Commerce, CRM and ERP Solutions</li>
										<li>Enterprise Mobility and Transformation</li>
										<li>Product Life Cycle Management (PLCM)</li>
										<li>Rapid Prototyping and Product Testing Support</li>
										<li>Supply Chain Management (SCM)</li>
										<li>Web Applications / Portals</li>
										<li>Mobile Applications / Mobility Solutions</li>
									</ul>
									<h3 className="wow fadeInUp"> WHAT YOU CAN DO </h3>
									<p className="wow fadeInUp">You have full control over what information we store and its subsequent usage. You can choose to:</p>
									<ul className="listings wow fadeInUp">
										<li>Unsubscribe from the mailing list and notification preferences to stop receiving such communication.</li>
										<li>You can choose to not share your information by not filling up the form and not using the chat support.</li>
										<li>You can at anytime change your consent earlier provided for any specific use of your data.</li>
										<li>If you have any question regarding our privacy policy or data handling practices, we would be glad to hear from you at info@digipanda.in</li>
									</ul>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
	    	</Container>
	  	</div>
	)
}

export default IndustriesContent;

