import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import { Link } from "react-router-dom";

const IndustriesContent = () => {
  return (
    <div className="industries-content">
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="inner-box">
              <Row>
                <Col lg="8" md="12" className="left-sec">
                  <h3 className="mt-0 wow fadeInUp">Background</h3>
                  <p className="wow fadeInUp">
                    TISVA, a brand by Usha International Limited, offers
                    aesthetic luminaires that go beyond ordinary lighting
                    concepts for contemporary homes. Combining beauty with
                    cutting-edge technology, TISVA showcases inspirational
                    lighting with customisable hues, colours, and brightness to
                    suit different moods.
                  </p>
                  <h3 className="wow fadeInUp">Challenges and Goals🔴</h3>
                  <p className="wow fadeInUp">
                    <b>
                      Challenges that were faced by TISVA in a competitive
                      market:
                    </b>
                  </p>
                  <ul className="listings wow fadeInUp">
                    <li>Weak online presence </li>
                    <li>Poor user experience on the website</li>
                    <li>Low Traffic on the website</li>
                    <li>Poor brand awareness</li>
                  </ul>
                  <h3 className="wow fadeInUp">Strategies Implemented:</h3>
                  <p className="wow fadeInUp">
                    <b>Visual Transformation: </b>
                  </p>
                  <ul className="listings wow fadeInUp">
                    <li>
                      Updated the brand's social media feed with a black-themed
                      colour palette to enhance the visual appeal and convey a
                      premium feel.
                    </li>
                    <li>
                      The new colour scheme aimed to glorify TISVA's products
                      and create an aesthetic and cohesive visual identity.
                    </li>
                  </ul>
                  <h3 className="wow fadeInUp">
                    Integrated Communication Strategy:{" "}
                  </h3>
                  <ul className="listings wow fadeInUp">
                    <li>
                      Ensured that copy, captions, and messaging highlighted the
                      same core message, focusing on product craftsmanship,
                      features, and unique selling points.
                    </li>
                    <li>
                      Aligned all communication efforts towards promoting
                      TISVA's lighting solutions and their superior quality.
                    </li>
                    <li>
                      Created consistency in the imagery to reinforce the brand
                      promise{" "}
                    </li>
                  </ul>
                  <h3 className="wow fadeInUp">8k organic followers gained!</h3>
                  <p className="wow fadeInUp">
                    <b>Content Calendar and Regular Posting:</b>
                  </p>
                  <ul className="listings wow fadeInUp">
                    <li>
                      Created a content calendar to ensure regular & consistent
                      posting on social media platforms.
                    </li>
                    <li>
                      Leveraged festivals as an opportunity for targeted
                      campaigns, starting two months in advance to build
                      excitement and engagement.
                    </li>
                    <li>
                      Designed festive offers to incentivize customers and
                      encourage conversions.
                    </li>
                    <li>
                      Utilized YouTube as a platform to showcase TISVA's
                      messaging strategy through captivating videos, resulting
                      in millions of views and increased brand exposure.
                    </li>
                  </ul>
                  <h3 className="wow fadeInUp">
                    Digital Promotion for TISVA Studio and Store Launches:
                  </h3>

                  <ul className="listings wow fadeInUp">
                    <li>
                      Implemented instant plans digitally to create awareness
                      and generate buzz around TISVA Studio openings and store
                      launches.
                    </li>
                    <li>
                      Utilized contest plans to encourage audience
                      participation, drive engagement, and amplify brand
                      visibility.
                    </li>
                  </ul>
                  <h3 className="wow fadeInUp">
                    Showcasing Prestigious Projects:
                  </h3>

                  <ul className="listings wow fadeInUp">
                    <li>
                      Highlighted TISVA's involvement in prestigious projects
                      such as lighting installations in the historical Vidhan
                      Sabha and Goa Airport.
                    </li>
                    <li>
                      Leveraged these projects as case studies to exemplify
                      TISVA's expertise and capability in providing top-notch
                      lighting solutions.
                    </li>
                    <li>
                      Utilized social media, blogs, and other digital platforms
                      to showcase these projects, enhancing brand credibility
                      and attracting potential customers.
                    </li>
                  </ul>
                  <h3>Results Achieved🎯</h3>
                  <p>
                    Through our efforts, we were able to achieve significant
                    results for TISVA. Their brand identity and messaging were
                    significantly improved, resulting in increased brand
                    recognition and awareness.
                  </p>
                  <ul className="listings wow fadeInUp">
                    <li>
                      <b>Improved Brand Identity:</b> Tisva's brand identity and
                      messaging were significantly improved, resulting in
                      increased brand recognition and awareness.
                    </li>
                    <li>
                      <b>Website Traffic and Conversions:</b> Website traffic
                      increased by 68%, and the conversion rate increased by
                      45%, resulting in a significant increase in online sales
                      and revenue.
                    </li>
                    <li>
                      <b>Improved Social Media Engagement:</b> Tisva's social
                      media engagement increased by 78%, resulting in increased
                      brand awareness and customer engagement.
                    </li>
                    <li>
                      <b>Enhanced User Experience:</b> The website's user
                      experience was improved, increasing customer satisfaction
                      and a decrease in bounce rate.
                    </li>
                    <li>
                      <b>Increased Local Search Visibility:</b> Tisva's local
                      search visibility increased by 32%, resulting in an
                      increase in foot traffic to its offline stores.
                    </li>
                  </ul>
                </Col>
                <Col lg="4" md="12" className="right-sec">
                  <div className="blue-box wow fadeInUp">
                    <h4 className="title-wht"> Request Information </h4>
                    <p className="text-white">
                      To start a dialogue with our consultant, please complete
                      our contact form and we shall get in touch with you
                      shortly.{" "}
                    </p>
                    <Link to="/contact-us" className="same-btn wow fadeInUp">
                      Get Free Estimation
                    </Link>
                  </div>
                  <h3 className="btm-title wow fadeInUp"> TECHNOLOGIES </h3>
                  <p>
                    WordPress,HTML5,CSS,Bootstrap 5, Jquery, Javascript, Animate
                    CSS
                  </p>
                  <h3 className="btm-title wow fadeInUp"> CLIENT REVIEWS </h3>
                  <p>
                    digiPanda has delivered high quality of mobile apps and
                    website and shown a high degree of flexibility, scalability
                    and service orientation to enable us meet the strategic
                    goals for our highly important IT initiatives. Their sheer
                    commitment, execution excellence, technical expertise and
                    great vision helped us in our overall program and made a
                    tremendous difference to the project execution. Reliability,
                    Quality and a professional attitude are what we expect from
                    a development partner, and digiPanda delivers on all of
                    these fronts. That is why we rely on them to manage and
                    develop some of our critical product functionalities.
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IndustriesContent;
