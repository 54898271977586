import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const Success = () => {

	return (
	    <div className="SuccessArea textFormat">
			<Container fluid>
				<Row>
					<Col lg="10 offset-lg-1">
						<h2 className="wow fadeInUp">Free <span>Product</span> Demo</h2>
						<p className="wow fadeInUp">Interested in seeing our smart website in action? Sign up for a free product demo today and experience the future of website development for yourself. Our team of experts will walk you through the features and benefits of our smart websites and answer any questions you may have.</p>
						<p className="wow fadeInUp mt-3">Don't wait any longer to take your ecommerce business to the next level. Contact us today to learn more about our smart website development services and how we can help you succeed online.</p>
						<a href="https://calendly.com/digipanda-in/30min" target="_blank" className="same-btn wow fadeInUp"> Book a demo </a>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Success;
