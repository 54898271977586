import React from "react";
import { Container } from 'react-bootstrap';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner blog-back">
			    <div className="Banner_Content w-100">
					<Container fluid className="text-center">
						<h2 className="pagehead-title wow fadeInUp"><span>INSIGHTS</span> FOR DIGITAL TRANSFORMATION</h2>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
