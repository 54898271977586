import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/QaTesting/Hero";
import DevelopServices from "../components/QaTesting/DevelopServices";
import Commitment from "../components/QaTesting/Commitment";
import Customers from "../components/QaTesting/Customers";
import Success from "../components/QaTesting/Success";
import AutomationTesting from "../components/QaTesting/AutomationTesting";
import PageTitle from "../components/QaTesting/PageTitle";


import '../assets/Webdevelopment.css';



const QaTesting = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<AutomationTesting />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  </div>
	)
}

export default QaTesting;
