import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Careers/Hero";
import DevelopServices from "../components/Careers/DevelopServices";
import Profiles from "../components/Careers/Profiles";
import Benifits from "../components/Careers/Benifits";
import PageTitle from "../components/Careers/PageTitle";

import '../assets/Webdevelopment.css';



const Careers = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Profiles />
	    	<Benifits />
	  </div>
	)
}

export default Careers;
