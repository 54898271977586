import React from "react";
import { Container } from 'react-bootstrap';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner Consultingback">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="wow fadeInUp">IT CONSULTING</h2>
						<div className="red-border"> </div>
						<p>With our IT consulting services, we have companies develop and implement the right IT strategies and solutions that helps them achieve business-IT alignment. </p>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
