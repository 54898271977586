import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import reviews from '../../images/reviews.png';

import { Link } from 'react-router-dom';


const Getintouch = () => {
	return(
		<div className="contact-section">
	    	<Container fluid>
				<Row>
					<Col md="12">
						<div className="contact-form">
							<Row>
								<Col lg="8">
									<div className="top-contact">
										<h2 className="h2 sm wow fadeInUp"> RESUME APPLICATION FORM </h2>
										<p className="small-heading"> Please fill the job application form below and one of representative shall get in touch with you soon.</p>
									</div>
									<div className="contactform">
										<Form>
											<Row>
												<Col md="6">
													<Form.Group controlId="exampleForm.ControlInput1">
													    <Form.Control type="text" placeholder="Name" />
													</Form.Group>
												</Col>
												<Col md="6">
													<Form.Group controlId="exampleForm.ControlInput1">
													    <Form.Control type="email" placeholder="Email" />
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="6">
													<Form.Group controlId="exampleForm.ControlInput1">
													    <Form.Control type="text" placeholder="Phone Number" />
													</Form.Group>
												</Col>
												<Col md="6">
													<Form.Group controlId="exampleForm.SelectCustomSizeLg">
													    <Form.Control as="select" custom>
													      <option>Select Expercience</option>
													      <option>1-2 Years</option>
													      <option>2-4 Years</option>
													      <option>4-6 Years</option>
													      <option>6-10 Years</option>
													      <option>> 10 Years</option>
													    </Form.Control>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<Form.Group controlId="exampleForm.ControlTextarea1">
													    <Form.Control as="textarea" rows={5} placeholder="Message" />
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<Button className="same-btn" variant="primary" type="submit">
													    Submit Application
													</Button>
												</Col>
											</Row>
										</Form>
									</div>	
								</Col>
								<Col lg="4">
									<div className="right-form-address">
										<img src={reviews} className="p-5" alt="Benifits" />
										<div className="OurAddress reviews border-bottom-0">
											<h2 className="h3 mb-4">EMPLOYEE REVIEWS</h2>
											<Link to="#" target="_blank"> Join us on LinkedIn </Link>
											<Link to="#" target="_blank"> Follow our company events and recent activities on Facebook </Link>
											<Link to="#" target="_blank"> Follow our company on Twitter </Link>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
	  	</div>
	)
}

export default Getintouch;
