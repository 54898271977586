import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/BdAnalytics/Hero";
import DevelopServices from "../components/BdAnalytics/DevelopServices";
import Technology from "../components/BdAnalytics/Technology";
import Process from "../components/BdAnalytics/Process";
import IndustryBussinessjs from "../components/BdAnalytics/IndustryBussinessjs";
import Commitment from "../components/BdAnalytics/Commitment";
import Customers from "../components/BdAnalytics/Customers";
import Success from "../components/BdAnalytics/Success";
import Whatsnew from "../components/BdAnalytics/Whatsnew";
import PageTitle from "../components/BdAnalytics/PageTitle";


import '../assets/Webdevelopment.css';



const BdAnalytics = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Whatsnew />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  	</div>
	)
}

export default BdAnalytics;
