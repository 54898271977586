import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const Achivements = () => {

	return (
	    <div className="PartnerdigiPanda clearfix">
			<Container fluid>
				<Row>
					<Col lg="10 offset-1">
						<div className="wow fadeInUp">
							<h3>Partner With <span>digiPanda</span></h3>
							<p>Make the initial move toward improving the usability and profitability of your website. Join up with digiPanda, and we'll show you how and where to make your online presence ADA compliant so you may qualify for the ADA tax credit from the IRS.</p>
							<p>The ADA experts at DigiPanda are aware that whenever your website has achieved compliance, maintenance is necessary to keep it there. You can be confident that your specialized ADA compliance consultant will execute adjustments and make damn sure your website is constantly in compliance with ADA rules at no additional cost. Unlike certain web development and designing companies, we include continuous technical assistance as an element of our plan at no additional cost to you. This indicates that your ADA consultant is constantly reachable by either email or telephone. We're committed to the long haul!</p>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Achivements;
