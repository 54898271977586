import React from "react";
import { Row, Col, Container } from 'react-bootstrap';

import {Link} from "react-router-dom";



const IndustriesContent = () => {
	return(
		<div className="industries-content">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="inner-box">
							<Row>
								<Col lg="8" md="12" className="left-sec">
									<h3 className="mt-0 wow fadeInUp">ROLE/POSITIONING</h3>
									<p className="wow fadeInUp">The Usha Silai app is dedicated to empowering people by teaching sewing skills even in remote areas. It aims to boost the efficiency of the Usha Silai School. It provides men and women with online content like vernacular sewing videos, e-books, access to the latest information regarding fashion and sewing. </p>
									<p className="wow fadeInUp">Throughthe UshaSilai app, more work is generated too. Like connecting local designers, engaging women in selling Usha products.</p>
									<h3 className="wow fadeInUp">TARGET MARKET </h3>
									<p className="wow fadeInUp">The Usha Silaiapp is a fun way of learning sewing. The app is full of videos, pdf lessons and projects. The app aims to target the underprivileged rural population. Its objective is to have all sewing related training, necessary notes in one place. One can also access the Usha team through the app for suggestions or more information.</p>
									<h3 className="wow fadeInUp">BRAND & BRIEF</h3>
									<p className="wow fadeInUp">The Usha Silai app is to revolutionize the way sewing teaching and learning works. Through the app grassroots level, the rural population is being supplied with education and skills. The mobile app is developing the villages, communities by providing necessary information and providing opportunities to build skills. It aims to boost the efficiency of Usha Silai Schools and increase income through better access to information and services.</p>
									<h3 className="wow fadeInUp">THE CHALLENGE</h3>
									<p className="wow fadeInUp">Even though Usha is a popular brand it was facing a tough time when we consider the Usha Silaiapp. As the app was made with the mindset of training the rural population without being dependent on commuting, fees or individual teachers it still wasn’t serving the purpose. Th population it is supposed to serve could be educated, uneducated or even illiterate. But it wasn’t contributing much. There was abundant information lacking the right picturisation of the content making it difficult to understand. The app was built but it wasn’t really helping the end-users, mostly the rural population.</p>
									<h3 className="wow fadeInUp">SOLUTION/APPROACH</h3>
									<p className="wow fadeInUp">An application can help people enter your world and know your ideas with a closer look. When we worked to improve the Usha Silai app there were various factors, we looked into but one of the most important aspects was user experience. It’s not all about designing but also creating a simple and useful experience for the users. Since mobile usage is more than desktop usage it's an obvious reason to scrutinize the User Experience (UX) of any mobile application.</p>
									<ul className="listings wow fadeInUp">
										<li>We worked on the user experience by using the native UI element to make the app simple for the user. </li>
										<li>Our team ensured to put a simple app design as a minimalistic approach result in higher usage of the mobile app.</li>
										<li>We focused on the content being visually educating rather than being dependent on any language. </li>
										<li>The next essential feature we worked on isan app design and improved search feature. We added expected tabs that people might type otherwise type and search.</li>
										<li>Our team ensured that there is consistency in the UI design. Consistency builds trust in the users.</li>
										<li>Navigation features make the app more friendly to the users hence we intensively worked on the search bars.</li>
									</ul>
									<h3 className="wow fadeInUp">OUR WORK PROCESS</h3>
									<p className="wow fadeInUp">We at DigiPanda are the go-getters who believe in providing a complete solution with the best possible approach and project analysis.</p>
									<p className="wow fadeInUp"><strong>Step 1:</strong> Initially, our team worked on understanding the products, services and target audience.</p>
									<p className="wow fadeInUp"><strong>Step 2:</strong> Then we created an interactive and engaging application that was both Android & iOS compatible.</p>
									<p className="wow fadeInUp"><strong>Step 3:</strong> Then, Campaign Creation was done where we chose the campaign objective as per the target audience and accordingly, the campaign started which was as per a set ad format and creative were made to drive users to download the app.</p>
									<h3 className="wow fadeInUp">STRATEGY TO SUCCEED</h3>
									<p className="wow fadeInUp">Our focus was on the app interface and easy usability. We used features like</p>
									<ul className="listings wow fadeInUp">
										<li>Custom Branding</li>
										<li>Ai Scanner, Add anything and everything</li>
										<li>Automated updating, No limit on information,</li>
										<li>Share with anyone and anywhere</li>
										<li>Contactless sharing</li>
										<li>Click to navigate</li>
										<li>Automated updating</li>
										<li>Build traffic</li>
										<li>90-sec brand video upload</li>
									</ul>
									<p className="wow fadeInUp">We also ensured that the data privacy function was secured and protected.Our main objective was to develop an error-free application with smooth navigation to help increase the app download and usage rate.</p>
								</Col>
								<Col lg="4" md="12" className="right-sec">
									<div className="blue-box wow fadeInUp">
										<h4 className="title-wht"> Request Information </h4>
										<p className="text-white">To start a dialogue with our consultant, please complete our contact form and we shall get in touch with you shortly. </p>
										<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
									</div>
									<h3 className="btm-title wow fadeInUp"> TECHNOLOGIES </h3>
									<p><span>Technology Stack:</span> IOS: objective C Android: Java</p>
									<p><span>Website:</span> HTML5/CSS3<span>Technology Stack:</span> IOS: objective C Android: Java</p>
									<p>The Android mobile app was developed in Java while iPhone application was developed using native iPhone SDK and Objective C. </p>
									<h3 className="btm-title wow fadeInUp"> CLIENT REVIEWS </h3>
									<p>During our interaction with the digiPanda team we found that it is an extremely professional company with an expert team of designers and developers. It has demonstrated a high degree of flexibility and scalability to enable us meet the strategic goals. The team showed high level of commitment and completed the project in a very smooth manner meeting all the requirements and in the timeframe provided. We will definitely contact them for our other projects in future.</p>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
	    	</Container>
	  	</div>
	)
}

export default IndustriesContent;

