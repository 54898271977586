import React from "react";
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import b1 from '../../images/b1.png';
import b2 from '../../images/b2.png';
import b3 from '../../images/b3.png';
import b4 from '../../images/b4.png';
import b5 from '../../images/b5.png';
import b6 from '../../images/b6.png';
import b7 from '../../images/b7.png';
import b8 from '../../images/b8.png';
import {Link} from "react-router-dom";

const Businees = () => {

	return (
	    <div className="transform-row mt-5">
	    	<div className="transform-sec">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">digiPanda' CHARTER IS TO MAKE IT THINK, TALK AND <span>PERFORM</span> LIKE A BUSINESS.</h2>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec">
				<Container fluid>
					<Row>
						<Col md="12" className="">
							<div className="brands wow fadeInUp">
								<ListGroup>
								  <ListGroup.Item><img src={b1} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								  <ListGroup.Item><img src={b2} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								  <ListGroup.Item><img src={b3} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								  <ListGroup.Item><img src={b4} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								  <ListGroup.Item><img src={b5} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								  <ListGroup.Item><img src={b6} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								  <ListGroup.Item><img src={b7} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								  <ListGroup.Item><img src={b8} className="App-logo" alt="logo" width="161" /></ListGroup.Item>
								</ListGroup>
								<Link to="#" className="same-btn wow fadeInUp"> Explore Our Work </Link>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default Businees;
