import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import dbc from "../../images/dbc.png";

import { Link } from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero1 extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="HereoBanner Webdevelopment digipanda-portfolio1 pb-0">
        <div className="Banner_Content section">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg="5" className="left-text-sec">
                <h2 className="text-uppercase wow fadeInUp text-white">DBC</h2>
                <p className="text-white wow fadeInUp">
                  DBC is a digital platform that allows you to design your own
                  unique digital business cards. Our solution eliminates your
                  need to fumble for cards when in need of services and help you
                  store a lot more information than just a paper business card.
                  We make your business cards smarter. These cards can be sent
                  across to anyone with or without the app. The gallery feature
                  allows you to embed pictures and videos and add links to
                  creatives as well.{" "}
                </p>
                <Link
                  target="_blank"
                  to={{ pathname: "https://sailaxdbc.com/" }}
                  className="red-btn wow fadeInUp"
                >
                  {" "}
                  Demo Available on Request &nbsp; <FaTelegramPlane />{" "}
                </Link>
              </Col>
              <Col lg="7" className="banner-right-img cms-img">
                <div className="right-img-sec">
                  <img src={dbc} alt="Developemnt" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero1;
