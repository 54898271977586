import React from "react";
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import tech1 from '../../images/tech1.png';
import tech2 from '../../images/tech2.png';
import tech3 from '../../images/tech3.png';
import tech4 from '../../images/tech4.png';
import tech5 from '../../images/tech5.png';

const Technology = () => {

	return (
	    <div className="transform-row">
	    	<div className="transform-sec">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">WE USE SMART <span>TECHNOLOGIES</span> TO BUILD SMARTER APPLICATIONS</h2>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec">
				<Container fluid>
					<Row>
						<Col md="12" className="">
							<div className="brands technology_brands wow fadeInUp">
								<ListGroup>
								  <ListGroup.Item><img src={tech1} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={tech2} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={tech3} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={tech4} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={tech5} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								</ListGroup>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default Technology;
