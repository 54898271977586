import React from "react";
import { Container } from 'react-bootstrap';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner TestiBack">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">TESTIMONIALS</h2>
						<div className="red-border"> </div>
						<p>We talk about providing value to our customers, but we offer quality products to them. You can check it out from our testimonials related to software development, mobile app development, web development, and IT consulting services. </p>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
