import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const HomeServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">RIGHT DECISIONS LEAD <span>BUSINESS</span> TO THE RIGHT PATH, BUT…</h2>
						<p className="wow fadeInUp">Scale your growth by 10X with digiPanda with the right marketing strategies and expert team. Choosing the right digital marketing partner is half the work done. The growth of e-commerce with digital marketing is booming with the evolution of digital movement globally. With the mushrooming internet subscribers and the digital generation being the fasted growing market, digital capabilities can’t be compromised when you aim to grow your dream projects. The digital market has never been so omnipresent. We at digiPanda make your brand image relevant even when nearly every sector of the economy is radically transforming. We ensure to add significant economic value to your projects as the nature of the marketplace changes.</p>
						<Link to="/portfolio" className="same-btn wow fadeInUp"> Explore Our Work </Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">WEB DEVELOPMENT</h3>
									<p className="wow fadeInUp">We offer web solutions that transform your e-commerce with the help of maximized use of information technology.  From form a blueprint to execution we create a web product that infuses life into your imagination with our expert team of developers and designers.</p>
									<Link to="/web-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MOBILE APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Do you need a mobile app for your business to roll better? We custom develop mobile apps for your e-commerce. Our team specializes in creating custom mobile apps for android and iOS platforms for business. Just drop us your idea and we will be on it.</p>
									<Link to="/mobile-app-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">SOFTWARE DEVELOPMENT</h3>
									<p className="wow fadeInUp">We build bespoke software solutions only at digiPanda. Accelerate your digital growth with the best software in less time. Delegate smartly and get your hands on the latest tools, skilled resources and technologies to deliver better custom software development solutions.</p>
									<Link to="/software-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">IT Outsourcing</h3>
									<p className="wow fadeInUp">Not every company has a flexible workforce, so how will they get their work done? The answer is simple "Outsourcing," with the help of our IT outsourcing services; you can get access to the right expert advice for your business that helps you grow your business and increase your cost savings.</p>
									<Link to="/outsourcing" className="href_link"></Link>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default HomeServices;
