import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import mobiledev from '../../images/mobiledev.png';
import iosdev from '../../images/iosdev.png';
import androiddev from '../../images/androiddev.png';
import crossplatformdev from '../../images/crossplatformdev.png';
import ecommercedev from '../../images/ecommercedev.png';
import magentodev from '../../images/magentodev.png';
import phpdev from '../../images/phpdev.png';
import webdev from '../../images/webdev.png';

import {Link} from "react-router-dom";



const ConsultingServices = () => {

	return (
	    <div className="consul_hire benifits">
			<Container fluid>
				<Row>
					<Col lg="10 offset-1" className="text-center">
						<h2 className="wow fadeInUp">HIRE DEDICATED <span>DEVELOPERS</span></h2>
						<p className="small-para wow fadeInUp">Fulfill your customized requirements as per your needs with the help of our dedicated developers.</p>
					</Col>
				</Row>
				<Row>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={mobiledev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire Mobile App Developers </h3>
							<p className="exp wow fadeInUp">Our team helps you develop easy-to-use and scalable mobile applications that meet your requirements within your budget. </p>
							<Link to="/mobile-app-development" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={iosdev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire iOS App Developers </h3>
							<p className="exp wow fadeInUp"> Want to get iOS apps that enhance your business? If yes, then at DigiPanda, we always develop professional products with targeted requirements. </p>
							<Link to="/ios-app-development" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={androiddev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire Android App Developers </h3>
							<p className="exp wow fadeInUp"> Our team helps you develop easy-to-use and scalable android applications that meet your requirements within your budget. </p>
							<Link to="/android-app-development" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={crossplatformdev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire Cross Platform App Developers </h3>
							<p className="exp wow fadeInUp"> Get high-quality cross platform applications with our expert team handling Xamarin, PhoneGap, React Native and other frameworks.</p>
							<Link to="/cross-platform-app-development" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={ecommercedev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire Ecommerce Developers </h3>
							<p className="exp wow fadeInUp"> Generate sales through your ecommerce startups or large businesses with the help of our expert team.</p>
							<Link to="/ecommerce-solution" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={magentodev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire Wordpress Developers </h3>
							<p className="exp wow fadeInUp">Get a wordpress website developed for your small to large enterprise with customized elements and generate amazing results. </p>
							<Link to="/wrodpress-development" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={phpdev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire PHP Developers </h3>
							<p className="exp wow fadeInUp"> Product online products that enhance the reach of your business from all over the globe using our PHP development services. </p>
							<Link to="/php-development" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={webdev} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Hire Web Developers </h3>
							<p className="exp wow fadeInUp"> Get website design, website application development, and portal development services that fulfill your needs and drive results for your business. </p>
							<Link to="/web-development" className="href_link"></Link>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default ConsultingServices;
