import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import SEOoptimization from '../../images/SEOoptimization.png';
import CONTENT_CREATION from '../../images/CONTENT_CREATION.png';
import customer_service from '../../images/customer_service.png';
import postequirements from '../../images/postequirements.png';
import competition_i from '../../images/competition_i.png';
import Tracking from '../../images/Tracking.png';




const DevelopServices = () => {

	return (
	     <div className="HomeServices padding pb-0">
			<Container fluid>
				<Row>
					<Col lg="10 offset-lg-1">
						<div className="text-center bigContent">
							<p className="wow fadeInUp">Are you tired of traditional websites that require constant updates and maintenance? Our smart website development services are the solution you've been looking for. Our smart websites are capable of self-SEO, have an AI chatbot, and can even generate content for itself.</p>
							<p className="wow fadeInUp">Our cutting-edge technology is designed to help your ecommerce business grow and succeed online. With our smart websites, you'll be able to focus on running your business while our website takes care of the rest.</p>
						</div>
					</Col>
				</Row>
			</Container>
			<div className="ServicesBackBg mt-4">
				<Container fluid>
					<Row>
						<Col lg="10 offset-lg-1">
							<div className="text-center mb-5 pb-4">
								<h2 className="wow fadeInUp titleEcom_">Common <span>ecommerce</span> website challenges</h2>
							</div>
						</Col>
						<Col lg="12">
							<Row>
								<Col md="4" className="DServiceBg">
									<div className="Service_Col text-center">
										<span className="wow fadeInUp"><img src={SEOoptimization} className="icon" alt="icon" width="64" /></span>
										<h3 className="wow fadeInUp">SEO Optimization</h3>
										<p className="wow fadeInUp">Many ecommerce business owners struggle with optimizing their website for search engines.</p>
										
									</div>
								</Col>
								<Col md="4" className="DServiceBg">
									<div className="Service_Col text-center">
										<span className="wow fadeInUp"><img src={CONTENT_CREATION} className="icon" alt="icon" width="64" /></span>
										<h3 className="wow fadeInUp">Content Creation</h3>
										<p className="wow fadeInUp">Creating high-quality content is time-consuming and often requires specialized skills.</p>
										
									</div>
								</Col>
								<Col md="4" className="DServiceBg">
									<div className="Service_Col text-center">
										<span className="wow fadeInUp"><img src={customer_service} className="icon" alt="icon" width="64" /></span>
										<h3 className="wow fadeInUp">Customer Service</h3>
										<p className="wow fadeInUp">Providing excellent customer service is crucial for ecommerce businesses, but can be challenging to manage with a limited team</p>
										
									</div>
								</Col>
								<Col md="4" className="DServiceBg">
									<div className="Service_Col text-center">
										<span className="wow fadeInUp"><img src={postequirements} className="icon" alt="icon" width="64" /></span>
										<h3 className="wow fadeInUp">User Experience</h3>
										<p className="wow fadeInUp">Providing a seamless and user-friendly experience is essential for ecommerce businesses to convert visitors into customers.</p>
										
									</div>
								</Col>
								<Col md="4" className="DServiceBg">
									<div className="Service_Col text-center">
										<span className="wow fadeInUp"><img src={competition_i} className="icon" alt="icon" width="64" /></span>
										<h3 className="wow fadeInUp">Competitiveness</h3>
										<p className="wow fadeInUp">With so many ecommerce businesses in the market, it can be challenging to stand out and remain competitive.</p>
										
									</div>
								</Col>
								<Col md="4" className="DServiceBg">
									<div className="Service_Col text-center">
										<span className="wow fadeInUp"><img src={Tracking} className="icon" alt="icon" width="64" /></span>
										<h3 className="wow fadeInUp">Tracking</h3>
										<p className="wow fadeInUp">Monitoring and improving best revenue driving traffic sources</p>
										
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default DevelopServices;
