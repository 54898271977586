import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/SEO/Hero";
import OtServices from "../components/SEO/OtServices";
import PageTitle from "../components/SEO/PageTitle";


import '../assets/Webdevelopment.css';



const SEO = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<OtServices />
	  </div>
	)
}

export default SEO;
