import React from "react";
import { Container } from 'react-bootstrap';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner contact">
			    <div className="Banner_Content">
					<Container fluid>
						<h1 className="wow fadeInUp">CONTACT</h1>
						<div className="red-border"> </div>
						<p>Get in touch with us to know how you can build or take your business to the next level with our digital support. </p>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
