import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Blockchain/Hero";
import DevelopServices from "../components/Blockchain/DevelopServices";
import Technology from "../components/Blockchain/Technology";
import Process from "../components/Blockchain/Process";
import IndustryBussinessjs from "../components/Blockchain/IndustryBussinessjs";
import Commitment from "../components/Blockchain/Commitment";
import Customers from "../components/Blockchain/Customers";
import Success from "../components/Blockchain/Success";
import Whatsnew from "../components/Blockchain/Whatsnew";
import PageTitle from "../components/Blockchain/PageTitle";


import '../assets/Webdevelopment.css';



const Blockchain = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Whatsnew />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	  	</div>
	)
}

export default Blockchain;
