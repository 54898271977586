import React from "react";
import { Container } from 'react-bootstrap';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner automotive-back">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">TRAVEL & TOURISM</h2>
						<div className="red-border"> </div>
						<p>Build better relationships with our tailor-made travel and tourism industry solutions that significantly improves time-to-market.</p>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
