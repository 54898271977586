import React from "react";
import { Row, Col, Container } from 'react-bootstrap';


const IndustriesContent = () => {
	return(
		<div className="industries-content">
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="inner-box">
							<Row>
								<Col lg="8" md="12" className="left-sec">
									<h3 className="mt-0 wow fadeInUp">Role/Positioning</h3>
									<p className="wow fadeInUp">A Digital Business Card app is for everyone who wants to grow digitally. DBC is an interactive app that helps users generates online networking opportunities while reducing overhead costs while helping them grow their professional life. Anyone from anywhere can create multiple digital business cards with different information on each one. So, you can have a card for your work contacts, one for clients or customers, and one for your friends. Unlike other business card apps, with DBC you can share your card with anyone, even without the app.</p>
									<h3 className="wow fadeInUp">Target Market</h3>
									<p className="wow fadeInUp">DBC is for everyone. The app is integral for all sorts of organizational priorities – recruiting and various types of sourcing, from partnerships and industry experts to client acquisition and expansion.</p>
									<p><a rel="noreferrer" href="https://digitalbusinesscards.com.au" target="_blank">https://digitalbusinesscards.com.au</a></p>
									<h3 className="wow fadeInUp">Brand & Brief</h3>
									<p className="wow fadeInUp">Incepted in 2003, Sailax Group is a forward-looking company that provides sustainable solutions for businesses. We have four brands, Sailax Solar, Sailax LED, Sailax AI, and Sailax DBC. With a keen focus on bettering life with technology, Sailax DBC got added to the Sailax Group of companies in 2019. Each of these brands deliver the best in quality products and services. DBC builds a sustainable global village by providing affordable, and alternative solutions to paper visiting cards.</p>
									<h3 className="wow fadeInUp">The Challenge</h3>
									<p className="wow fadeInUp">‘Do you know, over 90% of business cards get thrown away within one week of receiving them?’</p>
									<p className="wow fadeInUp">With the pandemic coming our way the increase usage of technology has evolved with no room for anyone to skip this global submit. But, when we started developing this app, we faced a few challenges, and we had to encourage professionals to use digital business cards for easy sharing, anytime, anywhere. We tried convincing professionals and businesses not to go for the paper cards route to cut costs.</p>
									<p className="wow fadeInUp">Sharing a business card is all about the personal touch. We don't intend to eliminate it, but enhance it and increase the efficiency because professionals tend to forget or run out of their business cards at the time of sharing. But with Sailax DBC, your business card is always with you on mobile which can be shared at any given moment. </p>
									<p className="wow fadeInUp">Sailax Digital Business Cards can be created on both iOS and Android. Digital cards are way more affordable than their counterpart- paper cards. Unlike visiting cards, digital business cards can be designed, customized, and shared with anyone, anywhere with no space constraints.    </p>
									<h3 className="wow fadeInUp">Solution/Approach</h3>
									<p className="wow fadeInUp">Sailax DBC by itself is an evolved concept. Sailax DBC is a result of our understanding of the struggle of a professional to connect and our vision of a sustainable environment.</p>
									<p className="wow fadeInUp">Our team designed a purple and white colour-based app to maintain the brand aesthetic feel along with a user-friendly interface that helps users design their unique business cards. It facilitates individuals to store information without a limit and in any format at their fingertips.</p>
									<p className="wow fadeInUp">To promote the app, we used social media platforms to reach out to the right audience at the right time and at the right place for an improved lead, volume & quality. We planned to keep the leads warm & engaged via social media campaigns to bring them down the funnel further and make them download the app. Our teams’ hard work paid off and we’re successful in driving traffic with over 1.5 Lakh downloads and still counting.</p>
									<p className="wow fadeInUp">The team also worked on registrants with follow-up content while retargeting those who engage, but don’t register. We started with personalized sponsored In-Mails, webinars, and addressing specific issues for businesses and it worked!</p>
									<h3 className="wow fadeInUp">Our Work Process</h3>
									<p className="wow fadeInUp">We at DigiPanda are the go-getters who believe in providing a complete solution with the best possible approach and project analysis.</p>
									<p className="wow fadeInUp">Initially, our team worked on understanding the products, services and target audience. Then we created an interactive and engaging application that was both Android & iOS compatible. Then, Campaign Creation was done where we chose the campaign objective as per the target audience and accordingly, the campaign started which was as per a set ad format and creative were made to drive users to download the app.</p>
									<h3 className="wow fadeInUp">Strategy to Succeed</h3>
									<p className="wow fadeInUp">Our focus was on the app interface and easy usability. We used features like Custom Branding, Ai Scanner, Add anything and everything, Automated updating, No limit on information, Share with anyone and anywhere, Contactless sharing, Click to navigate, Automated updating, Build traffic, and 90-sec brand video upload. We also ensured that the data privacy function was secured and protected.  </p>
									<p className="wow fadeInUp">Our main objective was to develop an error-free application with smooth navigation to help increase the app download and usage rate.</p>
								</Col>
								<Col lg="4" md="12" className="right-sec">
									<div className="blue-box wow fadeInUp">
										<h4 className="title-wht"> Request Information </h4>
										<p className="text-white">To start a dialogue with our consultant, please complete our contact form and we shall get in touch with you shortly. </p>
										<a href="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</a>
									</div>
									<h3 className="btm-title wow fadeInUp"> TECHNOLOGIES </h3>
									<p>WordPress CMS, JQuery, Yoast plugin for SEO, Nginx Server</p>
									<h3 className="btm-title wow fadeInUp"> CLIENT REVIEWS </h3>
									<p>DBC is a digital platform that allows you to design your own unique digital business cards. Our solution eliminates your need to fumble for cards when in need of services and help you store a lot more information than just a paper business card. We make your business cards smarter. These cards can be sent across to anyone with or without the app. The gallery feature allows you to embed pictures and videos and add links to creatives as well. It simply is your ONE – STOP SOLUTION.</p>
									<p><span>Ajay Sharma (Managing Director)</span></p>
									<p><a rel="noreferrer" href="https://digitalbusinesscards.com.au" target="_blank">https://digitalbusinesscards.com.au</a></p>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
	    	</Container>
	  	</div>
	)
}

export default IndustriesContent;

