import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Melrose from "../../images/Melrose.png";

import { Link } from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero3 extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="HereoBanner Webdevelopment digipanda-portfolio3 pb-0">
        <div className="Banner_Content section">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg="5" className="left-text-sec">
                <h2 className="text-uppercase wow fadeInUp text-white">
                  {" "}
                  Melrose
                </h2>
                <p className="text-white wow fadeInUp">
                  {" "}
                  Melrose is an investment venture that wishes to build an
                  empire and make things happen with a progressive mindset. The
                  objective is to invest in equity, brands and business with a
                  long-term objective. The target niche is AI & enterprise
                  modification, cutting edge financial technology, calculative
                  life sciences, entertainment, commerce and analogous
                  well-being. Melrose capital being an investment brand believes
                  in future-forward businesses.
                </p>

                <Link
                  target="_blank"
                  to={{ pathname: "https://melrosecapital.ae" }}
                  className="red-btn wow fadeInUp"
                >
                  {" "}
                  Launch Live Website &nbsp; <FaTelegramPlane />{" "}
                </Link>
              </Col>
              <Col lg="7" className="banner-right-img cms-img">
                <div className="right-img-sec">
                  <img src={Melrose} alt="Developemnt" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero3;
