import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const OtServices = () => {

	return (
	    <div className="consul_hire benifits">
			<Container fluid>
				<Row>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> <span>Meiying Liang.</span> Phantom Pen</h3>
							<p className="exp wow fadeInUp"> Digipanda has done a lot of web design and development work for us over the past six years. The design team  is very patient they are willing to work with clients by building relationships and understanding their needs. The founder has a high level of emotional intelligence as he listens to customers, understands their point of view and is willing to make changes until the customer is satisfied. His technical skills including design and development skills are at par with the best. </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"><span> Candy Singh.</span> Magic Mushroom</h3>
							<p className="exp wow fadeInUp"> Superb , These guys are just superb when it comes to creating and executing new ideas. Digipanda consulting helped my company in designing and executing some very difficult ideas. The bunch is very friendly and always on their toes. Highly recommended. </p>
						</div>
					</Col>
					<Col lg="4" sm="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> <span>Vikram.</span> Wild World India </h3>
							<p className="exp wow fadeInUp">I tried 5 digital agencies in a span of 4 years for my business  and found most them  just trying to sell me more services without actually showing results. I met the founder of Digipanda consulting via reference and I was confident after the meeting that these guys can deliver what I had in my mind. Honest human and dedicated team. They can think in the lines what you want. Go for them if you want to see your idea getting executed with no hassle .</p>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default OtServices;
