import React from "react";
import { Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner web">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">WEBSITE DEVELOPMENT</h2>
						<div className="red-border"> </div>
						<p>Did you know it takes only 0.5 seconds to hook your visitor’s attention? Team digiPanda is bringing your imagination to life. You dream your website we will create it for you. </p>
						<Link to="/contact-us" className="red-btn"> GET FREE ESTIMATION </Link>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
