import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import automotive from '../../images/automotive.png';
import realestate from '../../images/realestate.png';
import media from '../../images/media.png';
import ecommerce from '../../images/ecommerce.png';
import healthcare from '../../images/healthcare.png';
import transportation from '../../images/transportation.png';
import manufacturing from '../../images/manufacturing.png';
import travel from '../../images/travel.png';
import banking from '../../images/banking.png';
import elearning from '../../images/elearning.png';

import {Link} from "react-router-dom";

const ConsultingServices = () => {

	return (
	    <div className="consul_hire benifits">
			<Container fluid>
				<Row>
					<Col lg="10 offset-1" className="text-center">
						<h2 className="wow fadeInUp">OUR DEVELOPMENT <span> INDUSTRIES</span></h2>
						<p className="small-para wow fadeInUp">Our development technologies are focused and customized 100% as per your project requirements. We offer world-class development skills with a strong inhouse team of 300+ dedicated web, mobile app and software developers.</p>
					</Col>
				</Row>
				<Row>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={automotive} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">  Automotive  </h3>
							<p className="exp wow fadeInUp">  To improvise supply chain collaboration and diversify product offerings, digiPanda develops end-to-end automotive business solutions focusing on dealer management, CRM and ERP solutions. </p>
							<Link to="/automotive" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={realestate} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">  Real Estate  </h3>
							<p className="exp wow fadeInUp">Showcase your properties and connect with prospect buyers in a better manner. digiPanda develops portals, mobile applications and full-cycle CRM solutions that give your real estate business a competitive edge. </p>
							<Link to="/real-estate" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={media} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Entertainment </h3>
							<p className="exp wow fadeInUp"> Mapping the changing needs, digiPanda renders cutting edge solutions including content platforms, gaming applications and media aggregators that are fully functional across the entire entertainment industry. </p>
							<Link to="/entertainment" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={ecommerce} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">Retail & Ecommerce </h3>
							<p className="exp wow fadeInUp"> digiPanda renders optimized retail solutions that are in sync with your brand message. We are capable of delivering end-to-end business solutions including mobile or iPad apps and full-cycle CRM systems. </p>
							<Link to="/retail" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={healthcare} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Healthcare  </h3>
							<p className="exp wow fadeInUp"> Adhering to Government regulations and keeping check on soaring patient care costs, digiPanda develops healthcare solutions that accustom healthcare industry with better management and control abilities. </p>
							<Link to="/healthcare" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={transportation} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Transportation </h3>
							<p className="exp wow fadeInUp"> Cut-throat competition prevails across logistic marketplaces. Our solutions enable you to sustain the stiff competition, access and forecast information and abridge operational costs to increase efficacy. </p>
							<Link to="/logistics" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={manufacturing} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Manufacturing  </h3>
							<p className="exp wow fadeInUp"> Improvised and elevated capacity management is need of hour. Our Manufacturing solutions integrate ERP and inventory management systems with web applications for enhanced data and legacy migration. </p>
							<Link to="/manufacturing" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={travel} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Travel & Tourism </h3>
							<p className="exp wow fadeInUp"> digiPandas' Travel and Tourism industry practice leverages domain expertise to provide innovative application frameworks and IT solutions that improves time-to-market and increases your return on investment. </p>
							<Link to="/travel" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={banking} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp">  Banking & Finance </h3>
							<p className="exp wow fadeInUp"> Finance industry needs a streamlined distribution model. IT solutions rendered by digiPanda focuses on monetization of data assets, development of mobile wallets and robust financial applications.</p>
							<Link to="/finance" className="href_link"></Link>
						</div>
					</Col>
					<Col lg="3" sm="6" className="consul_col">
						<div className="consultant-content">
							<span className="wow fadeInUp"><img src={elearning} className="icon" alt="icon" /></span>
							<h3 className="consul_title wow fadeInUp"> Education  </h3>
							<p className="exp wow fadeInUp">Innovative virtual learning solutions from digiPanda are targeted towards taking classroom learning a notch higher. Integrating solutions with industry standards enables digiPanda to deliver world-class solutions. </p>
							<Link to="/education" className="href_link"></Link>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default ConsultingServices;
