import React from "react";
import { Row, Col, Container } from 'react-bootstrap';




const ConsultingServices = () => {

	return (
	    <div className="consul_hire benifits bg-white ps-0 pe-0 pb-0">
			<Container fluid>
				<Row>
					<Col lg="12" className="text-center">
						<h2 className="wow fadeInUp">Enhance Your Brand By Our Performance-Based <span>SEO</span> Techniques</h2>
						<p className="small-para wow fadeInUp mb-4 pt-4">You need to have recognized internet exposure and web search rankings to flourish in today's digitalized global environment. The rating of any website on search results can be significantly improved by search engine optimization, which also helps you reach your intended target audience. </p>
						<p className="small-para wow fadeInUp">DigiPanda Consulting provides result-driven SEO services to assist businesses in gaining a sustainable foothold in their sector. To help you increase exposure, leads, and conversions, our professionals formulate practical tactics and use the leading industry standards.</p>
					</Col>
				</Row>
			</Container>
			<div className="consul_hire benifits ps-0 pe-0 mt-4">
				<Container fluid>
					<Row>
						<Col lg="8 offset-2">
							<div className="digital-content text-center">
								<h2 className="wow fadeInUp"> What Justifies The Demand For <span>SEO</span> For Your Biz?</h2>
								<p className="exp wow fadeInUp">Regardless of how informative and well-designed your website appears, if it doesn't bring enough appropriate visitors, it won't fully accomplish its goals. With the help of digiPanda's professional web optimization services, you can boost the effectiveness of your company by making your site appear more frequently in organically searched results for the terms that your consumers use.</p>
							</div>
						</Col>
					</Row>
					<Row className="mt-5">
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Increased traffic and conversion rates </h3>
								<p className="exp wow fadeInUp"> Since most individuals only look for goods or services when they require them, users have a higher chance of making a purchase. To ensure that your website ranks amongst the other top search results, our professionals use tried and tested techniques to optimize it for search results. In consequence, this produces traffic and leads to a greater rate of conversion.</p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Improve The Click-Through Rate Of Your Website </h3>
								<p className="exp wow fadeInUp"> For search engine crawlers to efficiently grasp the information on your website and position it higher in search engine results, DigiPanda's team of specialists analyses your website and builds a stable foundation for it. Your click-through rate increases as your website's homepage start to show in the top results since there is a greater chance that site links will be shown to visitors. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Increased Brand Credibility </h3>
								<p className="exp wow fadeInUp"> Years of expertise have been accumulated by our SEO experts in improving the text, layout, graphics, and aesthetics of our clientele' websites. As the general quality of the websites affects how they rank in search engines. Your website's improved quality will raise users' faith in your company, which will open up new options for attracting and keeping customers. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Trust Development </h3>
								<p className="exp wow fadeInUp"> To boost your company's online exposure and targeted traffic, our professionals carry out in-depth research before developing a strategy that is focused on results. You may gain the trust of your clients by providing relevant data on your site that enables website visitors to make well-informed choices. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Performance Evaluation </h3>
								<p className="exp wow fadeInUp"> Your website's various KPIs are monitored by our crew of digital marketing specialists at digiPanda, and the information we acquire from them allows you to learn more about your website's users. The specialists of our digital marketing team can assist you in compiling vital data on your visitors, such as their search preferences, favored languages, devices, demography, bounce rate, and activity levels. You can utilize this data to evaluate your website's effectiveness and make critical decisions. </p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="consul_hire benifits bg-white">
				<Container fluid>
					<Row>
						<Col lg="10 offset-1">
							<div className="digital-content text-center mt-5">
								<h2 className="wow fadeInUp mb-4"> Our <span>SEO</span> Solutions</h2>
								<p className="exp wow fadeInUp">Boost Your Search Engine rankings to Become More Visible Online</p>
							</div>
						</Col>
					</Row>
					<Row className="mt-5">
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Keyword Researches & Tactics </h3>
								<p className="exp wow fadeInUp"> DigiPanda is an SEO business that can raise your rating for particular searched terms and comprehends your niche. To formulate a list that is most beneficial for your business and the competitive dynamics, an SEO specialist will examine the keywords your website presently ranks for, collect your keyword wishlist, and perform more keyword analysis.</p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Link Building </h3>
								<p className="exp wow fadeInUp"> Getting higher in the search results is greatly influenced by backlinks, but not simply any link! For this reason, you ought to collaborate with an SEO firm like digiPanda, which has a devoted link-building staff and interactions with numerous high-quality and related websites. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Technical SEO </h3>
								<p className="exp wow fadeInUp"> The back-end of your website can be optimized to raise rankings, for example, by increasing page load speed. It will be easier and much more efficient for crawlers to crawl your website if you correct such issues. Additionally, your rankings may be improved if you satisfy the search engines. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Local SEO </h3>
								<p className="exp wow fadeInUp"> According to statistics, 64% of consumers look for surrounding businesses online. With Google's Business Profile or Google My Business (GMB) optimization, raise your firm's local SEO ranks and draw in clients with a strong purchasing intention. To increase your localized fanbase, we monitor your reputation online and enhance your GMB status. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> On-Page SEO </h3>
								<p className="exp wow fadeInUp"> Boost your web presence to attract high-quality traffic. To increase the credibility of your website, digiPanda makes sure that our SEO services are in line with search engines' recommendations. Our SEO specialists create original, valuable material, improve your Html elements and headings, and use high-resolution photos. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Off-Page SEO </h3>
								<p className="exp wow fadeInUp"> Off-page optimization is essential for building a brand's reputation and expanding exposure online. To produce quality backlinks and favorable reviews, we make use of social media marketing, link building, and influencer marketing. Team up with our Digital agency, and we'll help you demonstrate your expertise in the field. </p>
							</div>
						</Col>
						<Col lg="4" sm="6" className="consul_col border-end">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp"> Content Creation </h3>
								<p className="exp wow fadeInUp"> Our writing team can create blogs, and content that is worth sharing, or add more content to current products and services pages to make them highly SEO-friendly for your website. Search engines seem to prefer websites that consistently update their content timt0to0time.To assist your content requirements and strengthen your search marketing, pick our SEO company. </p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="PartnerdigiPanda clearfix whyUs">
				<Container fluid>
					<Row>
						<Col lg="8 offset-2">
							<div className="wow fadeInUp">
								<h3>Why <span>Us?</span></h3>
								<p>Our experts at digiPanda Consulting Pvt. Ltd. specialize in providing search engine optimization strategies that are conversion-focused. To comprehend your company's goals and intended audience, we undertake in-depth research and competitor analysis. After that, we develop tailored SEO tactics to improve the search engine results for your company's relevant keywords.</p>
								<h4>Customized SEO Approaches to Boost Organic Traffic for Your Website</h4>
							</div>
						</Col>
					</Row>
				</Container>
		    </div>
	    </div>
	);
};

export default ConsultingServices;
