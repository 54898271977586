import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import webdevelopment from '../../images/webdevelopment.png';
import web from '../../images/web.png';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner Webdevelopment webdev">
			    <div className="Banner_Content">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="7" className="banner-left-img cms-img">
								<div className="left-img">
									<img src={webdevelopment} className="wow fadeInLeft" width="100%" alt="Developemnt" />
								</div>
							</Col>
							<Col lg="5" className="banner-right-content">
								<div className="heart-img">
									<img src={web} className="wow zoomIn" alt="Developemnt" />
								</div>
								<h2 className="wow fadeInUp"><span>WEB</span>SITE <br /> DEVELOPMENT</h2>
								<p>Did you know it takes only 0.5 seconds to hook your visitor’s attention? Team digiPanda is bringing your imagination to life. You dream your website we will create it for you.</p>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
