import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import usafleg from "../../images/usafleg.jpg";
import indiafleg from "../../images/indiafleg.jpg";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer">
      <Container fluid>
        <Row>
          <Col className="menu-listing col contact-col">
            <h4 className="wow fadeInUp">Offices</h4>
            <p className="wow fadeInUp mb-3">
              <p className="mb-1">
                <strong>IND:</strong>
              </p>
              D-111, Floor No. 1, Sector 63, Noida, India
            </p>
            <p className="wow fadeInUp mb-3">
              <p className="mb-1">
                <strong>AUS:</strong>
              </p>
              Unit 9/35-37, Jesica Rd, Campbellfield VIC 3061
            </p>
            <p className="wow fadeInUp mb-3">
              <p className="mb-1">
                <strong>NL:</strong>
              </p>
              Sniep 69, 1112 AJ Diemen, The Netherlands
            </p>
          </Col>
          <Col className="menu-listing col service-col">
            <Link to="/services">
              <h4 className="wow fadeInUp">Services</h4>
            </Link>
            <ul>
              <Link to="/web-development">Web Development</Link>
              <Link to="/mobile-app-development">Mobile App Development</Link>
              <Link to="/software-development">Software Development</Link>
              <Link to="/digital-marketing-service">Digital Marketing</Link>
            </ul>
          </Col>
          <Col className="menu-listing col solution-col">
            <h4 className="wow fadeInUp">Solutions</h4>
            <ul>
              <Link to="/outsourcing">IT Outsourcing</Link>
              <Link to="/technologies">Technologies</Link>
              <Link to="/hire-developers">Hire Developers</Link>
            </ul>
          </Col>
          <Col className="menu-listing col company-col">
            <h4 className="wow fadeInUp">Corporate</h4>
            <ul>
              <Link to="/portfolio">Portfolio</Link>
              <Link to="/case-studies">Case Studies</Link>
              <Link to="/testimonials">Testimonials</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/careers">Careers</Link>
              <Link to="/contact-us">Contact Us</Link>
            </ul>
          </Col>
          <Col className="menu-listing col hvQues">
            <span className="footer-head hide-xs">Connect with an Expert</span>
            <div className="phone-contact">
              Talk to us:
              <a className="mt-4" href="tel:+1(757) 972-7858">
                <img width="25" src={usafleg} alt="flag" /> &nbsp;{" "}
                <span className="number">+1(757) 972-7858</span>
              </a>
              <a className="mt-4" href="tel:+91 8448835766">
                <img width="25" src={indiafleg} alt="flag" /> &nbsp;{" "}
                <span className="number">+91 844-883-5766</span>
              </a>
            </div>
            <div className="rowfull">
              Skype: <a href="skype:digipanda.sales">digipanda.sales</a>
              <p>
                Email:{" "}
                <a href="mailto:info@digipanda.tech">info@digipanda.tech</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
