import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import PageTitle from "../components/Portfolio/PageTitle";
import Hero from "../components/Portfolio/Hero";
import Hero1 from "../components/Portfolio/Hero1";
import Hero2 from "../components/Portfolio/Hero2";
import Hero3 from "../components/Portfolio/Hero3";
import Hero4 from "../components/Portfolio/Hero4";
import Hero5 from "../components/Portfolio/Hero5";
import Hero6 from "../components/Portfolio/Hero6";
import Hero7 from "../components/Portfolio/Hero7";

import "../assets/Webdevelopment.css";

const Portfolio = () => {
  return (
    <div className="App">
      <PageTitle />
      <Hero />
      <Hero1 />
      <Hero2 />
      <Hero3 />
      <Hero4 />
      <Hero5 />
      <Hero6 />
      <Hero7 />
    </div>
  );
};

export default Portfolio;
