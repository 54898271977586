import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import { Link } from "react-router-dom";

const IndustriesContent = () => {
  return (
    <div className="industries-content">
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="inner-box">
              <Row>
                <Col lg="8" md="12" className="left-sec">
                  <h3 className="mt-0 wow fadeInUp">Background</h3>
                  <p className="wow fadeInUp">
                    MirrorWalla, a leading Indian manufacturer of high-quality
                    mirrors, is renowned for its unique and visually striking
                    designs. Despite this reputation, MirrorWalla faced
                    challenges with an outdated e-commerce website and low brand
                    awareness, leading to a decline in website traffic and
                    sales.
                  </p>
                  <h3 className="wow fadeInUp">Project Goals</h3>
                  <p className="wow fadeInUp">
                    <b>
                      MirrorWalla sought digiPanda's expertise to enhance its
                      online presence and drive sales through its e-commerce
                      platform. The key goals included:
                    </b>
                  </p>
                  <ul className="listings wow fadeInUp">
                    <li>
                      <b>Revamped E-commerce Website:</b> Develop a fully
                      optimized e-commerce website to showcase MirrorWalla's
                      products effectively.{" "}
                    </li>
                    <li>
                      <b>Increased Visibility:</b> Enhance brand recognition and
                      visibility across various online platforms.
                    </li>
                    <li>
                      <b>Traffic and Sales Growth:</b> Drive more traffic to the
                      website and increase online sales.
                    </li>
                    <li>
                      <b>Improved User Experience:</b> Create a user-friendly
                      and seamless website experience for customers.
                    </li>
                  </ul>
                  <h3 className="wow fadeInUp">Challenges Faced</h3>
                  <p>
                    The primary challenge was MirrorWalla's outdated e-commerce
                    website. The poor user interface and limited functionality
                    hindered user engagement and sales. Additionally, low brand
                    awareness made it difficult to attract new customers.
                  </p>
                  <h3>digiPanda's Strategies and Solutions</h3>
                  <p className="wow fadeInUp">
                    <b>
                      To address these challenges, digiPanda implemented a
                      comprehensive digital marketing strategy:
                    </b>
                  </p>
                  <ul className="listings wow fadeInUp">
                    <li>
                      <b>Optimized E-commerce Website:</b> We collaborated with
                      MirrorWalla to create a new, user-friendly e-commerce
                      website. This included:
                      <ol>
                        <li>
                          A clean and intuitive interface for easy navigation.
                        </li>
                        <li>
                          High-quality product images and detailed descriptions.
                        </li>
                        <li>
                          A streamlined checkout process to facilitate smooth
                          transactions.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Enhanced Visibility:</b> We implemented search engine
                      optimization (SEO) strategies to ensure the website ranked
                      higher in search engine results pages (SERPs) like Google
                      and Bing. Additionally, we registered the website with
                      Google My Business (GMB) for improved local search
                      visibility.
                    </li>
                    <li>
                      <b>Traffic and Sales Growth:</b> Result-oriented paid
                      advertising campaigns were launched on platforms like
                      Google Ads and Facebook Ads. These campaigns aimed to
                      increase brand awareness, generate leads, and ultimately
                      drive sales.
                    </li>
                    <li>
                      <b>Elevated Customer Experience:</b> Regular website
                      audits were conducted to ensure optimal website
                      performance and a positive user experience. This included
                      optimizing the website for mobile devices and ensuring
                      fast loading times.
                    </li>
                  </ul>
                  <h3 className="wow fadeInUp">Results Achieved</h3>
                  <p>
                    <b>
                      digiPanda's strategic approach yielded significant results
                      for MirrorWalla:
                    </b>
                  </p>
                  <ul className="listings wow fadeInUp">
                    <li>
                      <b>400% Increase in Website Traffic:</b> Within the first
                      three months of launching the new website, website traffic
                      surged by 400%.
                    </li>
                    <li>
                      <b>200% Increase in Online Sales:</b> Online sales
                      witnessed a substantial 200% increase within the first six
                      months.
                    </li>
                    <li>
                      <b>250% Brand Awareness Growth:</b> Brand awareness and
                      recognition across platforms experienced a remarkable 250%
                      growth.
                    </li>
                    <li>
                      <b>50% Increase in Customer Engagement:</b> Customer
                      engagement and satisfaction on the website improved by
                      50%.
                    </li>
                  </ul>
                  <h3 className="wow fadeInUp">Conclusion</h3>
                  <p className="wow fadeInUp">
                    The collaboration between digiPanda and MirrorWalla proved
                    to be a resounding success. MirrorWalla achieved significant
                    growth in online sales, brand recognition, and customer
                    engagement. By leveraging the power of digital marketing and
                    website optimization, digiPanda empowered MirrorWalla to
                    establish a stronger online presence and solidify its
                    position within the mirror industry.
                  </p>
                </Col>
                <Col lg="4" md="12" className="right-sec">
                  <div className="blue-box wow fadeInUp">
                    <h4 className="title-wht"> Request Information </h4>
                    <p className="text-white">
                      To start a dialogue with our consultant, please complete
                      our contact form and we shall get in touch with you
                      shortly.{" "}
                    </p>
                    <Link to="/contact-us" className="same-btn wow fadeInUp">
                      Get Free Estimation
                    </Link>
                  </div>
                  <h3 className="btm-title wow fadeInUp"> TECHNOLOGIES </h3>
                  <p>
                    WordPress,HTML5,CSS,Bootstrap 5, Jquery, Javascript, Animate
                    CSS
                  </p>
                  <h3 className="btm-title wow fadeInUp"> CLIENT REVIEWS </h3>
                  <p>
                    DigiPanda has been instrumental in propelling our business
                    forward, stated MirrorWalla. "Their expertise in digital
                    marketing and website development has been invaluable in
                    bolstering our online presence and driving sales growth. We
                    highly recommend digiPanda to any business seeking to expand
                    through digital marketing."
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IndustriesContent;
