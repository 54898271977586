import React from "react";
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import t1 from '../../images/t1.png';
import t2 from '../../images/t2.png';
import t3 from '../../images/t3.png';
import t4 from '../../images/t4.png';
import t5 from '../../images/t5.png';

const Technology = () => {

	return (
	    <div className="transform-row">
	    	<div className="transform-sec">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">Take your business to new heights. <br /> <span>Efficient & Effective.</span> </h2>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec">
				<Container fluid>
					<Row>
						<Col md="12" className="">
							<div className="brands technology_brands wow fadeInUp">
								<ListGroup>
								  <ListGroup.Item><img src={t1} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={t2} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={t3} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={t4} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								  <ListGroup.Item><img src={t5} className="App-logo" alt="logo" width="140" /></ListGroup.Item>
								</ListGroup>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default Technology;
