import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import c1 from '../../images/c1.png';
import c2 from '../../images/c2.png';
import c3 from '../../images/c3.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">WE ASSIST STARTUPS, SME'S AND LARGE ENTERPRISES IN MAKING CRITICAL <span>BUSINESS</span> DECISIONS.</h2>
						<p className="wow fadeInUp">digiPanda provides a broad range of web application development and offshore software development services to help you harness the power of technology, consulting and maximize your online business investment. Our services address specific needs of enterprise IT programs, communications and Internet technology product development. Client's benefit from seamless coordination across strategy, implementation, and management of their technology programs and from our expertise in focused industries, strong quality orientation, cross-technology expertise, and distributed project management capabilities.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">WEB DEVELOPMENT</h3>
									<p className="wow fadeInUp">Take advantage of our complete range of cutting-edge and highly reliable custom web development services to empower your business to surge ahead of the competition.</p>
									<Link to="/web-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MOBILE APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">Our mobile application development services helps you address evolving market challenges by defining, designing and building applications tailored to meet your business requirements.</p>
									<Link to="/mobile-app-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={c3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">SOFTWARE DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda' cross-industry, offshore software development services help you craft a vision for your organization and then implement a result-oriented technology framework that will turn that vision into reality.</p>
									<Link to="/software-development" className="href_link"></Link>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
