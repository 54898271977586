import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/LikeUbereats/Hero";
import BlogContent from "../components/LikeUbereats/BlogContent";
import PageTitle from "../components/LikeUbereats/PageTitle";

import '../assets/Webdevelopment.css';



const LikeUbereats = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<BlogContent />
	  </div>
	)
}

export default LikeUbereats;
