import React from "react";
import { Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner web">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">ASP.NET DEVELOPMENT</h2>
						<div className="red-border"> </div>
						<p>digiPanda has established itself as one of the top offshore ASP.NET development company in India with tremendous wherewithal in all the technologies associated with the Microsoft platform.</p>
						<Link to="/contact-us" className="red-btn"> GET FREE ESTIMATION </Link>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
