import React from "react";
import {Helmet} from "react-helmet";

class PageTitle extends React.Component {

  	render() {
		return (
			<div className="Page_Title">
	            <Helmet>
			        <title>Hire Offshore Developers, Dedicated Programmers India</title>
			        <meta name="description" content="Digipanda Web Development" />
			    </Helmet>
			</div>
		);
	}
};

export default PageTitle;
