import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Manufacturing/Hero";
import IndustriesContent from "../components/Manufacturing/IndustriesContent";
import PageTitle from "../components/Manufacturing/PageTitle";

import '../assets/Webdevelopment.css';



const Manufacturing = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<IndustriesContent />
	  	</div>
	)
}

export default Manufacturing;
