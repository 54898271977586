import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda PROVIDES INNOVATIVE <span>AI SOLUTIONS</span> TO MAKE OUR CLIENT'S BUSINESS SMART & AGILE</h2>
						<p className="wow fadeInUp">Artificial Intelligence or AI is revolutionizing businesses and it is one of the hottest technology trends which is prevailing all over the world. AI technology replicates human intelligence in a structured manner. This intelligence provides many opportunities for businesses to automate their business operations and processes by leveraging the benefits of AI applications. digiPanda's AI development services help enterprises in building amazing AI software, virtual agents or personal assistants which understand and interpret the human behavior for delivering excellent support and amazing user experience. digiPanda will guide you on the path to your custom AI software, from the business requirements gathering stage to user training and maintenance for the ready AI solution.</p>
						<p className="wow fadeInUp">As a leading AI development company, our extensive experience in developing AI software can become a differentiator for your business to get immense success through the use of various AI technologies. Our expertise includes entire range of AI technologies including NLP implementation, Machine learning development, Speech Recognition, Image Processing, Neural Networks etc. We design such powerful solutions which integrate smoothly with the client's business model and boost their business in a big way.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MACHINE LEARNING</h3>
									<p className="wow fadeInUp">digiPanda builds applications of AI which empower businesses to get ultimate benefits of machine learning which is a technology that allows machines to use data for self-learning. Machine Learning can be used to interpret complex data, trends, patterns to learn human behavior.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CHATBOT DEVELOPMENT</h3>
									<p className="wow fadeInUp">We build elegant and engaging iOS apps that makes your business successful. We have professional iOS app developers who always design and develop easy to use, user friendly and cost-effective iOS applications.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ALEXA APP DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda builds cross platform apps with Xamarin, PhoneGap, React Native and other frameworks. Cross platform app development makes it possible to use reusable codes which saves lots of development time and costs.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">PREDICTIVE ANALYTICS</h3>
									<p className="wow fadeInUp">digiPanda offers expert development of Augmented Reality apps. Right from retail to construction to daily enterprise activities, you can use the potential benefits of AR to make your key operations highly efficient, informed and cost effective for better ROI.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">NLP SERVICES</h3>
									<p className="wow fadeInUp">Create, schedule and manage your data integration at scale with digiPanda's hybrid data integration service. Work with data wherever it lives, in the cloud or on-premises and with enterprise grade security.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">AI CONSULTING</h3>
									<p className="wow fadeInUp">digiPanda's Big Data Strategy consulting services provide strategy, engineering and analytics to help you maximize your data insights. Our end to end services, matched with industry specific skills and processes, help you make your data simpler to access and understand.</p>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
