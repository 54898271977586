import React from "react";
import { Container } from 'react-bootstrap';
import {Link} from "react-router-dom";

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner PrivacyBack">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">PRIVACY POLICY</h2>
						<div className="red-border"> </div>
						<p>Protecting your personal information is a responsibility that we take very seriously. digipanda is committed to transparency and choice regarding protecting the privacy of our clientâ€™ s personal information as well as that of all visitors to our website.</p>
						<Link to="/contact-us" className="same-btn"> Get In Touch With Us </Link>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
