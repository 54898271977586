import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import transparency from '../../images/transparency.png';
import ontimedelivery from '../../images/ontimedelivery.png';
import freesupport from '../../images/freesupport.png';
import flexible from '../../images/flexible.png';
import hours from '../../images/hours.png';



const Commitment = () => {

	return (
	    <div className="Commitment_area clearfix padding">
			<Container fluid>
				<Row>
					<Col md="12" className="text-center">
						<h2 className="wow fadeInUp">OUR COMMITMENT & <span>GUARANTEE</span></h2>
						<p className="wow fadeInUp">DigiPanda delivers high-quality, scalable, and efficient web and mobile app development services that enhance your business reach and complete your targets linked with the products. </p>
					</Col>
				</Row>
				<Row className="guarantee-row">
					<Col className="grnt-cols">
						<div className="icon">
							<img src={transparency}  alt="icon" />
						</div>
						<h5>100% TRANSPARENCY</h5>
						<p>We believe in complete transparency that helps our clients to be fully updated during the development cycle.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={ontimedelivery}  alt="icon" />
						</div>
						<h5>ON-TIME DELIVERY</h5>
						<p>Our team makes sure that all the deliveries carried forward from our end are always on time. </p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={freesupport}  alt="icon" />
						</div>
						<h5>CUSTOMER SUPPORT</h5>
						<p>We offer free 30 days support to all our customers after the product delivery. However, if anyone wants to get in touch with us after that, we also offer 24/7 support.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={flexible}  alt="icon" />
						</div>
						<h5>FLEXIBLE ENGAGEMENTS</h5>
						<p>We create customized engagement models with the help of which you can fulfill all your requirements efficiently.</p>
					</Col>
					<Col className="grnt-cols">
						<div className="icon">
							<img src={hours}  alt="icon" />
						</div>
						<h5>24X7 SUPPORT</h5>
						<p>Our communication channels are open 24/7 that connects you with the appropriate team of experts to ensure smooth execution of your project.</p>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Commitment;
