import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import WOW from "wowjs";

class Hero extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <div className="HereoBanner">
        <div className="Banner_Content">
          <Container fluid>
            <h1 className="wow fadeInUp">
              DEVELOPING RESULT-DRIVEN <span>PRODUCTS.</span>
            </h1>
            <div className="red-border"> </div>
            <p>
              Creating adaptable software and mobile applications that drive
              results with unique ideas.
            </p>
            <Link to="/portfolio" className="red-btn">
              {" "}
              Explore Our Work{" "}
            </Link>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero;
