import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Link} from "react-router-dom";

const CustomerSection = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">WHY CUSTOMERS IN OVER <span>10+</span> COUNTRIES CHOOSE DIGIPANDA?</h2>
						<div className="column-box wow fadeIn">
							<Row>
								<Col md="4" className="sm-cols">
									<p> Flexible engagement models </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> 24x7 Support across all timezones </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Competitive pricing & ontime delivery </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> State of the art it infrastructure </p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Strong technology competency</p>
								</Col>
								<Col md="4" className="sm-cols">
									<p> Seamless communication </p>
								</Col>
							</Row>
						</div>
						<Link to="/contact-us" className="same-btn wow fadeInUp"> Explore Your Next </Link>
					</Col>
					<Col lg="7">
						<div className="testimonial-col">
							<OwlCarousel 
							className="owl-theme testimonial-slider"
							items="1" 
							loop 
							dots
							autoplay
							>
							    <div className='item'>
							        <h4 className="text-uppercase"> <span>Candy Singh </span> . Magic Mushroom </h4>
							        <p> "Superb , These guys are just superb when it comes to creating and executing new ideas. Digipanda consulting helped my company in designing and executing some very difficult ideas. The bunch is very friendly and always on their toes. Highly recommended."</p>
							    </div>
							    <div className='item'>
							        <h4 className="text-uppercase"><span> Vikram</span> . Wild World India</h4>
							        <p> "I tried 5 digital agencies in a span of 4 years for my business  and found most them  just trying to sell me more services without actually showing results. I met the founder of Digipanda consulting via reference and I was confident after the meeting that these guys can deliver what I had in my mind."</p>
							    </div>
							</OwlCarousel>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default CustomerSection;
