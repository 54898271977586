import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import software from '../../images/software.jpg';
import {Link} from "react-router-dom";


const IndustryBussinessjs = () => {

	return (
	    <div className="Industry_Bussness p-0 mt-4">
			<Container fluid>
				<Row>
					<Col md="6">
						<div className="perform_content">
							<h3 className="wow fadeInUp">OUR CHARTER IS TO MAKE IT THINK, TALK AND <span>PERFORM</span> LIKE A BUSINESS.</h3>
							<p className="wow fadeInUp">We work closely with our clients to first identify business objectives, define a solution strategy and develop a detailed roadmap for implementation. Based on the roadmap & architecture developed during the assessment and specification stage we deliver custom-built solutions with comprehensive business logic, efficient performance and technical scalability.</p>
							<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
						</div>
					</Col>
					<Col md="6" className="RightImgindusB">
						<img src={software} className="wow fadeInRight" alt="soft" />
					</Col>
				</Row>
			</Container>
			
	    </div>
	);
};

export default IndustryBussinessjs;
