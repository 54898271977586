import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import tisva1 from "../../images/tisva1.png";

import { Link } from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero6 extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="HereoBanner Webdevelopment digipanda-portfolio6 pb-0">
        <div className="Banner_Content section">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg="7" className="banner-right-img cms-img">
                <div className="right-img-sec">
                  <img src={tisva1} alt="Developemnt" />
                </div>
              </Col>
              <Col lg="5" className="left-text-sec">
                <h2 className="text-uppercase wow fadeInUp text-white">
                  {" "}
                  TISVA{" "}
                </h2>
                <p className="text-white wow fadeInUp">
                  Tisva is a company dedicated to crafting exceptional designer
                  lighting solutions. Their website showcases a stunning
                  collection of decorative and architectural lighting, catering
                  to both residential and commercial spaces.
                  <br />
                  DigiPanda played a key role in creating a user-friendly and
                  visually appealing website that effectively highlights Tisva's
                  commitment to style and functionality. Through the website,
                  customers can:
                  <ul className="m-0 mt-3 mb-4">
                    <li>Explore a World of Lighting</li>
                    <li>Discover Innovative Features</li>
                    <li>Schedule a Free Consultation</li>
                  </ul>
                  The successful Tisva website exemplifies DigiPanda's ability
                  to craft digital experiences that illuminate a brand's value
                  proposition and empower customer engagement.
                </p>
                <Link
                  target="_blank"
                  to={{ pathname: "https://www.lightsbytisva.com/" }}
                  className="red-btn wow fadeInUp"
                >
                  {" "}
                  Demo Available on Request &nbsp; <FaTelegramPlane />{" "}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero6;
