import React from "react";
import { Row, Col, Container } from 'react-bootstrap';


const Process = () => {

	return (
	    <div className="">
		    <div className="process-sec padding pb-0">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">Ensuring Fair Access to Content Pieces Following The <span>ADA</span></h2>
						</Col>
					</Row>
					<div className="process-content">
						<Row>
							<Col md="12" className="">
								<div className="figcaption text-center mt-5">
									<h4 className="wow fadeInUp">DISCUSS PROJECT DETAILS WITH OUR ANALYSTS</h4>
									<p className="wow fadeInUp mt-2">Disability-inclusive planning has been much more well-known over time. The majority of nations have policies in place to safeguard the civil rights of individuals with disabilities, outlawing discrimination and making sure that all structures and businesses are barrier-free. Despite this being a positive move, web accessibility guidelines are not universally followed.</p>
									<p className="wow fadeInUp mt-2">Maintain your website's content ADA compliant by becoming familiar with the principles of the Americans with Disabilities Act, the Internet Content Accessibility Principles, and other open standards.</p>
									<p className="wow fadeInUp mt-2">To address online developments and the advancement of technologies, <b>WCAG 2.1 was released in 2017.</b> Building on earlier WCAG revisions, WCAG 2.1 intends to increase the accessibility of internet content for a wider spectrum of people with impairments, such as:</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<div className="consul_hire benifits mt-5">
				<Container fluid>
					<Row>
						<Col lg="6" className="consul_col">
							<div className="consultant-content">
								<p className="exp wow fadeInUp"> ●	Deafness</p>
								<p className="exp wow fadeInUp"> ●	Blind Disorder</p>
								<p className="exp wow fadeInUp"> ●	Photosensitivity</p>
								<p className="exp wow fadeInUp"> ●	Loss of Hearing</p>
								<p className="exp wow fadeInUp"> ●	Poor Vision</p>
								<p className="exp wow fadeInUp"> ●	Having trouble learning</p>
								<p className="exp wow fadeInUp"> ●	Constraints On The Mind</p>
								<p className="exp wow fadeInUp"> ●	Restricted Motion</p>
								<p className="exp wow fadeInUp"> ●	Speaking Deficiencies</p>
							</div>
						</Col>
						<Col lg="6" className="consul_col">
							<div className="consultant-content">
								<p className="exp wow fadeInUp">Compliance with WCAG or the ADA criteria is not a prerequisite for an ADA website. A broad word used to describe constructing internet sites that become accessible to the users, irrespective of their physical or cognitive function, is "ADA compliance."</p>
							</div>
						</Col>
						<Col lg="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp">ADA Compliance For Websites Is A Must, Not Even A Choice.</h3>
								<p className="exp wow fadeInUp"> All businesses/sites must now comply with the WCAG, which is now mandated in many nations, such as the United Kingdom, Australia, and Canada. To provide up to $5,000 in ADA tax credits for the website's ease of access and urge more small companies and groups to participate in ensuring that everyone has access to internet resources, the Internal Revenue Service (IRS) Code's Section 44 ADA tax credit was enacted. </p>
								<p className="exp wow fadeInUp">Making your website ADA-compatible is still important for other reasons outside the IRS tax incentive. Maintaining ADA website compliance is essential to your company's success. </p>
							</div>
						</Col>
						<Col lg="6" className="consul_col">
							<div className="consultant-content">
								<h3 className="consul_title wow fadeInUp">Make Your Online Experience Complete</h3>
								<p className="exp wow fadeInUp">Your website design, as a marketing and company owner, says a lot about the caliber of your goods and the caliber of your customer service. Building an ADA-compliant website will demonstrate to your customers, potential customers, and important stakeholders your dedication to offering the finest service.</p>
							</div>
						</Col>
					</Row>
				</Container>
		    </div>
	    </div>
	);
};

export default Process;
