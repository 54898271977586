import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heistyle from "../../images/heistyle.png";

import { Link } from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero5 extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="HereoBanner Webdevelopment digipanda-portfolio5 pb-0">
        <div className="Banner_Content section">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg="5" className="left-text-sec">
                <h2 className="text-uppercase wow fadeInUp text-white">
                  {" "}
                  Hie{" "}
                </h2>
                <p className="text-white wow fadeInUp">
                  Hie is a handbag and accessories brand that celebrates the
                  multifaceted woman of today. Their designs empower
                  self-expression and seamlessly transition between different
                  aspects of a woman's life. The DigiPanda team was tasked with
                  creating a Shopify e-commerce store that reflected Hiestyle's
                  core values and provided a platform to showcase its diverse
                  collection. We crafted a user-friendly and visually appealing
                  website that allows customers to:
                  <ul className="m-0 mt-3 mb-4">
                    <li>Explore Hiestyle's Story</li>
                    <li>Navigate with Ease</li>
                    <li>Experience Products in Detail</li>
                  </ul>
                  The success of the Hiestyle e-commerce website highlights
                  DigiPanda's expertise in creating user-centred experiences
                  that empower brands to connect with their target audience.
                </p>
                <Link
                  target="_blank"
                  to={{ pathname: "https://www.hiestyle.com/" }}
                  className="red-btn wow fadeInUp"
                >
                  {" "}
                  Demo Available on Request &nbsp; <FaTelegramPlane />{" "}
                </Link>
              </Col>
              <Col lg="7" className="banner-right-img cms-img">
                <div className="right-img-sec">
                  <img src={heistyle} alt="Developemnt" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero5;
