import React from "react";
import { Row, Col, Container } from 'react-bootstrap';


const Process = () => {

	return (
	    <div className="process-sec padding pb-0">
			<Container fluid>
				<Row>
					<Col md="12">
						<h2 className="wow fadeInUp">OUR DEVELOPMENT <span>PROCESS</span></h2>
						<p className="wow fadeInUp">The reason behind our high-quality services is the development process we follow for every requirement we get. </p>
					</Col>
				</Row>
				<div className="process-content">
					<Row>
						<Col md="12" className="">
							<ul className="hire-team-process">
								<li className="step1">
									<div className="dashed-circle project-requirment"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">POST YOUR PROJECT REQUIREMENTS</h4>
										<p className="wow fadeInUp sub-title-area">Our team will go through the requirements of your project, and based on your deliverables, we will proceed by sending them to the right team. </p>
									</div>
								</li>
								<li className="step2">
									<div className="dashed-circle discuss-project"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">DISCUSS PROJECT DETAILS WITH OUR ANALYSTS</h4>
										<p className="wow fadeInUp sub-title-area">One of our web development experts will contact you to discuss your project more and clear down all the confusion, if any. </p>
									</div>
								</li>
								<li className="step3">
									<div className="dashed-circle terms-n-timelines"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">CHOOSE ENGAGEMENT TERMS & TIMELINES</h4>
										<p className="wow fadeInUp sub-title-area">We will discuss the engagement terms and timelines with you, and based on further discussions and agreements, we will move forward with the project. </p>
									</div>
								</li>
								<li className="step4">
									<div className="dashed-circle get-started"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">SECURELY PAY ONLINE AND GET STARTED!</h4>
										<p className="wow fadeInUp sub-title-area">You can use our secure payment method online and get started with the project within no time. </p>
									</div>
								</li>
							</ul>
						</Col>
					</Row>
				</div>
			</Container>
			
	    </div>
	);
};

export default Process;
