import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import logo from "../../images/logo.svg";
import { Row, Col, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import usafleg from "../../images/usafleg.jpg";
import indiafleg from "../../images/indiafleg.jpg";
import { Link } from "react-router-dom";

class MainHeader extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleMenuOpen() {
    var element = document.getElementById("nav");
    if (element != null) element.style.display = "block";
    element.classList.add("fadeIn");
  }
  handleMenuClose() {
    var element = document.getElementById("nav");
    if (element != null) element.style.display = "none";
    element.classList.remove("fadeIn");
  }

  handleScroll(event) {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      document.getElementById("App-header").classList.add("fixedHeader");
    } else {
      document.getElementById("App-header").classList.remove("fixedHeader");
    }
  }

  render() {
    const classes = classNames("main-navbar", "bg-light", "sticky-top");
    return (
      <header id="App-header" className={classes}>
        <Container fluid>
          <Row className="align-items-center">
            <Col lg="12">
              <Navbar bg="light" expand="lg">
                <Navbar.Brand>
                  <Link to="/">
                    <img
                      src={logo}
                      className="App-logo"
                      alt="logo"
                      width="161"
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Collapse>
                  <Nav
                    className="m-auto"
                    style={{ maxHeight: "100px" }}
                    navbarScroll
                  >
                    <NavDropdown
                      title="Services"
                      id="idofthiselement"
                      onMouseEnter={(e) =>
                        document.getElementById("idofthiselement").click()
                      }
                      onMouseLeave={(e) =>
                        document.getElementById("idofthiselement").click()
                      }
                    >
                      <Link to="/web-development">Web Development</Link>
                      <Link to="/mobile-app-development">
                        Mobile App Development
                      </Link>
                      <Link to="/software-development">
                        Software Development
                      </Link>
                      <Link to="/ada-compliance-services">
                        ADA Compliance Services
                      </Link>
                      <Link to="/digital-marketing-service">
                        Digital Marketing Service
                      </Link>
                    </NavDropdown>
                    <NavDropdown
                      title="Solutions"
                      id="navbarScrollingDropdown4"
                      onMouseEnter={(e) =>
                        document
                          .getElementById("navbarScrollingDropdown4")
                          .click()
                      }
                      onMouseLeave={(e) =>
                        document
                          .getElementById("navbarScrollingDropdown4")
                          .click()
                      }
                    >
                      <Link to="/outsourcing">IT Outsourcing</Link>
                      <Link to="/technologies">Technologies</Link>
                      <Link to="/hire-developers">Hire Developers</Link>
                    </NavDropdown>
                    <NavDropdown
                      title="Corporate"
                      id="navbarScrollingDropdown5"
                      onMouseEnter={(e) =>
                        document
                          .getElementById("navbarScrollingDropdown5")
                          .click()
                      }
                      onMouseLeave={(e) =>
                        document
                          .getElementById("navbarScrollingDropdown5")
                          .click()
                      }
                    >
                      <Link to="/case-studies">Case Studies</Link>
                      <Link to="/testimonials">Testimonials</Link>
                      <Link to="/blog">Blog</Link>
                      <Link to="/careers">Careers</Link>
                    </NavDropdown>
                    <Nav.Item>
                      <Link className="nav-link" to="/portfolio">
                        Portfolio
                      </Link>
                    </Nav.Item>
                    <NavDropdown
                      title="AI Websites"
                      id="navbarScrollingDropdown16"
                      onMouseEnter={(e) =>
                        document
                          .getElementById("navbarScrollingDropdown16")
                          .click()
                      }
                      onMouseLeave={(e) =>
                        document
                          .getElementById("navbarScrollingDropdown16")
                          .click()
                      }
                    >
                      <Link to="/ecommerce-solution">eCommerce</Link>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
                <Nav className="ml-auto">
                  <div className="OurCnt">
                    <a
                      className="Digipanda_Contact"
                      href="tel:+1(757) 972-7858"
                    >
                      <img width="25" src={usafleg} alt="flag" /> &nbsp; +1(757)
                      972-7858{" "}
                    </a>
                    <a
                      className="Digipanda_Contact"
                      href="tel:tel:+91 8448835766"
                    >
                      <img width="25" src={indiafleg} alt="flag" /> &nbsp; +91
                      844-883-5766{" "}
                    </a>
                    <Link
                      className="GetStarted Digipanda_Contact"
                      to="/contact-us"
                    >
                      {" "}
                      Get Free Estimation{" "}
                    </Link>
                    <Link
                      className="font3bold ToggleBTN"
                      onClick={this.handleMenuOpen}
                    >
                      <span className="ion-android-menu">
                        <div className="nav-menu">
                          <span> </span>
                          <span className="right-ar"> </span>
                          <span> </span>
                        </div>
                      </span>
                    </Link>
                  </div>
                </Nav>
              </Navbar>
            </Col>
          </Row>
        </Container>
        <div className="MainNavbarNav" id="nav">
          <Container fluid>
            <Row>
              <Col md="8" className="pTop order2">
                <h1>
                  DEVELOPING RESULT-DRIVEN <span>PRODUCTS.</span>{" "}
                </h1>
                <NavDropdown.Item href="/portfolio" className="red-btn">
                  {" "}
                  Explore Our Work{" "}
                </NavDropdown.Item>
              </Col>
              <Col md="4" className="pTop order1">
                <Nav className="toggle_nav">
                  <NavDropdown
                    title="Services"
                    id="navbarScrollingDropdown6"
                    onMouseEnter={(e) =>
                      document
                        .getElementById("navbarScrollingDropdown6")
                        .click()
                    }
                    onMouseLeave={(e) =>
                      document
                        .getElementById("navbarScrollingDropdown6")
                        .click()
                    }
                  >
                    <NavDropdown.Item href="/web-development">
                      Web Development
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mobile-app-development">
                      Mobile App Development
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/software-development">
                      Software Development
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/ada-compliance-services">
                      ADA Complianc Services
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/digital-marketing-service">
                      Digital Marketing Service
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    title="Solutions"
                    id="navbarScrollingDropdown8"
                    onMouseEnter={(e) =>
                      document
                        .getElementById("navbarScrollingDropdown8")
                        .click()
                    }
                    onMouseLeave={(e) =>
                      document
                        .getElementById("navbarScrollingDropdown8")
                        .click()
                    }
                  >
                    <NavDropdown.Item href="/outsourcing">
                      IT Outsourcing
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/technologies">
                      Technologies
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/hire-developers">
                      Hire Developers
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Corporate"
                    id="navbarScrollingDropdown9"
                    onMouseEnter={(e) =>
                      document
                        .getElementById("navbarScrollingDropdown9")
                        .click()
                    }
                    onMouseLeave={(e) =>
                      document
                        .getElementById("navbarScrollingDropdown9")
                        .click()
                    }
                  >
                    <NavDropdown.Item href="/case-studies">
                      Case Studies
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/testimonials">
                      Testimonials
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/blog">Blog</NavDropdown.Item>
                    <NavDropdown.Item href="/careers">Careers</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Item>
                    <a className="nav-link" href="/portfolio">
                      Portfolio
                    </a>
                  </Nav.Item>
                  <Nav.Item>
                    <a className="nav-link" href="/contact-us">
                      Contact Us
                    </a>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Container>
          <Container fluid className="p-0">
            <Row className="office-sec m-0">
              <Col>
                <div className="title"> OFFICES </div>
              </Col>
              <Col>
                <p>
                  <p className="mb-1">
                    <strong>IND:</strong>
                  </p>{" "}
                  D-111, Floor No. 1, Sector 63, Noida, India{" "}
                </p>
              </Col>
              <Col>
                <p>
                  <p className="mb-1">
                    <strong>AUS:</strong>
                  </p>{" "}
                  Unit 9/35-37, Jesica Rd, Campbellfield VIC 3061
                </p>
              </Col>
              <Col>
                <p>
                  <p className="mb-1">
                    <strong>NL:</strong>
                  </p>{" "}
                  Sniep 69, 1112 AJ Diemen, The Netherlands{" "}
                </p>
              </Col>
              <Col>
                <div className="social-icons">
                  <Link
                    to="https://www.facebook.com/digiPandaConsulting/"
                    className="fb-icon"
                    target="_blank"
                  >
                    <FaFacebookF />
                  </Link>
                  <Link
                    to="https://twitter.com/digipanda_IN"
                    className="tw-icon"
                    target="_blank"
                  >
                    <FaTwitter />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/digipanda-consulting"
                    className="lnkd-icon"
                    target="_blank"
                  >
                    <FaLinkedinIn />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <Link className="CloseNavMenu" onClick={this.handleMenuClose}>
            <span className="right-ar"></span>
          </Link>
        </div>
      </header>
    );
  }
}

MainHeader.propTypes = {
  /**
   * The layout type where the MainHeader is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainHeader.defaultProps = {
  stickyTop: true,
};

export default MainHeader;
