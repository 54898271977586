import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';
import q5 from '../../images/q5.png';
import q3 from '../../images/q3.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda IS ONE OF THE TOP WORDPRESS ECOMMERCE DEVELOPMENT COMPANY WITH OVER <span>50,000+</span> MAN YEARS OF EXPERIENCE.</h2>
						<p className="wow fadeInUp">digiPanda is a ISO 9001 certified offshore Wordpress ecommerce development company, backed by a strong workforce of 300+ experts offering high-performance Wordpress development services to help you surge ahead of the competition. digiPanda offers offshore Wordpress development services in India that empowers your business with exceptional and powerful features by rendering result-oriented solutions for your online business. Our Wordpress development services provides you an edge over your competitors, allowing you to focus on your core business. Being a top offshore Wordpress development company in India, we partner with you in your ecommerce ventures and help you transform your visitors into prospective customers. digiPanda specializes in offering scalable Wordpress solutions mapping from enterprise ecommerce store development to custom development and theme customization that cater to specific client requirements. As a result of our strategic development methodology and processes, we provide Wordpress ecommerce development services with on-time deliverable guarantee that ensures 100% satisfaction.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MODULE DEVELOPMENT</h3>
									<p className="wow fadeInUp">Our Android strategy consultants helps in forming a successful strategy for the growth of your online mobile business.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">THEME DEVELOPMENT</h3>
									<p className="wow fadeInUp">Design and user experience can make or break your Android app. Our Android app developers ensure excellent designs and UI experience.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ECOMMERCE DEVELOPMENT</h3>
									<p className="wow fadeInUp">We have 100+ expert Android app developers working on Android Studio with an average industry experience of 5+ years.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MULTI-STORE DEVELOPMENT</h3>
									<p className="wow fadeInUp">We have 25+ android app testers to provide you high performance and 100% reliable Android app testing services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q5} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">TEMPLATE CUSTOMIZATION</h3>
									<p className="wow fadeInUp">We have a strong team of Android app developers working on enterprise android apps for the fortune 1000 clients.</p>
									<Link to="/software-development" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img width="65" src={q3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">MAINTENANCE & MIGRATION</h3>
									<p className="wow fadeInUp">We have 50+ android game developers to provide you high performance and 100% reliable android gaming services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
