import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import CountUp from 'react-countup';


const TransformSection = () => {

	return (
	    <div className="transform-row">
	    	<div className="transform-sec Customizable_content mt-0 pb-5">
				<Container fluid>
					<Row>
						<Col md="8 offset-lg-2">
							<h2 className="wow fadeInUp">ROI Focused</h2>
							<p className="wow fadeInUp">Our smart websites can be customized to meet your specific needs and preferences, which means you'll have a website that reflects your unique brand.</p>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec customizableTrsm">
				<Container fluid className="p-15">
					<Row>
						<Col md="3" className="bl-col sm-col">
							<CountUp start={0} end={26} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>higher customer engagement</p>
								    </div>
								)}
							</CountUp>
						</Col>
						<Col md="3" className="bl-col sm-col">
							<CountUp start={0} end={35} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>higher sales</p>
								    </div>
								)}
							</CountUp>
						</Col>
						<Col md="3" className="bl-col sm-col">
							<CountUp start={0} end={17} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>increase in conversion</p>
								    </div>
								)}
							</CountUp>
						</Col>
						<Col md="3" className="bl-col sm-col">
							<CountUp start={0} end={500} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>ROI</p>
								    </div>
								)}
							</CountUp>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default TransformSection;
