import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import CountUp from 'react-countup';


const TransformSection = () => {

	return (
	    <div className="transform-row">
	    	<div className="transform-sec">
				<Container fluid>
					<Row>
						<Col md="12">
							<h2 className="wow fadeInUp">EVERY INDUSTRY & <span> BUSINESS </span> SOLUTIONS <br/> AT ONE PLACE</h2>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="certified-sec">
				<Container fluid className="p-15">
					<Row>
						<Col md="2" className="bl-col sm-col">
							<CountUp start={0} end={7} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>YEARS IN BUSINESS</p>
								    </div>
								)}
							</CountUp>
						</Col>
						<Col md="2" className="bl-col sm-col">
							<CountUp start={0} end={100} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>IT PROFESSIONALS</p>
								    </div>
								)}
							</CountUp>
						</Col>
						<Col md="4" className="bl-col sm-col">
							<h4 className="wow fadeInUp">ISO 9001 <span>CERTIFIED</span> NASSCOM & STPI ACCREDITATION</h4>
						</Col>
						<Col md="2" className="bl-col sm-col">
							<CountUp start={0} end={250} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>CLIENTS WORLDWIDE</p>
								    </div>
								)}
							</CountUp>
						</Col>
						<Col md="2" className="bl-col sm-col">
							<CountUp start={0} end={1500} delay={0} duration={9.75}>
								{({ countUpRef }) => (
								    <div className="wow fadeInUp">
								      <span className="counter" ref={countUpRef} />
								      <p>PROJECTS EXECUTED</p>
								    </div>
								)}
							</CountUp>
						</Col>
					</Row>
				</Container>
			</div>
	    </div>
	);
};

export default TransformSection;
