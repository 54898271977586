import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Testimonials/Hero";
import DevelopServices from "../components/Testimonials/DevelopServices";
import OtServices from "../components/Testimonials/OtServices";
import PageTitle from "../components/Testimonials/PageTitle";


import '../assets/Webdevelopment.css';



const Testimonials = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<OtServices />
	  </div>
	)
}

export default Testimonials;
