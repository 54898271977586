import React from "react";
import { Container } from 'react-bootstrap';
import {Link} from "react-router-dom";

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner technoloBack">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">TECHNOLOGIES</h2>
						<div className="red-border"> </div>
						<p>Get customized solutions for all types of information technology and the latest market trends to build a modern image of your business and generate better results. Our experienced team develops focused products and solutions based on your business needs and targeted customers. </p>
						<Link to="/contact-us" className="red-btn">  Get Free Estimation  </Link>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
