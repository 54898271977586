import React from "react";
import { Row, Col, Container } from 'react-bootstrap';


const Process = () => {

	return (
	    <div className="process-sec padding">
			<Container fluid>
				<Row>
					<Col md="12">
						<h2 className="wow fadeInUp">Features</h2>
					</Col>
				</Row>
				<div className="process-content">
					<Row>
						<Col md="12" className="">
							<ul className="hire-team-process">
								<li className="step1">
									<div className="dashed-circle project-requirment"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">Self-SEO</h4>
										<p className="wow fadeInUp sub-title-area">Our smart websites are designed to optimize themselves for search engines, which means your website will rank higher in search results and attract more traffic.</p>
									</div>
								</li>
								<li className="step2">
									<div className="dashed-circle discuss-project"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">AI Chatbot</h4>
										<p className="wow fadeInUp sub-title-area">Our smart websites come equipped with an AI-powered chatbot that can answer customer questions and provide personalized recommendations.</p>
									</div>
								</li>
								<li className="step3">
									<div className="dashed-circle terms-n-timelines"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">Content Generation</h4>
										<p className="wow fadeInUp sub-title-area">Our smart websites can generate high-quality content, including product descriptions, titles, meta tags, and images, which means you'll never have to worry about creating content again.</p>
									</div>
								</li>
								<li className="step4">
									<div className="dashed-circle website-easy-to-use"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">Self Tracking</h4>
										<p className="wow fadeInUp sub-title-area"> Advance tracking to monitor traffic, engagement and conversion matrices on the website to drive actionable insights. </p>
									</div>
								</li>
							</ul>
						</Col>
					</Row>
				</div>
			</Container>
			
	    </div>
	);
};

export default Process;
