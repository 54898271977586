import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import magentodev from '../../images/magentodev.png';
import manufacturing from '../../images/manufacturing.png';
import flexible from '../../images/flexible.png';
import postequirements from '../../images/postequirements.png';
import vendor from '../../images/vendor.png';
import q6 from '../../images/q6.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	     <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda IS ONE OF THE TOP <span>PHP DEVELOPMENT</span> COMPANIES WITH OVER 50,000 MAN YEARS OF EXPERIENCE.</h2>
						<p className="wow fadeInUp">digiPanda is a ISO 9001 certified offshore PHP development company, backed by a strong workforce of 300+ experts providing high-performance, custom PHP web development services of any complexity with incredible competence. Leveraging on the deep domain expertise of our PHP professionals who adopt industry best-practices and methodologies, digiPanda delivers most comprehensive offshore PHP development services in India for different industry verticals. Adopting the latest technology frameworks and proven methodologies, our PHP solutions offer scalability, flexibility and improved collaboration. Being a top offshore PHP development company in India digiPanda, offers PHP based business solutions that deliver maximum ROI and reduces the overall development cost. Hire dedicated PHP developers and programmers from digiPanda on full time or hourly basis to build the most professional, robust and highly scalable PHP solutions with highest quality standards.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={magentodev} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">CUSTOM PHP DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda offers end-to-end website design, web application development and portal development services for your business.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={manufacturing} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">OPEN SOURCE SOLUTIONS</h3>
									<p className="wow fadeInUp">digiPanda build scalable and secure ebusiness and ecommerce solutions for customers across various business verticals.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={flexible} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">ECOMMERCE DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda offers PHP development services that enhances the business demands of our customers across all verticals.</p>
									<Link to="/ecommerce-solution" className="href_link"></Link>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={postequirements} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">CMS DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda provides cost effective and high performance ASP.NET development services for growing businesses and startups.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={vendor} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">LARAVEL DEVELOPMENT</h3>
									<p className="wow fadeInUp">We leverage time and cost saving advantages of open source technologies, frameworks and platforms to deliver scalable web applications.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={q6} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">CAKEPHP DEVELOPMENT</h3>
									<p className="wow fadeInUp">We have 50+ web testers to provide high performance and 100% reliable website testing services.</p>
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
