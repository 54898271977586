import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import logo1 from "../../images/logo1.png";
import logo2 from "../../images/logo2.png";
import logo3 from "../../images/logo3.png";
import logo4 from "../../images/logo4.png";
import tisva from "../../images/tisva.png";
import mirrorwala from "../../images/mirrorwala.png";
import { Link } from "react-router-dom";

const options = {
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

const CaseStudy = () => {
  return (
    <div className="CaseStudy padding">
      <Container fluid>
        <Row>
          <Col md="12">
            <h2 className="wow fadeInUp">
              CASE STUDIES OF HOW OUR CLIENTS <br /> HAVE EXPERIENCED{" "}
              <span>COMPETITIVE</span> ADVANTAGE
            </h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-15">
        <Row>
          <Col lg="12">
            <div className="CaseStudy-col">
              <OwlCarousel
                className="owl-theme CaseStudy-slider"
                items="4"
                loop
                dots
                autoplay
                margin={10}
                {...options}
              >
                <div className="item">
                  <div className="inner-cols banner1">
                    <div className="banner-col-content">
                      <div className="col-logo">
                        <img src={logo1} className="App-logo" alt="logo" />
                      </div>
                      <p className="para">
                        A Digital Business Card app is for everyone who wants to
                        grow digitally. DBC is an interactive app that helps
                        users generates online networking
                      </p>
                      <Link to="/sailax-dbc" className="red-btn circle-btn">
                        {" "}
                        View Case Study{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="inner-cols banner5">
                    <div className="banner-col-content">
                      <div className="col-logo">
                        <img src={tisva} className="App-logo" alt="logo" />
                      </div>
                      <p className="para">
                        TISVA, a brand by Usha International Limited, offers
                        aesthetic luminaires that go beyond ordinary lighting
                        concepts for contemporary homes.
                      </p>
                      <Link to="/tisva" className="red-btn circle-btn">
                        {" "}
                        View Case Study{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="inner-cols banner6">
                    <div className="banner-col-content">
                      <div className="col-logo">
                        <img src={mirrorwala} className="App-logo" alt="logo" />
                      </div>
                      <p className="para">
                        Boosting E-commerce Sales and Visibility with
                        digiPanda's Digital Expertise
                      </p>
                      <Link to="/mirrorwalla" className="red-btn circle-btn">
                        {" "}
                        View Case Study{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="inner-cols banner2">
                    <div className="banner-col-content">
                      <div className="col-logo">
                        <img src={logo2} className="App-logo" alt="logo" />
                      </div>
                      <p className="para">
                        The Usha Silai app is dedicated to empowering people by
                        teaching sewing skills even in remote areas. It aims to
                        eliminate dependence
                      </p>
                      <Link to="/usha" className="red-btn circle-btn">
                        {" "}
                        View Case Study{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="inner-cols banner3">
                    <div className="banner-col-content">
                      <div className="col-logo">
                        <img src={logo3} className="App-logo" alt="logo" />
                      </div>
                      <p className="para">
                        An investment services industry that helps you get new
                        opportunities with smart investment options. Melrose
                        capital preaches an investment
                      </p>
                      <Link to="/melrose" className="red-btn circle-btn">
                        {" "}
                        View Case Study{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="inner-cols banner4">
                    <div className="banner-col-content">
                      <div className="col-logo">
                        <img src={logo4} className="App-logo" alt="logo" />
                      </div>
                      <p className="para">
                        An IT services industry that helps your agency with all
                        its IT needs. No matter what background you belong to
                        like entertainment, legal or any others we got you
                        covered.
                      </p>
                      <Link to="/soho" className="red-btn circle-btn">
                        {" "}
                        View Case Study{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CaseStudy;
