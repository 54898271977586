import React from "react";
import { Container } from 'react-bootstrap';
import {Link} from "react-router-dom";
import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner blockchain-back">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">BLOCKCHAIN</h2>
						<div className="red-border"> </div>
						<p>We assist our clients to explore and develop solutions using blockchain technology that can help them extract real business value by utilizing our technical capabilities and experience to implement blockchain and DLT across their organization.</p>
						<Link to="/portfolio" className="red-btn"> Explore Our Work </Link>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
