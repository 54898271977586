import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Entertainment/Hero";
import IndustriesContent from "../components/Entertainment/IndustriesContent";
import PageTitle from "../components/Entertainment/PageTitle";

import '../assets/Webdevelopment.css';



const Entertainment = () => {
	return(
		<div className="App">
	    	<PageTitle />
	    	<Hero />
	    	<IndustriesContent />
	  </div>
	)
}

export default Entertainment;
