import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "../components/Automotive/Hero";
import IndustriesContent from "../components/Automotive/IndustriesContent";
import PageTitle from "../components/Automotive/PageTitle";

import "../assets/Webdevelopment.css";

const Automotive = () => {
  return (
    <div className="App">
      <PageTitle />
      <Hero />
      <IndustriesContent />
    </div>
  );
};

export default Automotive;
