import React from "react";
import { Row, Col, Container } from 'react-bootstrap';


const Process = () => {

	return (
	    <div className="process-sec padding">
			<Container fluid>
				<Row>
					<Col md="12">
						<h2 className="wow fadeInUp">OUR DEVELOPMENT <span>PROCESS</span></h2>
						<p className="wow fadeInUp">We deliver highest level of customer service by deploying innovative and collaborative project management systems to build the most professional, robust and highly scalable web & mobile solutions with highest quality standards.</p>
					</Col>
				</Row>
				<div className="process-content">
					<Row>
						<Col md="12" className="">
							<ul className="hire-team-process">
								<li className="step1">
									<div className="dashed-circle project-requirment"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">POST YOUR PROJECT REQUIREMENTS</h4>
										<p className="wow fadeInUp sub-title-area">Our analysts will thoroughly review your project requirements and select the most experienced developers best suited for your project.</p>
									</div>
								</li>
								<li className="step2">
									<div className="dashed-circle discuss-project"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">DISCUSS PROJECT DETAILS WITH OUR ANALYSTS</h4>
										<p className="wow fadeInUp sub-title-area">Our experts will contact you within no time to discuss your project related queries and to offer the best solution for your project development.</p>
									</div>
								</li>
								<li className="step3">
									<div className="dashed-circle terms-n-timelines"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">CHOOSE ENGAGEMENT TERMS & TIMELINES</h4>
										<p className="wow fadeInUp sub-title-area">Based on the project consultation provided by our experts, you can choose the engagement timelines for your project execution.</p>
									</div>
								</li>
								<li className="step4">
									<div className="dashed-circle get-started"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">SECURELY PAY ONLINE AND GET STARTED!</h4>
										<p className="wow fadeInUp sub-title-area">Make payment through our 100% secure online system and our team shall immediately get started on your project within 24 hours.</p>
									</div>
								</li>
							</ul>
						</Col>
					</Row>
				</div>
			</Container>
			
	    </div>
	);
};

export default Process;
