import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';




const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="8">
						<h2 className="wow fadeInUp">SOFTWARE DEVELOPMENT FIRM WITH <span>ENVIABLE</span> TRACK RECORD.</h2>
						<p className="wow fadeInUp">We've helped over 1000 startups, SMEs, medium-sized businesses and large enterprises overcome their biggest software development and mobile app development challenges. But don't just take our word for it. Checkout the testimonials below to see exactly what our customers have to say about us.</p>
					</Col>
					<Col lg="4">
						<div className="makingCus_">
							<h2>Making customers Happy Since <span>2017</span></h2>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
