import React from "react";
import { Container } from 'react-bootstrap';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner OutsourcingBack">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">IT OUTSOURCING</h2>
						<div className="red-border"> </div>
						<p>Not every company has a flexible workforce, so how will they get their work done? The answer is simple "Outsourcing," with the help of our IT outsourcing services; you can get access to the right expert advice for your business that helps you grow your business and increase your cost savings. </p>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
