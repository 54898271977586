import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import softwareD from '../../images/softwareD.jpg';
import {Link} from "react-router-dom";


const IndustryBussinessjs = () => {

	return (
	    <div className="Industry_Bussness p-0">
			<Container fluid>
				<Row>
					<Col md="6">
						<div className="perform_content">
							<h3 className="wow fadeInUp">WE DEAL IN EVERY INDUSTRY AND <span>BUSINESS.</span> </h3>
							<p className="wow fadeInUp">We directly deal with our clients, every single detail linked with the project is shared & discussed with the client. The biggest reason behind dealing this way is, it builds transparency. </p>
							<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
						</div>
					</Col>
					<Col md="6" className="RightImgSft">
						<img src={softwareD} className="wow fadeInRight" alt="soft" />
					</Col>
				</Row>
			</Container>
			
	    </div>
	);
};

export default IndustryBussinessjs;
