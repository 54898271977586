import React from "react";
import { Row, Col, Container } from 'react-bootstrap';


const Process = () => {

	return (
	    <div className="process-sec padding">
			<Container fluid>
				<Row>
					<Col md="12">
						<h2 className="wow fadeInUp">Our Development <span>Strategy</span></h2>
						<p className="wow fadeInUp">There is always a good development strategy behind a great business.</p>
					</Col>
				</Row>
				<div className="process-content">
					<Row>
						<Col md="12" className="">
							<ul className="hire-team-process">
								<li className="step1">
									<div className="dashed-circle project-requirment"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">Upload your project Requirements</h4>
										<p className="wow fadeInUp sub-title-area">The digiPanda team will go through all your needs according to your uploaded data. We will send the right team to contact you further. </p>
									</div>
								</li>
								<li className="step2">
									<div className="dashed-circle discuss-project"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">Expert Analysts Opinion</h4>
										<p className="wow fadeInUp sub-title-area">Our analyst’s team will get in touch with you and discuss the project in detail for more clarity and to know the desired objective. </p>
									</div>
								</li>
								<li className="step3">
									<div className="dashed-circle terms-n-timelines"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">Choosing Timelines and Terms</h4>
										<p className="wow fadeInUp sub-title-area">It’s essential to know the deadline hence, the next step is to know your timeliness and special terms to consider if any. </p>
									</div>
								</li>
								<li className="step4">
									<div className="dashed-circle get-started"></div>
									<div className="figcaption">
										<h4 className="wow fadeInUp">Start Now &Make Secure Payment</h4>
										<p className="wow fadeInUp sub-title-area">Decide, don’t delay. Start your project now and pay with our secure payment method online. No more waiting. </p>
									</div>
								</li>
							</ul>
						</Col>
					</Row>
				</div>
			</Container>
			
	    </div>
	);
};

export default Process;
