import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">OUR EXPERTISE IN <span>BLOCKCHAIN</span> DEVELOPMENT ASSISTS COMPANIES TO DEVELOP INNOVATIVE BLOCKCHAIN SOLUTIONS.</h2>
						<p className="wow fadeInUp">Blockchain is a distributed ledger technology for maintaining a permanent and tamper proof record of transactional data. Data stored on a Blockchain cannot be manipulated and the model is absolutely resistant to theft and tamper. digiPanda believes Blockchain has the potential to introduce a new level of transparency, automation and efficiency in all kinds of businesses and organizations. digiPanda is the right place to explore the gamut of Blockchain development services that can help you increase your business productivity. We have earned the reputation for developing state of the art mobile and web solutions and this expertise has enabled us to become one of the top Blockchain application development company globally. We have worked with several leading organizations from across industries to identify and build DLT-powered solutions which are tailored to their specific business needs. You can safely rely upon us for scalable Cryptocurrency Wallet development, Hyperledger, Bitcoin apps and and private Blockchain development services.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">ICO CREATION</h3>
									<p className="wow fadeInUp">digiPanda is a preferred Android app development company that builds highly robust and scalable Android apps with easy user interface and better user experience (UI/UX) meeting your requirements and budget.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">WALLET CONFIGURATIONS</h3>
									<p className="wow fadeInUp">We build elegant and engaging iOS apps that makes your business successful. We have professional iOS app developers who always design and develop easy to use, user friendly and cost-effective iOS applications.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">DECENTRALIZED APPLICATION DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda builds cross platform apps with Xamarin, PhoneGap, React Native and other frameworks. Cross platform app development makes it possible to use reusable codes which saves lots of development time and costs.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">CRYPTO EXCHANGE DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda offers expert development of Augmented Reality apps. Right from retail to construction to daily enterprise activities, you can use the potential benefits of AR to make your key operations highly efficient, informed and cost effective for better ROI.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">PUBLIC & PRIVATE BLOCKCHAIN DEVELOPMENT</h3>
									<p className="wow fadeInUp">Create, schedule and manage your data integration at scale with digiPanda's hybrid data integration service. Work with data wherever it lives, in the cloud or on-premises and with enterprise grade security.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">BLOCKCHAIN TECHNOLOGY CONSULTING</h3>
									<p className="wow fadeInUp">digiPanda's Big Data Strategy consulting services provide strategy, engineering and analytics to help you maximize your data insights. Our end to end services, matched with industry specific skills and processes, help you make your data simpler to access and understand.</p>
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
