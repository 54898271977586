import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import visalive from "../../images/visalive.png";

import { Link } from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero7 extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="HereoBanner Webdevelopment digipanda-portfolio7 pb-0">
        <div className="Banner_Content section">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg="5" className="left-text-sec">
                <h2 className="text-uppercase wow fadeInUp text-white">
                  {" "}
                  VisaLiv{" "}
                </h2>
                <p className="text-white wow fadeInUp">
                  VisaLiv is a comprehensive online resource designed
                  specifically for travellers, students and immigrants seeking
                  to confidently navigate the often-complex world of visa
                  applications. This one-stop shop empowers individuals with the
                  knowledge they need to tackle visa requirements for
                  destinations across the globe.
                  <br />
                  VisaLiv provides travelers with:
                  <ul className="m-0 mt-3 mb-4">
                    <li>Up-to-date Information</li>
                    <li>Simplified Navigation</li>
                    <li>Empowering Content</li>
                  </ul>
                  With VisaLiv as your trusted partner, you can embark on your
                  travels with confidence, knowing you have the knowledge and
                  resources needed to navigate the visa application process
                  successfully.
                </p>
                <Link
                  target="_blank"
                  to={{ pathname: "https://visaliv.com/" }}
                  className="red-btn wow fadeInUp"
                >
                  {" "}
                  Demo Available on Request &nbsp; <FaTelegramPlane />{" "}
                </Link>
              </Col>
              <Col lg="7" className="banner-right-img cms-img">
                <div className="right-img-sec">
                  <img src={visalive} alt="Developemnt" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero7;
