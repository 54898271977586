import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import magentodev from '../../images/magentodev.png';
import manufacturing from '../../images/manufacturing.png';
import flexible from '../../images/flexible.png';
import postequirements from '../../images/postequirements.png';
import vendor from '../../images/vendor.png';
import q6 from '../../images/q6.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	     <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda IS ONE OF THE TOP <span>ASP.NET DEVELOPMENT</span> COMPANIES WITH OVER 50,000+ MAN YEARS OF EXPERIENCE.</h2>
						<p className="wow fadeInUp">digiPanda is a ISO 9001 certified offshore .NET development company in India, backed by a strong workforce of 300+ experts providing custom ASP.NET development services of any complexity with incredible competence. digiPanda has established itself as one of the most reliable offshore .NET development company in India with instrumental expertise in various technologies associated with the Microsoft ASP.NET platform. Being a top offshore .NET development company in India and a certified Microsoft development partner, we help organizations in building critical business applications using efficient project management and transparent work methodologies. ASP.NET development is an integral skill-set of programmers at digiPanda who are well abreast with the latest technology trends and frameworks to provide improvised user experience as per the business requisites of our customers. From analyzing, defining, developing, testing, deployment and maintenance, digiPanda ensures all deliverables are reliable, scalable and 100% customized at each development stage.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={magentodev} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">CUSTOM .NET DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda is a top .NET development company in India that provides high performance, scalable and customized ASP.NET development services catering to your business enterprise.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={manufacturing} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">SHAREPOINT DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda, a leading offshore .NET development company develops scalable and client-centric SharePoint solutions for customers across various business verticals.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={flexible} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">ASP.NET MVC DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda offers PHP development services that enhances the business demands of our customers across all verticals.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={postequirements} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">ENTERPRISE .NET SOLUTIONS</h3>
									<p className="wow fadeInUp">digiPanda provides cost effective and high performance ASP.NET development services for growing businesses and startups.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={vendor} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">MICROSOFT DYNAMICS CRM</h3>
									<p className="wow fadeInUp">We leverage time and cost saving advantages of open source technologies, frameworks and platforms to deliver scalable web applications.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={q6} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">.NET MIGRATION SERVICES</h3>
									<p className="wow fadeInUp">We have 50+ web testers to provide high performance and 100% reliable website testing services.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
