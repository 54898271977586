import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import ecommercesolutions from '../../images/ecommercesolutions.png';
import {Link} from "react-router-dom";


const IndustryBussinessjs = () => {

	return (
	    <div className="Industry_Bussness">
			<Container fluid>
				<Row>
					<Col md="6">
						<h3 className="wow fadeInUp">Our Niche Is Everything from Services to  <span>Products.</span> </h3>
						<p className="wow fadeInUp">We have worked with 25+ industries. Our team has dealt with an immense variety of projects and can work for any niche as we reply to intense research. We discuss and execute.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col md="6">
						<img src={ecommercesolutions} className="wow fadeInRight" alt="soft" />
					</Col>
				</Row>
			</Container>
			
	    </div>
	);
};

export default IndustryBussinessjs;
