import React from "react";
import { Container } from 'react-bootstrap';
import {Link} from "react-router-dom";

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner HDBack">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">HIRE DEVELOPERS</h2>
						<div className="red-border"> </div>
						<p>Are you looking for experienced developers? If yes, then DigiPanda is here to help you out with the help of our team of developers who have worked in almost every working sector. No matter how complex the challenges are, our developers are there to help you out. </p>
						<Link to="/contact-us" className="red-btn">  Get Free Estimation  </Link>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
