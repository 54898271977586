import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import icon4 from '../../images/icon4.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	    <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp text-uppercase">At DigiPanda, we develop products that add value to your <span>business</span> and the life of your customers. </h2>
						<p className="wow fadeInUp">Our team focuses aggressively on the user experience that users will get through the softwares, which makes us different from others. </p>
						<p className="wow fadeInUp">Our team is filled with experts providing professional development services, dealing with the complexity of softwares, and developing products based on innovation, client ideas, and more. </p>
						<p className="wow fadeInUp">We are a result-oriented development company providing services to every work sector, including healthcare, management, financial, technical, development-based, etc. </p>
						<p className="wow fadeInUp">Softwares developed by our team will have the main motive of fulfilling your requirements and targets while keeping in mind the user experience offered to the users. </p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon1} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">TEST STRATEGY</h3>
									<p className="wow fadeInUp">Our team develops a test strategy based on your product, requirements, and the working sector. </p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon2} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">TEST PLANNING</h3>
									<p className="wow fadeInUp">The planning of the test takes place before even the development work starts. We create deadlines for every work and make sure that it’s done within the expected time.</p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon3} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">TEST PROCEDURES</h3>
									<p className="wow fadeInUp">Procedures for all the tests are different, as the products are also different from each other. So every product will have its unique test procedure with a set deadline. </p>
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={icon4} className="icon" alt="icon" /></span>
									<h3 className="wow fadeInUp">TEST VALIDATION</h3>
									<p className="wow fadeInUp">Under the validation process of a test, we figure out how secure and useful the developed product is.</p>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
